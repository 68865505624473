import React, { useContext } from 'react'
import { View, Pressable, ScrollView, StyleSheet, Image, Text } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
import ModalPrimary from '../ComponentsShared/ModalPrimary'
import { COLORS } from '../../Colors'
// import { styles } from "./StylesDoHaveAccount" //styles for modalS?????
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function ModalIntro1({ showNextModal }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    return (
        <ModalPrimary>
            <AlphaText style={{ fontSize: 18, textAlign: 'center' }}>
                {
                    "Now it's time to get started on your profile!\n\nYour answers here will be visible to other users, but they'll still be semi-anonymous; they won't be shown in connection to your name or photos."
                }
            </AlphaText>
            <ButtonPrimary buttonText={'Got It'} onPress={showNextModal} />
        </ModalPrimary>
    )
}
const styles = StyleSheet.create({
    pressable: {
        borderRadius: 7,
        width: 130,
        height: 50,
        marginHorizontal: 10,
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
