import React, { useState, useEffect, useContext } from 'react'
import { Dimensions, View, TextInput, StyleSheet, Modal, ScrollView, Pressable } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function FeedbackDemo({ navigation, route }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    const styles = StyleSheet.create({
        container: {
            backgroundColor: COLORS.lightGreen, //this affects very top and bottom but not middle
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        pressable: {
            color: '#2196f3',
            borderRadius: 5,
            padding: 15,
            marginTop: 20,
            marginHorizontal: 20,
        },
        titleText: {
            color: COLORS.nearBlack,
            fontSize: 40,
            textAlign: 'center',
            marginTop: 10 * VS,
        },
        textSmall: {
            color: COLORS.nearBlack,
            fontSize: 14,
            textAlign: 'center',
            marginHorizontal: 20 * HS,
            marginVertical: 5 * VS,
        },
        textButton: {
            fontSize: 20, //THIS IS ADJUSTING WIDTH OF ALL CONTENT ON ANDROID BECAUSE FLEX. LOOK INTO THAT.
            color: COLORS.white,
            textAlign: 'center',
        },
        textInput: {
            borderWidth: 2,
            flexDirection: 'row',
            marginVertical: 10,
            flex: 1,
            height: 40 * VS,
            fontSize: 16 * ((VS + HS) / 2) * 0.85, //if you update the calculations on this line, need to update alphatext and other textinputs
            backgroundColor: COLORS.white,
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 3 * VS,
            borderColor: COLORS.nearBlack,
        },
    })

    let whereOf = 'default'
    try {
        const from = navigation.getParam('from')
        whereOf = from
    } catch {}
    //console.log(whereOf);

    const [text1, setText1] = useState('')
    const [text2, setText2] = useState('')
    const [text3, setText3] = useState('')
    const [text4, setText4] = useState('')
    const [modalVisible, setModalVisible] = useState(true)

    function handleFeedback() {
        navigation.goBack()
    }
    function handleSkip() {
        navigation.goBack()
    }
    // function killKeyboard() {
    //     Keyboard.dismiss(); this crashes app
    // }
    return (
        <SafeAreaView style={{ backgroundColor: COLORS.lightGreen, flex: 1 }}>
            <View style={styles.container}>
                {/* <TouchableWithoutFeedback onPress={killKeyboard}> */}
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={() => {
                        setModalVisible(!modalVisible)
                    }}
                >
                    <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                        <View
                            style={{
                                backgroundColor: COLORS.modalColor,
                                paddingVertical: 20,
                                paddingHorizontal: 10,
                                justifyContent: 'center',
                                flexDirection: 'column',
                                flex: 1,
                                marginHorizontal: 20,
                                marginTop: 100,
                                marginBottom: 100,
                                borderRadius: 10,
                            }}
                        >
                            <AlphaText
                                style={{
                                    color: COLORS.nearBlack,
                                    fontSize: 25,
                                    textAlign: 'center',
                                    paddingBottom: 7 * HS,
                                }}
                            >
                                {'Feedback Disabled\nin Demo'}
                            </AlphaText>
                            <AlphaText
                                style={{
                                    color: COLORS.nearBlack,
                                    fontSize: 16,
                                    textAlign: 'center',
                                }}
                            >
                                {
                                    'Once you create an account, you can give feedback here.\n\nIf you do not want to create an account, but want to give feedback nonetheless, please email us at info@thrivedatingapp.com with "Feedback" in the subject line.'
                                }
                            </AlphaText>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                }}
                            >
                                <Pressable
                                    onPress={() => setModalVisible(!modalVisible)}
                                    style={({ pressed }) => [
                                        {
                                            borderRadius: 5,
                                            paddingVertical: 10,
                                            paddingHorizontal: 20,
                                            marginTop: 25,
                                            width: '50%',
                                            opacity: pressed ? 0.5 : 1,
                                            backgroundColor: COLORS.redButton,
                                        },
                                    ]}
                                >
                                    <AlphaText style={styles.textButton}>OK</AlphaText>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </Modal>
                <View style={{ backgroundColor: COLORS.lightGreen, marginBottom: 30 }}>
                    <AlphaText style={styles.titleText}>Feedback</AlphaText>
                    <ScrollView>
                        <View style={{ alignSelf: 'center', flex: 1, width: '95%' }}>
                            <AlphaText style={[styles.textSmall, { fontSize: 20 }]}>We want your opinion!</AlphaText>
                            <AlphaText style={[styles.textSmall, { marginHorizontal: 5 }]}>
                                We're striving to make Thrive the best dating app ever. Your feedback is essential in
                                this process.
                            </AlphaText>
                            <AlphaText style={styles.textSmall}>
                                All fields are optional and each has a 1000 character limit.
                            </AlphaText>
                            <TextInput
                                multiline
                                style={[styles.textInput, { fontFamily: 'Medium' }]}
                                placeholder="What's good about the app?"
                                placeholderTextColor={COLORS.darkGray}
                                maxLength={1000}
                                onChangeText={(text1) => setText1(text1)}
                                value={text1}
                            ></TextInput>
                            <TextInput
                                multiline
                                style={[styles.textInput, { fontFamily: 'Medium' }]}
                                placeholder="What's bad about the app?"
                                placeholderTextColor={COLORS.darkGray}
                                maxLength={1000}
                                onChangeText={(text2) => setText2(text2)}
                                value={text2}
                            ></TextInput>
                            <TextInput
                                multiline
                                style={[styles.textInput, { fontFamily: 'Medium' }]}
                                placeholder="New feature suggestions?"
                                placeholderTextColor={COLORS.darkGray}
                                maxLength={1000}
                                onChangeText={(text3) => setText3(text3)}
                                value={text3}
                            ></TextInput>
                            <TextInput
                                multiline
                                style={[styles.textInput, { fontFamily: 'Medium' }]}
                                placeholder="Do you have any questions?"
                                placeholderTextColor={COLORS.darkGray}
                                maxLength={1000}
                                onChangeText={(text4) => setText4(text4)}
                                value={text4}
                            ></TextInput>
                            {/* DISABLED BECAUSE CONTENT WASN'T FITTING ON SCREEN. COULD POSSIBLY SOLVE THIS WITH SHADOWS TO INDICATE SCROLLABLE, BUT IF SO BUTTONS SHOULD PROBABLY BE OUTSIDE SCROLLVIEW
                        MAYBE BUTTONS SHOULD BE OUTSIDE SCROLLVIEW REGARDLESS */}
                            {whereOf == 'default' && (
                                //Still not 100% sure what this does.
                                <AlphaText style={styles.textSmall}>
                                    {
                                        "We'll show this screen to you every once in a while, but you can also access it in the settings menu anytime."
                                    }
                                </AlphaText>
                            )}
                        </View>
                    </ScrollView>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
                        <Pressable
                            onPress={handleSkip}
                            style={({ pressed }) => [
                                styles.pressable,
                                {
                                    //MAKE SURE THESE BUTTONS ARE FOLLOWING PROPER CONVENTIONS AND THAT THEIR RESPONSIVENESS MATCHES OTHER BUTTONS IN APP
                                    opacity: pressed ? 0.5 : 1,
                                    backgroundColor: COLORS.darkGray,
                                },
                            ]}
                        >
                            <AlphaText style={styles.textButton}>No</AlphaText>
                        </Pressable>
                        <Pressable
                            onPress={handleFeedback}
                            style={({ pressed }) => [
                                styles.pressable,
                                {
                                    opacity: pressed ? 0.5 : 1,
                                    backgroundColor: COLORS.redButton,
                                },
                            ]}
                        >
                            <AlphaText style={styles.textButton}>Submit Feedback</AlphaText>
                        </Pressable>
                    </View>
                </View>
            </View>
        </SafeAreaView>
    )
}
