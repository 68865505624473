import React, { useState, useEffect } from 'react'
import { View, Image, Modal, ScrollView, Dimensions, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
import { db } from './../../FirebaseInitialization'
import { getAuth } from 'firebase/auth'
import { updateDoc, getDocs, getDoc, doc, serverTimestamp, collection, setDoc } from 'firebase/firestore'
import ModalAlert from '../ComponentsShared/ModalAlert'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import * as ImagePicker from 'expo-image-picker'
import ModalProcessing from '../ComponentsShared/ModalProcessing'
import AddPhotoIcon from './../../assets/icons/AddPhotoIconNB.png'

const { width, height } = Dimensions.get('window')
let HS = width / 320
let VS = height / 640
const buttonText = 'Save Changes'
const imageBorderWidth = 5
const displayedWidth = width - 2 * imageBorderWidth

// REMINDER - ADDING A PHOTO HERE DOESN'T DIRECTLY ADD TO PHOTOS COLLECTION, BUT ADDS TO USER DOC, A photosToAdd field. PIC WILL DISPLAY ON RELOAD AS EXPECTED

export default function PhotoEditor({ navigation }) {
    const [imageData, setImageData] = useState({})
    const [initialImageData, setInitialImageData] = useState({})
    const [displayedHeights, setDisplayedHeights] = useState({})
    const [modalAlert, setModalAlert] = useState(false)
    const [alertModalText, setAlertModalText] = useState('error')
    const [modalProcessingVisible, setModalProcessingVisible] = useState(false)

    const auth = getAuth()
    const uid = auth.currentUser.uid

    const getPics = async () => {
        let updatedImageData = {}
        const snapshot = await getDocs(collection(db, 'users', uid, 'photos'))
        snapshot.forEach((doc) => {
            let data = doc.data()
            let docId = doc.id //the thing you use to identify if face or body
            let photoUrl = data.photoLink // the link itself

            if (docId.startsWith('face')) {
                updatedImageData.face = photoUrl
            } else if (docId.startsWith('body')) {
                updatedImageData.body = photoUrl
            } else if (docId.startsWith('life1')) {
                updatedImageData.life1 = photoUrl
            } else if (docId.startsWith('life2')) {
                updatedImageData.life2 = photoUrl
            } else if (docId.startsWith('life3')) {
                updatedImageData.life3 = photoUrl
            }
        })
        // CHECK IF THEY HAVE PENDING UPDATES (IF THEY'VE ALREADY SAVED SOME CHANGES HERE THAT HAVEN'T BEEN APPROVED YET BY MODS)
        const pendingUpdates = await getDoc(doc(db, 'users', uid))
        const photosToAdd = pendingUpdates.data().photosToAdd

        if (photosToAdd) {
            for (const photoType in photosToAdd) {
                updatedImageData[photoType] = photosToAdd[photoType]
            }
        }

        setImageData(updatedImageData)
        setInitialImageData(updatedImageData) // to be compared against imageData when save and quit
    }
    const sizePics = () => {
        const calculatedDisplayedHeights = {}
        const fetchImageSizes = async () => {
            const promises = Object.entries(imageData).map(async ([key, imageUrl]) => {
                await getImageSize(imageUrl, key, calculatedDisplayedHeights).catch((error) => {
                    console.error(`Error processing image ${imageUrl}: ${error}`)
                })
            })

            await Promise.all(promises)
            setDisplayedHeights(calculatedDisplayedHeights)
        }

        const getImageSize = (imageUrl, key, heights) => {
            return new Promise((resolve, reject) => {
                Image.getSize(
                    imageUrl,
                    (imageWidth, imageHeight) => {
                        const scaleFactor = imageWidth / displayedWidth
                        const calculatedDisplayedHeight = imageHeight / scaleFactor
                        heights[key] = calculatedDisplayedHeight
                        resolve()
                    },
                    reject
                )
            })
        }

        fetchImageSizes()
    }

    useEffect(() => {
        getPics()
        sizePics()
    }, [])

    useEffect(() => {
        sizePics()
    }, [imageData])

    let selectImageAsync = async (photoKey) => {
        let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (permissionResult.granted === false) {
            setModalAlert(true)
            setAlertModalText('Granting permission to access your image gallery is required to upload photos')
            return
        }
        let pickerResult = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
        })
        let newImageData = { ...imageData }
        newImageData[photoKey] = pickerResult.assets[0].uri
        setImageData(newImageData) //#1 //this replaces photo in display
        setModalAlert(true)
        setAlertModalText(
            `Press ${buttonText} once you're done selecting new photos to submit your changes. They'll be reviewed and approved asap by our team.`
        )
    }

    let handleImagesPicked = async (changedPhotos) => {
        try {
            const auth = getAuth()
            const uid = auth.currentUser.uid
            const storage = getStorage()

            for (const photoType in changedPhotos) {
                const uri = changedPhotos[photoType]
                const img = await fetch(uri)
                const bytes = await img.blob()

                const remoteUri = 'users/' + uid + '/' + uuid.v4()
                const userRef = ref(storage, remoteUri)
                await uploadBytesResumable(userRef, bytes)
                const urlToSave = await getDownloadURL(ref(storage, remoteUri))

                const docRef = doc(db, 'users', uid)
                const docSnapshot = await getDoc(docRef)
                const existingPhotosToAdd = docSnapshot.data().photosToAdd

                await updateDoc(doc(db, 'users', uid), {
                    photosToAdd: { ...existingPhotosToAdd, [photoType]: urlToSave },
                })
            }
            //this writes to separate collection for adminapp to find
            setDoc(doc(db, 'photosToAdd', uid), {
                uid: uid,
                timestamp: serverTimestamp(),
            })
            setModalProcessingVisible(false)
            setAlertModalText('Success!')
            setModalAlert(true)
            setTimeout(() => {
                navigation.navigate('ProfileEditingMenu')
            }, 500)
        } catch (e) {
            setModalAlert(true)
            setAlertModalText('Upload failed. Please try again. (Contact admin if error repeats)')
            console.log(e)
        } finally {
            setModalProcessingVisible(false)
        }
    }

    function findDifferences(newImageData, oldImageData) {
        let newPhotos = {}

        if (newImageData.face !== oldImageData.face) {
            newPhotos.face = newImageData.face
        }
        if (newImageData.body !== oldImageData.body) {
            newPhotos.body = newImageData.body
        }
        if (newImageData.life1 !== oldImageData.life1) {
            newPhotos.life1 = newImageData.life1
        }
        // if before they only had 1-2 life photos, this does provision for adding new ones
        // it does not, however, support a reduction in the number of photos displayed at this time, and that's fine

        if ('life2' in newImageData) {
            if (newImageData.life2 !== oldImageData.life2) {
                newPhotos.life2 = newImageData.life2
            }
        }
        if ('life3' in newImageData) {
            if (newImageData.life3 !== oldImageData.life3) {
                newPhotos.life3 = newImageData.life3
            }
        }
        return newPhotos
    }

    async function handleSaveAndQuit() {
        const changedPhotos = findDifferences(imageData, initialImageData)
        if (Object.keys(changedPhotos).length !== 0) {
            setModalProcessingVisible(true)
            await handleImagesPicked(changedPhotos)
        } else {
            navigation.navigate('ProfileEditingMenu')
        }
    }

    return (
        <View style={styles.container}>
            <Modal
                animationType="fade"
                transparent={true}
                visible={modalAlert}
                onRequestClose={() => {
                    setModalAlert(!modalAlert)
                }}
            >
                <ModalAlert setModalAlert={setModalAlert} alertModalText={alertModalText} />
            </Modal>
            <Modal animationType="fade" transparent={true} visible={modalProcessingVisible}>
                <ModalProcessing />
            </Modal>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ flex: 1 }}>
                    <AlphaText
                        style={{
                            marginTop: 40 * VS,
                            fontSize: 35,
                            color: COLORS.darkGray,
                            textAlign: 'center',
                        }}
                    >
                        Your Photos
                    </AlphaText>
                </View>
            </View>

            <ScrollView
                style={{ paddingHorizontal: 20, backgroundColor: COLORS.white }}
                showsVerticalScrollIndicator={false}
                scrollEventThrottle={50}
                contentContainerStyle={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}
                // ref={scrollRef}
                // onScroll={(event) => onScroll(event)}
            >
                {/* <PhotoSubHeader age={data.age} height={data.height} /> */}
                {imageData?.face && (
                    <Pressable onPress={() => selectImageAsync('face')}>
                        <Image
                            source={{ uri: imageData.face }}
                            style={[styles.image, { height: displayedHeights.face }]}
                        />
                    </Pressable>
                )}
                {imageData?.body && (
                    <Pressable onPress={() => selectImageAsync('body')}>
                        <Image
                            source={{ uri: imageData.body }}
                            style={[styles.image, { height: displayedHeights.body }]}
                        />
                    </Pressable>
                )}
                {imageData?.life1 && (
                    <Pressable onPress={() => selectImageAsync('life1')}>
                        <Image
                            source={{ uri: imageData.life1 }}
                            style={[styles.image, { height: displayedHeights.life1 }]}
                        />
                    </Pressable>
                )}
                {imageData?.life2 ? (
                    <Pressable onPress={() => selectImageAsync('life2')}>
                        <Image
                            source={{ uri: imageData.life2 }}
                            style={[styles.image, { height: displayedHeights.life2 }]}
                        />
                    </Pressable>
                ) : (
                    <Pressable onPress={() => selectImageAsync('life2')} style={styles.photoIconBox}>
                        <Image source={AddPhotoIcon} style={styles.photoIcon} />
                    </Pressable>
                )}
                {imageData?.life3 ? (
                    <Pressable onPress={() => selectImageAsync('life3')}>
                        <Image
                            source={{ uri: imageData.life3 }}
                            style={[styles.image, { height: displayedHeights.life3 }]}
                        />
                    </Pressable>
                ) : (
                    <Pressable onPress={() => selectImageAsync('life3')} style={styles.photoIconBox}>
                        <Image source={AddPhotoIcon} style={styles.photoIcon} />
                    </Pressable>
                )}
            </ScrollView>

            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    paddingVertical: 20 * VS,
                }}
            >
                {/* buttonText = "Save Changes" */}
                <ButtonPrimary onPress={() => handleSaveAndQuit()} buttonText={buttonText} />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        backgroundColor: COLORS.white,
    },

    image: {
        marginTop: 10,
        alignSelf: 'center',
        resizeMode: 'contain',
        width: displayedWidth,
        borderRadius: 10,
    },
    photoIcon: {
        width: 102 * HS * 0.9,
        height: 136 * HS * 0.9,
        resizeMode: 'contain',
    },
    photoIconBox: {
        marginVertical: 10 * VS,
        width: 102 * HS * 0.9 + 5,
        height: 136 * HS * 0.9 + 5,
        padding: 5,
        borderWidth: 2,
        borderColor: COLORS.nearBlack,
        borderStyle: 'dashed',
        alignItems: 'center',
        justifyContent: 'center',
    },
})
