import { View, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { useNavigation } from '@react-navigation/native'

export default function ModalConfirmation({ setConfirmationModal, yesPressed }) {
    const noPressed = () => {
        setConfirmationModal(false)
    }

    return (
        <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
            <View
                style={{
                    backgroundColor: COLORS.lightGreen,
                    padding: 20,
                    margin: 20,
                    borderRadius: 10,
                    alignItems: 'center',
                }}
            >
                <AlphaText style={{ fontSize: 30 }}>Confirmation</AlphaText>
                <AlphaText style={{ fontSize: 20, marginTop: 10 }}>Are these votes correct?</AlphaText>
                <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <Pressable
                        onPress={noPressed}
                        style={({ pressed }) => [
                            styles.pressable,
                            { backgroundColor: COLORS.darkGray, opacity: pressed ? 0.5 : 1 },
                        ]}
                    >
                        <AlphaText style={{ color: COLORS.white, fontSize: 15 }}>No</AlphaText>
                    </Pressable>
                    <Pressable
                        onPress={yesPressed}
                        style={({ pressed }) => [
                            styles.pressable,
                            { backgroundColor: COLORS.redButton, opacity: pressed ? 0.5 : 1 },
                        ]}
                    >
                        <AlphaText style={{ color: COLORS.white, fontSize: 15 }}>Yes</AlphaText>
                    </Pressable>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    pressable: {
        borderRadius: 7,
        width: 130,
        height: 50,
        marginHorizontal: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
