import React, { useContext } from 'react'
import { View, Pressable, ScrollView, StyleSheet, Image, Text } from 'react-native'
import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
import ButtonSecondary from '../ComponentsShared/ButtonSecondary'
import ModalPrimary from '../ComponentsShared/ModalPrimary'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
// import { styles } from "./StylesDoHaveAccount" //styles for modalS?????
import { ScreenSizeContext } from '../../ScreenSizeContext'

export default function ModalPicConfirmation({ setModalPicConfirmationVisible, uploadAndProceed, multiplePics }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    return (
        <ModalPrimary photoUpload={true}>
            <AlphaText
                style={{
                    fontSize: 20,
                    textAlign: 'center',
                }}
            >
                {!multiplePics && 'Are you sure this is the photo you want to upload?'}
                {multiplePics && 'Are you sure these are the photos you want to upload?'}
            </AlphaText>

            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                <ButtonSecondary onPress={() => setModalPicConfirmationVisible(false)} buttonText={'Edit'} />
                <ButtonPrimary onPress={uploadAndProceed} buttonText={'Yes'} />
            </View>
        </ModalPrimary>
    )
}
