import React, { useState, useEffect } from 'react'
import { Dimensions, View, Image, Modal, Pressable, StyleSheet } from 'react-native'
import { getAuth, sendEmailVerification, createUserWithEmailAndPassword } from 'firebase/auth'
import EmailPasswordInput from '../ComponentsShared/EmailPasswordInput'
import { SafeAreaView } from 'react-native-safe-area-context'
import AlphaText from '../ComponentsShared/TextAlpha'
import SmallQuestionMark from '../../assets/buttons/SmallQuestionMark.png'
import { COLORS } from './../../Colors'
import { loggingOut } from '../../FirebaseMethods'
import { setDoc, doc } from 'firebase/firestore'
import { db } from './../../FirebaseInitialization'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'

const { width, height } = Dimensions.get('window')
let HS = width / 320
let VS = height / 640

export default function CreateAccount({ navigation }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [modalProcessingVisible, setModalProcessingVisible] = useState(false)

    // const [modalMilwaukee, setModalMilwaukee] = useState(true);

    const [modalAlert, setModalAlert] = useState(false)
    const [alertModalText, setAlertModalText] = useState('error')
    const [emailModal, setEmailModal] = useState(false)
    const [passModal, setPassModal] = useState(false)

    const emptyState = () => {
        setEmail('')
        setPassword('')
    }

    async function handlePress() {
        if (!email) {
            setModalAlert(true)
            setAlertModalText('Email field is required.')
        } else if (!password) {
            setModalAlert(true)
            setAlertModalText('Password field is required.')
        } else if (password.length < 6) {
            setModalAlert(true)
            setAlertModalText('Password must be at least 6 character long.')
        } else {
            setModalProcessingVisible(true)
            try {
                await loggingOut()
            } catch {}
            const auth = getAuth()
            createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user
                    emptyState() // clearing state of password fields is good practice maybe
                    sendEmailVerification(user).then(() => {
                        setDoc(doc(db, 'users', user.uid), {
                            email: user.email,
                        }).then(() => {
                            logEvent(analytics, 'finished_create_account_screen')
                            navigation.navigate('VerifyEmail')
                        })
                    })
                })
                .catch((error) => {
                    if (error.code === 'auth/email-already-in-use') {
                        setModalAlert(true)
                        setAlertModalText('There is already an account with this email. Please sign in, instead.')
                    }
                })
            setModalProcessingVisible(false)
        }
    }

    return (
        <SafeAreaView
            style={{
                backgroundColor: COLORS.lightGreen,
                flex: 1,
                justifyContent: 'center',
            }}
        >
            <Modal visible={emailModal} animationType="fade" transparent={true}>
                <Pressable
                    style={{
                        backgroundColor: COLORS.white,
                        borderRadius: 10,
                        flex: 1,
                        justifyContent: 'center',
                        marginLeft: '50%',
                        marginRight: 5 * HS,
                        marginTop: (height - 250) / 2,
                        marginBottom: (height - 180) / 2,
                    }}
                    onPress={() => setEmailModal(false)}
                >
                    <AlphaText
                        style={{
                            padding: 20,
                            textAlign: 'center',
                            fontSize: 12,
                        }}
                    >
                        Your email will not be visible to anyone.
                    </AlphaText>
                </Pressable>
            </Modal>
            <Modal visible={passModal} animationType="fade" transparent={true}>
                <Pressable
                    style={{
                        backgroundColor: COLORS.white,
                        borderRadius: 10,
                        flex: 1,
                        justifyContent: 'center',
                        marginLeft: '30%',
                        marginRight: 5 * HS,
                        marginTop: (height - 90) / 2,
                        marginBottom: (height - 450) / 2,
                    }}
                    onPress={() => setPassModal(false)}
                >
                    <AlphaText
                        style={{
                            padding: 20,
                            textAlign: 'center',
                            fontSize: 12,
                        }}
                    >
                        We recommend creating a long password so that your account is secure; you will rarely be asked
                        for your password and may reset your password using your email anytime you need it.
                    </AlphaText>
                </Pressable>
            </Modal>
            <Modal animationType="fade" transparent={true} visible={modalProcessingVisible}>
                <View
                    style={{
                        backgroundColor: COLORS.modalSurroundings,
                        flex: 1,
                        justifyContent: 'center',
                    }}
                >
                    <View
                        style={{
                            backgroundColor: COLORS.lightGreen,
                            padding: 10,
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            flex: 1,
                            marginHorizontal: 30,
                            marginVertical: 200,
                            borderRadius: 10,
                        }}
                    >
                        <AlphaText
                            style={{
                                color: COLORS.nearBlack,
                                fontSize: 30,
                                textAlign: 'center',
                            }}
                        >
                            Processing Request
                        </AlphaText>
                        <AlphaText
                            style={{
                                color: COLORS.nearBlack,
                                fontSize: 20,
                                textAlign: 'center',
                            }}
                        >
                            Please Wait
                        </AlphaText>
                    </View>
                </View>
            </Modal>
            <Modal
                animationType="fade"
                transparent={true}
                visible={modalAlert}
                onRequestClose={() => {
                    setModalAlert(!modalAlert)
                }}
            >
                <View
                    style={{
                        backgroundColor: COLORS.modalSurroundings,
                        flex: 1,
                        justifyContent: 'center',
                    }}
                >
                    <View
                        style={{
                            backgroundColor: COLORS.lightGreen,
                            padding: 20,
                            // alignItems: "center",
                            justifyContent: 'center',
                            flexDirection: 'column',
                            flex: 1,
                            marginHorizontal: 30,
                            marginTop: 50,
                            marginBottom: 50,
                            borderRadius: 10,
                        }}
                    >
                        <AlphaText
                            style={{
                                color: COLORS.nearBlack,
                                fontSize: 30,
                                textAlign: 'center',
                                paddingBottom: 15,
                            }}
                        >
                            {alertModalText}
                        </AlphaText>

                        <View
                            style={{
                                flexDirection: 'row',
                            }}
                        >
                            <View style={{ flex: 0.5 }}></View>

                            <Pressable
                                onPress={() => setModalAlert(!modalAlert)}
                                style={({ pressed }) => [
                                    {
                                        flex: 6,
                                        borderRadius: 5,
                                        padding: 10,
                                        justifyContent: 'center',
                                        marginTop: 25,
                                        backgroundColor: COLORS.redButton,
                                        opacity: pressed ? 0.5 : 1,
                                    },
                                ]}
                            >
                                <AlphaText
                                    style={{
                                        fontSize: 17,
                                        color: COLORS.white,
                                        textAlign: 'center',
                                    }}
                                >
                                    Back
                                </AlphaText>
                            </Pressable>

                            <View style={{ flex: 0.5 }}></View>
                        </View>
                    </View>
                </View>
            </Modal>

            <View
                style={{
                    backgroundColor: COLORS.lightGreen,
                    alignItems: 'center',
                    marginHorizontal: 10 * HS,
                }}
            >
                <AlphaText
                    style={{
                        fontSize: 36,
                        color: COLORS.nearBlack,
                        marginBottom: 40 * VS * VS,
                    }}
                >
                    Create Account
                </AlphaText>
                <View style={{ width: '90%' }}>
                    <View style={{ justifyContent: 'center' }}>
                        <EmailPasswordInput inputType={'email'} value={email} setStateGeneric={setEmail} />
                        <Pressable
                            style={{ position: 'absolute', right: -12 * HS }}
                            onPress={() => setEmailModal(true)}
                        >
                            <Image source={SmallQuestionMark} style={styles.icon} />
                        </Pressable>
                    </View>
                    <View style={{ justifyContent: 'center' }}>
                        <EmailPasswordInput inputType={'password'} value={password} setStateGeneric={setPassword} />
                        <Pressable style={{ position: 'absolute', right: -12 * HS }} onPress={() => setPassModal(true)}>
                            <Image source={SmallQuestionMark} style={styles.icon} />
                        </Pressable>
                    </View>
                </View>
                <View style={{ width: '95%', marginTop: 10 * VS }}>
                    <AlphaText style={styles.text}>Password must be at least 6 characters long.</AlphaText>
                </View>
                <Pressable
                    onPress={handlePress}
                    style={({ pressed }) => [
                        {
                            borderRadius: 10 * HS,
                            padding: 7 * VS,
                            marginTop: 40 * VS * VS,
                            backgroundColor: COLORS.darkGray,
                            opacity: pressed ? 0.5 : 1,
                        },
                    ]}
                >
                    <AlphaText
                        style={{
                            fontSize: 18,
                            color: COLORS.white,
                            paddingHorizontal: 15,
                            paddingVertical: 10,
                            // fontWeight: "bold",
                        }}
                    >
                        Sign Up
                    </AlphaText>
                </Pressable>
                <Pressable style={styles.buttonSecondary} onPress={() => navigation.navigate('DemoOrCreate')}>
                    {({ pressed }) => (
                        <AlphaText
                            style={{
                                fontSize: 14,
                                textAlign: 'center',
                                marginTop: 5 * VS,
                                color: COLORS.nearBlack,
                                textDecorationLine: 'underline',
                                opacity: pressed ? 0.5 : 1,
                            }}
                        >
                            {"I want to try demo first so\nI'll know how the app works"}
                        </AlphaText>
                    )}
                </Pressable>
                <Pressable style={styles.buttonSecondary} onPress={() => navigation.navigate('SignIn')}>
                    {({ pressed }) => (
                        <AlphaText
                            style={{
                                fontSize: 14,
                                color: COLORS.nearBlack,
                                textDecorationLine: 'underline',
                                opacity: pressed ? 0.5 : 1,
                            }}
                        >
                            Already have an account?
                        </AlphaText>
                    )}
                </Pressable>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    buttonSecondary: {
        marginTop: 15 * VS,
    },
    text: {
        fontSize: 12,
        fontStyle: 'italic',
        color: COLORS.nearBlack,
        textAlign: 'center',
    },
    icon: {
        resizeMode: 'contain',
        width: 20,
        height: 20,
        marginRight: 10,
        tintColor: COLORS.nearBlack,
    },
})
