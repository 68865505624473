import React, { useState, useEffect, useContext } from 'react'
import { Dimensions, View, TextInput, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from './../../Colors'
import { db } from './../../FirebaseInitialization'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
import ButtonSecondary from '../ComponentsShared/ButtonSecondary'
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function Feedback({ navigation, route }) {
    const { HS, VS } = useContext(ScreenSizeContext)
    const styles = StyleSheet.create({
        container: {
            backgroundColor: COLORS.lightGreen, //this affects very top and bottom but not middle
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            alignSelf: 'center',
            flex: 1,
        },
        pressable: {
            color: '#2196f3',
            borderRadius: 5,
            padding: 15,
            marginTop: 20,
            marginHorizontal: 20,
        },
        titleText: {
            color: COLORS.nearBlack,
            fontSize: 40,
            textAlign: 'center',
            marginTop: 10 * VS,
        },
        textSmall: {
            color: COLORS.nearBlack,
            fontSize: 16,
            textAlign: 'center',
            marginHorizontal: 20 * HS,
            marginVertical: 5 * VS,
        },
        textButton: {
            fontSize: 20, //THIS IS ADJUSTING WIDTH OF ALL CONTENT ON ANDROID BECAUSE FLEX. LOOK INTO THAT.
            color: COLORS.white,
            textAlign: 'center',
        },
        textInput: {
            borderWidth: 2,
            flexDirection: 'row',
            marginVertical: 10,
            flex: 1,
            minHeight: 40 * VS,
            fontSize: 16 * ((VS + HS) / 2) * 0.85, //if you update the calculations on this line, need to update alphatext and other textinputs
            fontFamily: 'Medium',
            backgroundColor: COLORS.white,
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 3 * VS,
            borderColor: COLORS.nearBlack,
        },
    })
    let whereOf = 'default'
    try {
        const from = navigation.getParam('from')
        whereOf = from
    } catch {}
    const [text1, setText1] = useState('')
    const [text2, setText2] = useState('')
    const [text3, setText3] = useState('')
    const [text4, setText4] = useState('')

    function handleFeedback() {
        const auth = getAuth()
        const currentUser = auth.currentUser

        addDoc(collection(db, 'feedback'), {
            createdOn: serverTimestamp(),
            createdBy: currentUser.uid,
            email: currentUser.email,
            signUpFeedback: false,
            Question1: text1,
            Question2: text2,
            Question3: text3,
            Question4: text4,
        })

        //BEST WAY MIGHT BE TO TRIGGER EMAIL TO INFO@THRIVEDATINGAPP.COM ACCOUNT WITH A SUBJECT LINE OF "APP-FEEDBACK-hOzKWsQiK4X3XRjwQ4ICx5vRoZ53" SO CAN EASILY FILTER
        //attach their uid and email to it for easy follow ups?
        navigation.navigate('Thrive')
    }
    function handleSkip() {
        navigation.navigate('Thrive')
    }

    return (
        <SafeAreaView style={{ backgroundColor: COLORS.lightGreen, flex: 1 }}>
            <View style={styles.container}>
                <AlphaText style={styles.titleText}>Feedback</AlphaText>
                {/* <ScrollView> */}
                <View style={{ alignSelf: 'center', flex: 1, width: '95%' }}>
                    <AlphaText style={[styles.textSmall, { fontSize: 20 }]}>We want your opinion!</AlphaText>
                    <AlphaText style={[styles.textSmall, { marginHorizontal: 5 }]}>
                        We're striving to make Thrive the best dating app ever. Your feedback is essential in this
                        process.
                    </AlphaText>
                    <AlphaText style={styles.textSmall}>All fields are optional.</AlphaText>
                    <TextInput
                        multiline
                        style={styles.textInput}
                        placeholder="What's good about the app?"
                        placeholderTextColor={COLORS.darkGray}
                        maxLength={1000}
                        onChangeText={(text1) => setText1(text1)}
                        value={text1}
                    ></TextInput>
                    <TextInput
                        multiline
                        style={styles.textInput}
                        placeholder="What's bad about the app?"
                        placeholderTextColor={COLORS.darkGray}
                        maxLength={1000}
                        onChangeText={(text2) => setText2(text2)}
                        value={text2}
                    ></TextInput>
                    <TextInput
                        multiline
                        style={styles.textInput}
                        placeholder="New feature suggestions?"
                        placeholderTextColor={COLORS.darkGray}
                        maxLength={1000}
                        onChangeText={(text3) => setText3(text3)}
                        value={text3}
                    ></TextInput>
                    <TextInput
                        multiline
                        style={styles.textInput}
                        placeholder="Do you have any questions?"
                        placeholderTextColor={COLORS.darkGray}
                        maxLength={1000}
                        onChangeText={(text4) => setText4(text4)}
                        value={text4}
                    ></TextInput>
                    {/* </KeyboardAvoidingView> */}
                    {whereOf == 'default' && (
                        <AlphaText
                            style={{
                                color: COLORS.nearBlack,
                                fontSize: 14,
                                textAlign: 'center',
                                marginHorizontal: 30,
                                marginVertical: 7,
                            }}
                        >
                            {
                                "We'll show this screen to you every once in a while, but you can also access it in the settings menu anytime."
                            }
                        </AlphaText>
                    )}
                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
                        <ButtonSecondary onPress={handleSkip} buttonText={'Skip'} />
                        <ButtonPrimary onPress={handleFeedback} buttonText={'Submit Feedback'} />
                    </View>
                </View>
                {/* </ScrollView> */}
            </View>
        </SafeAreaView>
    )
}
