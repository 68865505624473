import React, { useState, useEffect } from 'react'
import { Dimensions, View, StyleSheet } from 'react-native'

import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from './../../Colors'
import AlphaText from '../ComponentsShared/TextAlpha'

const { width, height } = Dimensions.get('window')
let HS = width / 320
let VS = height / 640

export default function AccountCompleted({ navigation }) {
    function handleBackButtonClick() {
        return true
    }

    return (
        <SafeAreaView
            style={{
                backgroundColor: COLORS.white,
                flex: 1,
                justifyContent: 'center',
            }}
        >
            <View style={styles.container}>
                <AlphaText
                    style={{
                        fontSize: 35,
                        color: COLORS.redButton,
                        marginBottom: 20 * HS,
                    }}
                >
                    Congratulations!
                </AlphaText>
                <AlphaText style={styles.text}>You are done creating your account!</AlphaText>
                <AlphaText style={styles.text}>
                    Your account will now be manually reviewed. We will send you an email as soon as your account has
                    been approved. This typically takes less than 48 hours.
                    {/* Until then you will be unable to login. (IS THIS TRUE??? VERIFY BEFORE LAUNCH. TEST ME TESTING) */}
                </AlphaText>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    },

    text: {
        marginHorizontal: 30,
        marginVertical: 10,
        textAlign: 'center',
        fontSize: 20,
        color: COLORS.nearBlack,
        width: '85%',
    },
})
