import React, { useState, useEffect, createContext, useContext } from 'react'
import { Modal, StyleSheet } from 'react-native'
import { db } from './../../FirebaseInitialization'
import { updateDoc, getDoc, doc, arrayUnion } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { SafeAreaView } from 'react-native-safe-area-context'
import ModalConfirmation from '../ComponentsAnswers/ModalConfirmation'
import ModalReport from '../ComponentsShared/ModalReport'
import ModalAnswerQuestions from '../ComponentsAnswers/ModalAnswerQuestions'
import VotingFooter from '../ComponentsShared/VotingFooter'
import Swipable from '../ComponentsAnswers/Swipable'
import { answerProcessor } from '../ComponentsShared/answerProcessor'

import { COLORS } from '../../Colors'

const ModalContext = createContext()

export const useModal = () => useContext(ModalContext) || {}

let votedMaybe = []
let votedYes = []
function randomizeArray(array) {
    let shuffledArray = array.slice()
    for (var i = shuffledArray.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = shuffledArray[i]
        shuffledArray[i] = shuffledArray[j]
        shuffledArray[j] = temp
    }
    return shuffledArray
}

export default function AnswersVoting({ navigation }) {
    const [person, setPerson] = useState(1)
    const [votes, setVotes] = useState({ 1: null, 2: null, 3: null, 4: null, 5: null, 6: null, 7: null })
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [answerQuestionsModal, setAnswerQuestionsModal] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const [lineup, setLineup] = useState([])
    const [lineupForPhotos, setLineupForPhotos] = useState([])
    const [answers, setAnswers] = useState({
        1: {
            Dealbreaker: '',
            Marriage: '',
            Kids: '',
            Relationship: '',
            Religion: '',
            Career: '',
            Astrology: '',
            Politics: '',
            Sex: '',
            Alcohol: '',
            Food: '',
            Psychology: '',
            Fun: '',
            ForFun1: { question: '', answers: '' },
            ForFun2: { question: '', answers: '' },
            ForFun3: { question: '', answers: '' },
        },
        2: {
            Dealbreaker: '',
            Marriage: '',
            Kids: '',
            Relationship: '',
            Religion: '',
            Career: '',
            Astrology: '',
            Politics: '',
            Sex: '',
            Alcohol: '',
            Food: '',
            Psychology: '',
            Fun: '',
            ForFun1: { question: '', answers: '' },
            ForFun2: { question: '', answers: '' },
            ForFun3: { question: '', answers: '' },
        },
        3: {
            Dealbreaker: '',
            Marriage: '',
            Kids: '',
            Relationship: '',
            Religion: '',
            Career: '',
            Astrology: '',
            Politics: '',
            Sex: '',
            Alcohol: '',
            Food: '',
            Psychology: '',
            Fun: '',
            ForFun1: { question: '', answers: '' },
            ForFun2: { question: '', answers: '' },
            ForFun3: { question: '', answers: '' },
        },
        4: {
            Dealbreaker: '',
            Marriage: '',
            Kids: '',
            Relationship: '',
            Religion: '',
            Career: '',
            Astrology: '',
            Politics: '',
            Sex: '',
            Alcohol: '',
            Food: '',
            Psychology: '',
            Fun: '',
            ForFun1: { question: '', answers: '' },
            ForFun2: { question: '', answers: '' },
            ForFun3: { question: '', answers: '' },
        },
        5: {
            Dealbreaker: '',
            Marriage: '',
            Kids: '',
            Relationship: '',
            Religion: '',
            Career: '',
            Astrology: '',
            Politics: '',
            Sex: '',
            Alcohol: '',
            Food: '',
            Psychology: '',
            Fun: '',
            ForFun1: { question: '', answers: '' },
            ForFun2: { question: '', answers: '' },
            ForFun3: { question: '', answers: '' },
        },
        6: {
            Dealbreaker: '',
            Marriage: '',
            Kids: '',
            Relationship: '',
            Religion: '',
            Career: '',
            Astrology: '',
            Politics: '',
            Sex: '',
            Alcohol: '',
            Food: '',
            Psychology: '',
            Fun: '',
            ForFun1: { question: '', answers: '' },
            ForFun2: { question: '', answers: '' },
            ForFun3: { question: '', answers: '' },
        },
        7: {
            Dealbreaker: '',
            Marriage: '',
            Kids: '',
            Relationship: '',
            Religion: '',
            Career: '',
            Astrology: '',
            Politics: '',
            Sex: '',
            Alcohol: '',
            Food: '',
            Psychology: '',
            Fun: '',
            ForFun1: { question: '', answers: '' },
            ForFun2: { question: '', answers: '' },
            ForFun3: { question: '', answers: '' },
        },
    })

    const auth = getAuth()
    const currentUser = auth.currentUser
    const uid = currentUser.uid

    const getLineup = async function () {
        //  lineup = []
        setLineup([
            'zExdiWZvMY4mHLVyCrC9Xb2Il1IK',
            'zExdiWZvMY4mHLVyCrC9Xb2Il1IK',
            'zExdiWZvMY4mHLVyCrC9Xb2Il1IK',
            'zExdiWZvMY4mHLVyCrC9Xb2Il1IK',
            'zExdiWZvMY4mHLVyCrC9Xb2Il1IK',
            'zExdiWZvMY4mHLVyCrC9Xb2Il1IK',
            'zExdiWZvMY4mHLVyCrC9Xb2Il1IK',

            // 'baIp5pE4Q3vcTHQQbHGcbKZTvc2M', // these dicks don't have forFun in the shape of their answers so everything breaks
            // 'YUF2YkXG3nSGwVucLq9VOP0E69dW',
            // 'NwaDNgG2BFNna3rYV6VaZHANrkOg',
            // 'MzLu6yjzSdt5LeMlC149ehjS6p0q',
            // 'lCWxtd91Y76YDKUWfsmEkT2Z5qlO',
            // 'CVe4t6EsNIOmk65kDTmB0wY4Pl9b',
        ])
        ////////// THIS CALLS CLOUD FUNCTION TO GET REAL LINEUP////////////////////////////////////////////////

        // const mFunction = firebase.functions().httpsCallable("dailyFunction");
        // await mFunction().then((result) => {
        //     // console.log(result.data);
        //     lineup = result.data;
        //     // console.log("lineup1: ", lineup);
        //     return lineup;
        // });
        //

        // setLineup(randomizeArray(fetchedLineup))

        // THIS PART IS FOR HANDLING CONDITIONS WHERE A FULL LINEUP WAS NOT RETURNED (this code is old)
        // IF YOU KEEP THESE SETTIMEOUTS, MAKE SURE TO CLEARTIMEOUT TO AVOID MEMORY LEAKS
        // if (lineup.length == 1) {
        //     // setLoadingModal(false) //this should display loading until it fails and then hide
        //     setTimeout(() => {
        //         setModalNotTime(true)
        //     }, 500) //hacky way to handle multiple modals. If try to display immediately, fine on android, but nukes all modals on ios
        // } else if (lineup.length == 2) {
        //     // setLoadingModal(false)
        //     setTimeout(() => {
        //         setModalInsufficientUsers(true)
        //     }, 500) //hacky way to handle multiple modals. If try to display immediately, fine on android, but nukes all modals on ios

        //     // Unable to retrieve 7 users. We are sorry to inform you that there may not be enough new users in your area. Hopefully more people will sign up for the app soon. If you believe this is an error, please try again to see if the problem resolves itself and then contact support if problem persists.
        //     //if this happens to them, need to keep them "inactive = false" perpetually so that people can still match with them
        //     //also be nice to inform them when there are again a decent number of new users in their area
        // } else if (lineup.length != 7) {
        //     setModalAlert2(true)
        //     // window.alert("error 429, please contact admin");
        // } else
    }

    const getAnswers = async () => {
        // this gets their own answers here
        const ownAnswersData = await getDoc(doc(db, 'users', uid, 'answers', 'answers'))
        const ownAnswers = ownAnswersData.data().answers

        if (lineup.length == 7) {
            // DO WE VALIDATE LINEUP LENGTH ELSEWHERE???. WE NEED TO
            try {
                // SHUFFLE THE LINEUP SO PHOTOS AND ANSWERS UIDS ARE IN DIFFERENT ORDERS
                let answersRaw = await Promise.all(
                    lineup.map(async (personInLineup) => {
                        const docSnapshot = await getDoc(doc(db, 'users', personInLineup, 'answers', 'answers'))
                        return docSnapshot.data().answers
                    })
                )
                console.log('answersRaw: ', answersRaw)

                let answersRedacted = answersRaw.map((person) => {
                    let newPerson = { ...person }
                    for (let key in newPerson) {
                        if (ownAnswers[key] === '') {
                            let baseLength = Math.ceil(newPerson[key].length / 2)
                            let adjustment = Math.floor(Math.random() * 7) - 3 // Generates a random number between -3 and 3

                            let finalLength = baseLength + adjustment
                            finalLength = finalLength > 0 ? finalLength : 0 // Ensure the length is not negative

                            let newResponse = Array(finalLength).fill('♥ ').join('').trim()

                            newPerson[key] = newResponse
                        } else if (key.startsWith('forFun')) {
                            if (ownAnswers[key].answer === '') {
                                newPerson[key].question = ''
                                newPerson[key].answer = ''
                            }
                        }
                    }
                    return newPerson
                })
                console.log('answersRedacted: ', answersRedacted)

                let answersFormatted = answersRedacted.reduce((accumulatedObject, currentObject, index) => {
                    accumulatedObject[index + 1] = answerProcessor(currentObject)
                    return accumulatedObject
                }, {})
                console.log('ANSWERSFORMATTED: ', answersFormatted)

                setAnswers(answersFormatted)
            } catch (err) {
                console.log(err)
            }
        }
    }

    const openAnswerQuestionsModal = (key) => {
        setAnswerQuestionsModal(true)
        // console.log(key) Getting it to scroll to the key is hard, so...
    }
    // could rename as closeAnswerQuestionsModal and add to context (or not)
    const updateAnswers = () => {
        setAnswerQuestionsModal(false)
        getAnswers()
    }

    useEffect(() => {
        getLineup()
    }, [])

    useEffect(() => {
        getAnswers()
        setLineupForPhotos(randomizeArray(lineup))
    }, [lineup])

    // yes pressed on confirmation modal
    function yesPressed() {
        setConfirmationModal(false)
        Object.entries(votes).forEach(([key, vote]) => processVote(key, vote))
        console.log('lineupforPhotos: ', lineupForPhotos)
        console.log('votes', votes)
        console.log('votedYes', votedYes)
        console.log('votedMaybe', votedMaybe)

        navigation.navigate('PhotosVoting', {
            lineup: lineupForPhotos, //was working when it was lineupHook
            votedYes: votedYes, //array with uid(s) of people they green-lit
            votedMaybe: votedMaybe,
        })
    }

    function processVote(key, vote) {
        let theirUid = lineup[Number(key) - 1]
        console.log(key, vote)
        if (vote == 'yes') {
            votedYes.push(theirUid) //verify they aren't voting on a non-loaded user here
        }
        if (vote == 'skip') {
            votedMaybe.push(theirUid)
        }
        if (vote == 'no') {
            updateDoc(doc(db, 'users', uid), {
                blacklist: arrayUnion(theirUid),
            })
        }
    }

    return (
        <ModalContext.Provider value={{ openAnswerQuestionsModal, updateAnswers }}>
            <SafeAreaView style={styles.container}>
                <Modal visible={reportModal} animationType="fade" transparent={true}>
                    {/* TEST TO MAKE SURE REPORTS THE CORRECT PERSON */}
                    <ModalReport
                        setReportModal={setReportModal}
                        demo={false}
                        uidOfProfile={lineup[person - 1]}
                        screen={'answers'}
                    />
                </Modal>
                <Modal visible={confirmationModal} animationType="fade" transparent={true}>
                    <ModalConfirmation setConfirmationModal={setConfirmationModal} yesPressed={yesPressed} />
                </Modal>

                <Modal visible={answerQuestionsModal} animationType="fade" transparent={true}>
                    <ModalAnswerQuestions updateAnswers={updateAnswers} />
                </Modal>

                <Swipable
                    person={person}
                    setPerson={setPerson}
                    votes={votes}
                    answers={answers}
                    setReportModal={setReportModal}
                />

                <VotingFooter
                    person={person}
                    setPerson={setPerson}
                    votes={votes}
                    setVotes={setVotes}
                    initialYesVotes={3}
                    setConfirmationModal={setConfirmationModal}
                    screen="AnswersVoting"
                />
            </SafeAreaView>
        </ModalContext.Provider>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        backgroundColor: COLORS.white,
        flex: 1,
    },
})
