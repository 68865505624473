import React, { useState, useContext } from 'react'
import { View, Pressable, StyleSheet, Image } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import CapsuleButton from './CapsuleButton'
import { ScreenSizeContext } from '../../ScreenSizeContext'

export default function Dealbreakers({ answers }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    const handlePress = (buttonName) => {
        let currVal = pressed[buttonName]
        setPressed({ ...pressed, [buttonName]: !currVal })
    }
    const [pressed, setPressed] = useState({
        Marriage: true,
        Kids: true,
    })
    return (
        <View style={{ marginTop: 25, alignItems: 'flex-start' }}>
            <AlphaText style={{ fontSize: 15 }}>Dealbreakers</AlphaText>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 * VS, gap: 10 * VS }}>
                <CapsuleButton
                    keyword={'Marriage'}
                    answers={answers}
                    pressed={pressed}
                    handlePress={handlePress}
                    color={COLORS.lightPink}
                    initialDeployed
                />
                <CapsuleButton
                    keyword={'Kids'}
                    answers={answers}
                    pressed={pressed}
                    handlePress={handlePress}
                    color={COLORS.lightPink}
                    initialDeployed
                />
            </View>
            <View style={styles.largeBox}>
                <AlphaText style={styles.boxText}>{answers.Dealbreaker}</AlphaText>
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    boxText: {
        fontSize: 18,
    },
    largeBox: {
        marginTop: 10,
        backgroundColor: COLORS.lightPink,
        borderRadius: 8,
        padding: 15,
        width: '100%', //to fill space if text is shorter than one line
    },
})
