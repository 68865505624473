import React, { useEffect, useState, useRef } from 'react'
import { Dimensions, Modal, Pressable, View, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { SafeAreaView } from 'react-native-safe-area-context'
import HomeMainButton from '../ComponentsHome/HomeMainButton'
import VoteIcon from 'assets/icons/VoteIcon.png'
import ChatIcon from 'assets/icons/ChatIcon.png'
import ProfileIcon from 'assets/icons/ProfileIcon.png'
import SettingsIcon from 'assets/icons/SettingsIcon.png'
import { COLORS } from './../../Colors'

const { width: screenWidth, height: screenHeight } = Dimensions.get('window')
const guidelineBaseWidth = 415
const guidelineBaseHeight = 680

const horizontalScale = (size) => (screenWidth / guidelineBaseWidth) * size
const verticalScale = (size) => (screenHeight / guidelineBaseHeight) * size
let HS = screenWidth / 320
let VS = screenHeight / 640

export default function HomeDemo({ navigation }) {
    const [modalWelcome, setModalWelcome] = useState(true) //TEMPORARILY MADE FALSE - DISABLED. RE-ENABLE (SETe TRUE) ME FOR TUTORIAL FOR TESTING
    const [modalYouProfile, setModalYouProfile] = useState(false)
    const [modalTutorial1, setModalTutorial1] = useState(false)
    const [demoMessage, setDemoMessage] = useState(true)
    const [buttonsDisabled, setButtonsDisabled] = useState(true)
    const [location, setLocation] = useState(null)

    const handlePressed = (destination) => {
        if (typeof destination === 'string') {
            if (destination === 'MessagingDemo') {
                setDemoMessage(false)
            }
            if (destination === 'AnswersDemo') {
                setModalTutorial1(false)
                setButtonsDisabled(false)
            }
            navigation.navigate(destination)
        } else if (destination === 3) {
            setModalYouProfile(true)
        }
    }

    function createAccountPressed() {
        navigation.navigate('CreateAccount')
    }

    function handleCloseWelcome() {
        setModalWelcome(false)
        setModalTutorial1(true)
    }
    function handleCloseYouProfile() {
        setModalYouProfile(false)
    }

    const Tooltip = ({ text }) => {
        return (
            <View style={{ alignItems: 'center' }}>
                <View style={styles.triangle} />

                <View style={{ padding: 10, borderRadius: 10, backgroundColor: COLORS.redButton }}>
                    <AlphaText style={{ fontSize: 16, color: COLORS.white, textAlign: 'center' }}> {text} </AlphaText>
                </View>
            </View>
        )
    }

    // This below code is for positioning the tooltip on the Vote button
    const voteButtonRef = useRef(null)

    useEffect(() => {
        if (voteButtonRef.current) {
            voteButtonRef.current.measure((x, y, width, height, pageX, pageY) => {
                const tooltipWidth = screenWidth * 0.7 // DO NOT FUCKING CHANGE THIS NUMBER WITHOUT CHANGING WIDTH OF THE OVERLAY (WHICH IS SAME NUMBER AS PERCENTAGE)
                const tooltipX = screenWidth / 2 - tooltipWidth / 2
                const tooltipY = pageY + height - 64 - 5 //5 is the offset (value to play with), pageY is vertical position of view around vote button (ignores logo header height), 64 is height of header with logo (because), height is the height of the view around the vote button,
                // the top sets the location of the top of the tooltip triangle, from the bottom of the logo header
                setLocation({ top: tooltipY, left: tooltipX })
            })
        }
    }, [])

    return (
        <View style={{ display: 'flex', flex: 1, backgroundColor: COLORS.lightGreen }}>
            {modalTutorial1 && (
                <View pointerEvents="none" style={[styles.overlay, location && location]}>
                    <Tooltip
                        text={
                            'Press this button to vote on potential matches!\n\nYou can do this once per day (resets at 4AM CST)'
                        }
                    />
                </View>
            )}
            <Modal animationType="fade" transparent={true} visible={modalWelcome}>
                <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                    <View
                        style={{
                            backgroundColor: COLORS.modalColor,
                            padding: 20,
                            alignContent: 'space-around',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            flex: 1,
                            marginHorizontal: 20,
                            marginTop: 100,
                            marginBottom: 100,
                            borderRadius: 10,
                        }}
                    >
                        <AlphaText
                            style={{
                                color: COLORS.nearBlack,
                                fontSize: 30,
                                textAlign: 'center',
                            }}
                        >
                            Welcome
                        </AlphaText>
                        <AlphaText style={styles.modalText}>
                            You can test drive the app to see for yourself how it works. It's just a demo, so don't be
                            afraid to push all the buttons to see what happens!
                        </AlphaText>
                        <AlphaText style={styles.modalText}>
                            If you like what you see, press the dark gray button to create an account.
                        </AlphaText>
                        <View style={{ alignItems: 'center' }}>
                            <Pressable
                                onPress={handleCloseWelcome}
                                style={({ pressed }) => [
                                    {
                                        justifyContent: 'center',
                                        alignSelf: 'center',
                                        borderRadius: 5,
                                        padding: 10,
                                        marginHorizontal: 10,
                                        marginTop: 25,
                                        opacity: pressed ? 0.5 : 1,
                                        backgroundColor: COLORS.redButton,
                                    },
                                ]}
                            >
                                <AlphaText
                                    style={{
                                        fontSize: 17,
                                        color: COLORS.white,
                                        textAlign: 'center',
                                    }}
                                >
                                    Start Demo
                                </AlphaText>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </Modal>
            <Modal animationType="fade" transparent={true} visible={modalYouProfile}>
                <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                    <View
                        style={{
                            backgroundColor: COLORS.modalColor,
                            padding: 20,
                            alignContent: 'space-around',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            flex: 1,
                            marginHorizontal: 20,
                            marginTop: 150,
                            marginBottom: 150,
                            borderRadius: 10,
                        }}
                    >
                        <AlphaText style={styles.modalText}>
                            This button lets you view your own profile once you've created it.
                        </AlphaText>
                        <View style={{ alignItems: 'center' }}>
                            <Pressable
                                onPress={handleCloseYouProfile}
                                style={({ pressed }) => [
                                    {
                                        justifyContent: 'center',
                                        alignSelf: 'center',
                                        borderRadius: 5,
                                        paddingVertical: 10,
                                        paddingHorizontal: 20,
                                        marginHorizontal: 10,
                                        marginTop: 25,
                                        opacity: pressed ? 0.5 : 1,
                                        backgroundColor: COLORS.redButton,
                                    },
                                ]}
                            >
                                <AlphaText
                                    style={{
                                        fontSize: 17,
                                        color: COLORS.white,
                                        textAlign: 'center',
                                    }}
                                >
                                    OK
                                </AlphaText>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </Modal>
            {/* END OF MODALS START OF REAL CONTENT ************************************************************************************************************************** */}
            {/* the logo header bar is set in App.js using react navigation */}
            <View
                style={{
                    flex: 3,
                    flexDirection: 'column',
                    backgroundColor: COLORS.lightGreen,
                    height: '100%',
                    justifyContent: 'space-around',
                    marginVertical: 30,
                }}
            >
                <View ref={voteButtonRef} onLayout={() => {}}>
                    <HomeMainButton
                        title="Vote"
                        image={VoteIcon}
                        press={() => handlePressed('AnswersDemo')}
                        buttonsDisabled={false}
                    />
                </View>
                <View style={{ position: 'relative' }}>
                    <HomeMainButton
                        title="Chat"
                        image={ChatIcon}
                        press={() => handlePressed('MessagingDemo')}
                        buttonsDisabled={buttonsDisabled}
                    />
                    {demoMessage && (
                        <View
                            pointerEvents={'none'}
                            style={{
                                position: 'absolute',
                                right: 12,
                                top: -10,
                                width: 26 * HS,
                                height: 26 * HS,
                                backgroundColor: COLORS.redButton,
                                borderRadius: 13 * HS,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <AlphaText
                                style={{
                                    color: COLORS.white,
                                    fontSize: 12,
                                }}
                            >
                                1
                            </AlphaText>
                        </View>
                    )}
                </View>
                <HomeMainButton
                    title="Profile"
                    image={ProfileIcon}
                    press={() => handlePressed(3)}
                    buttonsDisabled={buttonsDisabled}
                />
                <HomeMainButton
                    title="Settings"
                    image={SettingsIcon}
                    press={() => handlePressed('SettingsDemo')}
                    buttonsDisabled={buttonsDisabled}
                />
            </View>

            <View style={{ backgroundColor: 'COLORS.lightGreen' }}>
                <Pressable
                    onPress={createAccountPressed}
                    disabled={buttonsDisabled}
                    style={({ pressed }) => [
                        {
                            flexDirection: 'column',
                            justifyContent: 'center',
                            paddingVertical: verticalScale(13),
                            borderRadius: verticalScale(10),
                            marginTop: verticalScale(10),
                            marginBottom: verticalScale(40),
                            marginHorizontal: horizontalScale(30),
                            opacity: pressed ? 0.5 : 1,
                            backgroundColor: COLORS.darkGray,
                        },
                    ]}
                >
                    <AlphaText style={{ fontSize: 20, textAlign: 'center', color: COLORS.white }}>
                        Create real account
                    </AlphaText>
                </Pressable>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    modalText: {
        color: COLORS.nearBlack,
        fontSize: 18,
        textAlign: 'center',
        paddingTop: 10,
    },
    triangle: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0, // change these to flip (also have to move view of triangle in ToolTip component definition)
        borderRightWidth: 10,
        borderBottomWidth: 10, // change these to flip
        borderLeftWidth: 10,
        borderTopColor: 'transparent', //change these to flip
        borderRightColor: 'transparent',
        borderBottomColor: COLORS.redButton, //change these to flip
        borderLeftColor: 'transparent',
    },
    overlay: {
        position: 'absolute',
        width: '70%', // DO NOT FUCKING CHANGE THIS NUMBER WITHOUT CHANGING TOOLTIPWIDTH (WHICH IS SAME NUMBER AS DECIMAL)
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
    },
})
