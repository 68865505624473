import { db } from './FirebaseInitialization'
import { setDoc, addDoc, updateDoc, doc, collection, serverTimestamp, arrayUnion } from 'firebase/firestore'
import { getAuth, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'

// export async function registration(email, password) {
//     try {
//         const auth = getAuth()
//         await createUserWithEmailAndPassword(auth, email, password)
//         await setDoc(doc(db, 'users', currentUser.uid), {
//             email: currentUser.email,
//         })
//         //need to add error handling here. presently, if setDoc fails, the account creation process proceeds anyways (which is a big problem later on because nothing saves)
//     } catch (err) {
//         window.alert('There is something wrong! Error 1.0', err.message)
//     }
// }

export async function signIn(email, password) {
    try {
        const auth = getAuth()
        await signInWithEmailAndPassword(auth, email, password)
    } catch (err) {
        window.alert('There is something wrong! Error 1.1', err, err.message)
    }
}

export async function loggingOut() {
    try {
        const auth = getAuth()
        await signOut(auth)
    } catch (err) {
        window.alert('There is something wrong! Error 1.2', err.message)
    }
}

export async function createReport(text, offender, screen) {
    try {
        const auth = getAuth()
        const currentUser = auth.currentUser
        const reporterEmail = currentUser.email
        await addDoc(collection(db, 'reports'), {
            resolved: 'no',
            reportingUser: currentUser.uid,
            reporterEmail: reporterEmail,
            offendingUser: offender,
            screen: screen,
            report: text,
        })
        // adds offending user to their blacklist
        updateDoc(doc(db, 'users', currentUser.uid), {
            blacklist: arrayUnion(offender),
        })
    } catch (err) {
        console.log('IS IT NOW???')
        console.log(err)
        window.alert('There is something wrong! Error 1.7', err.message)
    }
}
// PROBABLY SHOULD DOUBLE CHECK THE LOGIC OF ALL OF THESE BEFORE TESTING ANY OF THEM
export async function createFaceLink(uri, uid) {
    try {
        await setDoc(doc(db, 'users', uid, 'photos', 'face' + uid), {
            photoLink: uri,
        })
    } catch (err) {
        window.alert('There is something wrong! Error 1.8', err.message)
    }
}
export async function createBodyLink(uri, uid) {
    try {
        await setDoc(doc(db, 'users', uid, 'photos', 'body' + uid), {
            photoLink: uri,
        })
    } catch (err) {
        window.alert('There is something wrong! Error 1.8.1', err.message)
    }
}
export async function createLifeLink(uri, docName) {
    try {
        await setDoc(doc(db, 'users', uid, 'photos', docName), {
            photoLink: uri,
        })
    } catch (err) {
        window.alert('There is something wrong! Error 1.8.2', err.message)
    }
}

export async function createIdLink(uri, uid) {
    try {
        await updateDoc(doc(db, 'users', uid), { photoIdLink: uri })
    } catch (err) {
        window.alert('There is something wrong! Error 1.8.3', err.message)
    }
}

export async function createPendingUser(uid) {
    try {
        await setDoc(doc(db, 'pendingaccounts', uid), {
            timestamp: serverTimestamp(),
            user: uid,
        })
    } catch (err) {
        window.alert('There is something wrong! Error 1.9', err.message)
    }
}

//EVERYTHING BELOW IS CURRENTLY NOT HOOKED UP TO ANYTHING

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//UNUSED
// export async function updateAccountInfo(phone) {
//     try {
//         const currentUser = firebase.auth().currentUser;
//         db.collection("users").doc(currentUser.uid).update({
//             // email: "bob@gmail.com", eventually should have way to update password and maybe email      DON'T WORRY ABOUT THIS FOR NOW
//             phone: phone, //THIS WORKS
//         });
//     } catch (err) {
//         window.alert("There is something wrong! Error 1.3", err.message);
//     }
// }
//UNUSED
// export async function getKateNumber() {
//     try {
//         const herDeets = await db
//             .collection("users")
//             .doc("vIpzb5JpAJh5mdmwAFTvC7pINlD3") //THIS IS JUST FOR TESTING PURPOSES
//             .get();
//         // console.log(herDeets.data());
//         return herDeets.data();
//     } catch (err) {
//         window.alert("FAILURE");
//     }
// }

//MAYBE SHOULD MOVE THIS BACK HERE FROM ANSWERS???
// export async function uploadAnswers(answers) {
//     try {
//         const currentUser = firebase.auth().currentUser;

//         db.collection("users")
//             .doc(currentUser.uid) //NEEDS TO BE CALLED WHEN THEY CREATE OR EDIT ANSWERS (duh)
//             .collection("answers")
//             .doc("answers")
//             .update({
//                 answers: answers,
//             });
//     } catch (err) {
//         window.alert("There is something wrong! Error 1.4", err.message);
//     }
// }

//DELETE THIS ABOVE COMMENTED OUT STUFF ONCE APP IS FULLY OPERATIONAL

//I THINK THIS BELOW COMMENTED OUT STUFF MIGHT BE USEFUL??? OR MAYBE OLD/REDUNDANT AND NEED TO DELETE?
// export async function createSpecialMatch(userid) {
//     try {
//         const currentUser = firebase.auth().currentUser;

//         db.collection("users")
//             .doc(currentUser.uid) //NEEDS TO BE CALLED WHEN A MATCH IS ACTIVATED. MATCH SHOULD BE CHECKED FOR EVERYTIME SOMEONE IS "SELECTED". WHEN MATCH OCCURS NEEDS TO UPDATE BOTH PEOPLES' DATABASES AND SHOW USER THEY GOT A MATCH AND ENABLE CHAT
//             .collection("special_people")
//             .doc("matches")
//             .update({
//                 matches: firebase.firestore.FieldValue.arrayUnion(userid),
//             });
//     } catch (err) {
//         window.alert("There is something wrong! Error 1.5", err.message);
//     }
// }

// export async function createSpecialSelected(userid) {
//     try {
//         const currentUser = firebase.auth().currentUser;

//         db.collection("users")
//             .doc(currentUser.uid) //NEEDS TO BE CALLED WHEN THEY SELECT THE PHOTO OF SOMEONE WHO THEY SELECTED THE ANSWERS OF
//             .collection("special_people")
//             .doc("selected")
//             .update({
//                 selected: firebase.firestore.FieldValue.arrayUnion(userid),
//             });
//     } catch (err) {
//         window.alert("There is something wrong! Error 1.5", err.message);
//     }
// }

// export async function createSpecialBlacklist(userid) {
//     try {
//         const currentUser = firebase.auth().currentUser;

//         db.collection("users")
//             .doc(currentUser.uid) //NEEDS TO BE CALLED WHEN THEY USE THE RED BUTTON ON SOMEONE or when they file a report
//             .collection("special_people") //When this is called, also need to add blacklist userid to F.78701. This probs needs to be done by a function for security reasons
//             .doc("blacklist")
//             .update({
//                 blacklist: firebase.firestore.FieldValue.arrayUnion(userid),
//             });
//     } catch (err) {
//         window.alert("There is something wrong! Error 1.5", err.message);
//     }
// }

//IDK WTF THIS IS - SORRY
// export async function createMatchingEntry(
//     age,
//     blacklist,
//     gender,
//     primaryZipcode
// ) {
//     try {
//         const currentUser = firebase.auth().currentUser; //THIS MIGHT ONLY CURRENTLY BE IN-USE IN THE ADMIN APP
//         const collection = gender + "." + primaryZipcode; //NEEDS TO BE CALLED WHEN ACCOUNT IS APPROVED (or after they use app to select people for the second day* this could be problem at app launch lol)
//         // & NEEDS TO BE CALLED ANYTIME THE AGE UPDATES, PRIMARY ZIP UPDATES, OR THEY UPDATE THEIR BLACKLIST
//         db.collection(collection).doc(currentUser.uid).update({
//             age: age,
//             blacklist: blacklist,
//         });
//     } catch (err) {
//         window.alert("There is something wrong! Error 1.6", err.message);
//     }
// }

// UNUSED
// export async function getPhotoTesting(uri) {
//     ///THIS IS FOR TESTING SECURITY RULES BUT MAY BE USEFUL LATER
//     try {
//         //const currentUser = firebase.auth().currentUser;
//         const link = await db
//             .collection("users")
//             .doc("hOzKWsQiK4X3XRjwQ4ICx5vRoZ53") //BOB'S UID
//             .collection("photos")
//             .doc("9j49a1bKf8CFPsq1C9EN")
//             .get();
//         // console.log("something");
//         // console.log(link);
//     } catch (err) {
//         window.alert("Error 2.28", err.message);
//     }
// }
