import React, { useContext } from 'react'
import { View, TextInput } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import ModalPrimary from '../ComponentsShared/ModalPrimary'
import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
import { COLORS } from '../../Colors'
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function ModalNameHeight({
    submitName,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    nickname,
    setNickname,
    feet,
    setFeet,
    inches,
    setInches,
}) {
    const { HS, VS } = useContext(ScreenSizeContext)

    return (
        <ModalPrimary>
            <AlphaText
                style={{
                    fontSize: 25,
                    textAlign: 'center',
                }}
            >
                Last Step
            </AlphaText>
            <AlphaText
                style={{
                    marginTop: 15 * VS,
                    fontSize: 15,
                    textAlign: 'center',
                }}
            >
                Please enter your first and last name as they appear on your ID, omitting the middle name or initial.
            </AlphaText>
            <View style={{ paddingTop: 5 }}>
                <TextInput
                    style={{
                        color: COLORS.nearBlack,
                        borderWidth: 1,
                        fontFamily: 'Medium',
                        margin: 5 * HS,
                        width: 180 * HS,
                        height: 40 * HS,
                        fontSize: 18 * ((VS + HS) / 2) * 0.85, //if you update calculations on this line, need to update alphatext and other textinputs
                        backgroundColor: COLORS.white,
                        paddingHorizontal: 10 * HS,
                        borderRadius: 10 * HS,
                    }}
                    placeholder="Jonathan"
                    placeholderTextColor={COLORS.darkGray}
                    maxLength={20}
                    onChangeText={(firstName) => setFirstName(firstName)}
                    value={firstName}
                    textContentType="givenName"
                ></TextInput>
            </View>
            <TextInput
                style={{
                    color: COLORS.nearBlack,
                    borderWidth: 1,
                    fontFamily: 'Medium',
                    margin: 5 * HS,
                    width: 180 * HS,
                    height: 40 * HS,
                    fontSize: 18 * ((VS + HS) / 2) * 0.85, //if you update calculations on this line, need to update alphatext and other textinputs
                    backgroundColor: COLORS.white,
                    paddingHorizontal: 10 * HS,
                    borderRadius: 10 * HS,
                }}
                placeholder="Doe"
                placeholderTextColor={COLORS.darkGray}
                maxLength={20}
                onChangeText={(lastName) => setLastName(lastName)}
                value={lastName}
                textContentType="familyName"
            ></TextInput>
            <AlphaText
                style={{
                    fontSize: 15,
                    textAlign: 'center',
                    marginTop: 10 * VS,
                }}
            >
                Only the below name will be shown to other users, alongside your photos.
            </AlphaText>
            <View style={{ flexDirection: 'row', marginTop: 5 * VS }}>
                <AlphaText
                    style={{
                        fontSize: 18,
                        textAlign: 'center',
                        alignSelf: 'center',
                    }}
                >
                    First or Nickname:
                </AlphaText>
                <TextInput
                    style={{
                        color: COLORS.nearBlack,
                        borderWidth: 1,
                        fontFamily: 'Medium',
                        margin: 5 * HS,
                        width: '100%',
                        height: 40 * HS,
                        fontSize: 18 * ((VS + HS) / 2) * 0.85, //if you update calculations on this line, need to update alphatext and other textinputs
                        backgroundColor: COLORS.white,
                        paddingHorizontal: 10 * HS,
                        borderRadius: 10 * HS,
                    }}
                    placeholder="Jon"
                    placeholderTextColor={COLORS.darkGray}
                    maxLength={20}
                    onChangeText={(nickname) => setNickname(nickname)}
                    value={nickname}
                    textContentType="nickname"
                ></TextInput>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 5 * VS }}>
                <AlphaText
                    style={{
                        fontSize: 18,
                        textAlign: 'center',
                        alignSelf: 'center',
                    }}
                >
                    Height:
                </AlphaText>
                <TextInput
                    style={{
                        color: COLORS.nearBlack,
                        borderWidth: 1,
                        fontFamily: 'Medium',
                        margin: 5 * HS,
                        width: 35 * HS,
                        height: 40 * HS,
                        fontSize: 18 * ((VS + HS) / 2) * 0.85, //if you update calculations on this line, need to update alphatext and other textinputs
                        backgroundColor: COLORS.white,
                        paddingHorizontal: 10 * HS,
                        borderRadius: 10 * HS,
                    }}
                    placeholder="5"
                    placeholderTextColor={COLORS.darkGray}
                    maxLength={1}
                    onChangeText={(feet) => setFeet(feet)}
                    value={feet}
                    keyboardType="number-pad"
                ></TextInput>
                <AlphaText
                    style={{
                        fontSize: 18,
                        textAlign: 'center',
                        alignSelf: 'center',
                    }}
                >
                    feet
                </AlphaText>
                <TextInput
                    style={{
                        color: COLORS.nearBlack,
                        borderWidth: 1,
                        fontFamily: 'Medium',
                        margin: 5 * HS,
                        width: 45 * HS,
                        height: 40 * HS,
                        fontSize: 18 * ((VS + HS) / 2) * 0.85, //if you update calculations on this line, need to update alphatext and other textinputs
                        backgroundColor: COLORS.white,
                        paddingHorizontal: 10 * HS,
                        borderRadius: 10 * HS,
                        textAlign: 'right',
                    }}
                    placeholder="6"
                    placeholderTextColor={COLORS.darkGray}
                    maxLength={2}
                    onChangeText={(inches) => setInches(inches)}
                    value={inches}
                    keyboardType="number-pad"
                ></TextInput>
                <AlphaText
                    style={{
                        fontSize: 18,
                        textAlign: 'center',
                        alignSelf: 'center',
                    }}
                >
                    inches
                </AlphaText>
            </View>
            <AlphaText
                style={{
                    fontSize: 15,
                    textAlign: 'center',
                }}
            >
                Height is optional, but recommended, and will be displayed with your photos. Please be honest.
            </AlphaText>
            <ButtonPrimary buttonText={'Upload Name'} onPress={submitName} />
        </ModalPrimary>
    )
}
