import { useState, useEffect, useContext } from 'react'
import { View, KeyboardAvoidingView, Pressable, StyleSheet, TextInput, Modal, Dimensions } from 'react-native'
import AlphaText from './TextAlpha'
import { COLORS } from '../../Colors'
import { createReport } from '../../FirebaseMethods'
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function ModalReport({ setReportModal, demo, uidOfProfile, screen }) {
    const { HS, VS } = useContext(ScreenSizeContext)
    const [messageModal, setMessageModal] = useState(false)
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [reportText, onChangeText] = useState('')

    let violationsText = ''
    if (screen === 'answers') {
        violationsText = `*They reveal social media handle\n*They give non-answers to ?'s\n*Hate speech\n*Give away identity\n*Trolling`
    } else if (screen === 'messaging') {
        violationsText = `*They are trying to sell something\n*Hate speech\n*Unjustifiably rude (without provocation)`
    } else if (screen === 'photos') {
        violationsText = `*They reveal social media handle\n*Their photos do not meet guidelines\n*Give away identity`
    }
    const backPressed = () => {
        setReportModal(false)
    }
    const submitPressed = () => {
        if (!demo) {
            createReport(reportText, uidOfProfile, screen)
        }
        setMessageModal(true)
    }
    const messageModalPressed = () => {
        setMessageModal(false)
        setReportModal(false)
    }
    useEffect(() => {
        if (reportText !== '') {
            setSubmitDisabled(false)
        } else {
            setSubmitDisabled(true)
        }
    }, [reportText])

    return (
        <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
            {/* MODALCEPTION STARTS HERE*********************************************************************************************************************/}
            <Modal visible={messageModal} animationType="fade" transparent={true}>
                <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                    <View
                        style={{
                            backgroundColor: COLORS.lightGreen,
                            paddingVertical: 50,
                            paddingHorizontal: 15,
                            margin: 40,
                            borderRadius: 10,
                            alignItems: 'center',
                        }}
                    >
                        <AlphaText style={{ fontSize: 30, marginVertical: 30, textAlign: 'center' }}>
                            Report Sent Successfully
                        </AlphaText>
                        {demo && (
                            <AlphaText style={{ fontSize: 20, marginVertical: 10, textAlign: 'center' }}>
                                Except not really, because this is just a demo
                            </AlphaText>
                        )}

                        <Pressable
                            onPress={messageModalPressed}
                            style={({ pressed }) => [
                                styles.pressable,
                                { backgroundColor: COLORS.redButton, opacity: pressed ? 0.5 : 1 },
                            ]}
                        >
                            <AlphaText style={{ color: COLORS.white, fontSize: 18 }}>OK</AlphaText>
                        </Pressable>
                    </View>
                </View>
            </Modal>
            {/* MODALCEPTION ENDS HERE**********************************************************************************************************************/}
            <View
                style={{
                    backgroundColor: COLORS.lightGreen,
                    paddingVertical: 20,
                    paddingHorizontal: 15,
                    margin: 20,
                    borderRadius: 10,
                    alignItems: 'center',
                }}
            >
                <AlphaText style={{ fontSize: 30, marginVertical: 10 }}>Report This User</AlphaText>
                <TextInput
                    placeholder="Specifics about violation..."
                    placeholderTextColor={COLORS.darkGray}
                    onChangeText={onChangeText}
                    value={reportText}
                    style={{
                        backgroundColor: COLORS.white,
                        width: '95%',
                        marginVertical: 10,
                        padding: 10,
                        fontSize: 18 * ((VS + HS) / 2) * 0.85, //if you update the calculations on this line, need to update alphatext and other textinputs
                        fontFamily: 'Medium',
                        borderWidth: 2,
                        borderRadius: 5,
                    }}
                ></TextInput>
                <AlphaText style={{ fontSize: 20, marginTop: 10 }}>{'Some Possible Violations'}</AlphaText>
                <AlphaText style={{ fontSize: 16 }}>{violationsText} </AlphaText>
                <AlphaText style={{ fontSize: 16, textAlign: 'center', marginVertical: 10 }}>
                    A person will review your report and take action. Please help them identify the problem.
                </AlphaText>
                <View style={{ flexDirection: 'row', marginTop: 10, justifyContent: 'space-around' }}>
                    <Pressable
                        onPress={backPressed}
                        style={({ pressed }) => [
                            styles.pressable,
                            { backgroundColor: COLORS.darkGray, opacity: pressed ? 0.5 : 1 },
                        ]}
                    >
                        <AlphaText style={{ color: COLORS.white, fontSize: 16 }}>Back</AlphaText>
                    </Pressable>
                    <Pressable
                        onPress={submitPressed}
                        disabled={submitDisabled}
                        style={({ pressed }) => [
                            styles.pressable,
                            { backgroundColor: COLORS.redButton, opacity: pressed || submitDisabled ? 0.5 : 1 },
                        ]}
                    >
                        <AlphaText style={{ color: COLORS.white, fontSize: 18, textAlign: 'center' }}>
                            Send Report
                        </AlphaText>
                    </Pressable>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    pressable: {
        borderRadius: 7,
        width: '75%',
        height: 50,
        marginHorizontal: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
