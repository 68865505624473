import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getAuth, connectAuthEmulator, createUserWithEmailAndPassword } from 'firebase/auth'
import { getApp } from 'firebase/app' //IS THIS GONNA THROW ERROR?
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging } from 'firebase/messaging'

// Your web app's Firebase configuration. Maybe this should be in gitignore folder for security?

const firebaseConfig = {
    apiKey: 'AIzaSyCBrwAuR9JUDMvjoU79rut4fQn4_IkIA18',
    authDomain: 'dating-app-2-3de32.firebaseapp.com',
    projectId: 'dating-app-2-3de32',
    storageBucket: 'dating-app-2-3de32.appspot.com',
    messagingSenderId: '1056701282631', // this is old and maybe unrelated to current FCM messaging
    appId: '1:1056701282631:web:1d419f7cb6346f35fdf462',
    measurementId: 'G-8P0VWMHXLB',
}

const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)
//IDK IF FIRESTORE AND AUTH NEED TO BE INITIALIZED HERE FOR FUNCTIONS TO TEST APPROPRIATELY OR IF CAN DELETE (update, so this is a mess, because of comments if nothing else - tech debt)
export const functions = getFunctions(getApp()) //don't comment this one out ever I think
export const analytics = getAnalytics(app) // I don't think analytics works in demo, so that's ok, just TEST IN PROD
export const messaging = getMessaging(app)

/////////COMMENT THESE BACK IN FOR EMULATOR - THEN RUN "firebase emulators:start" (or "firebase emulators:start --import=./fake-user-testbed") BEFORE RUNNING "npx expo start" ///////////////////////////////////
// connectFirestoreEmulator(db, 'localhost', 8080)
// const auth = getAuth()
// connectAuthEmulator(auth, 'http://localhost:9099')
// connectFunctionsEmulator(functions, 'localhost', 5001)
// const storage = getStorage()
// connectStorageEmulator(storage, 'localhost', 9199)
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
