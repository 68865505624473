import React, { useState, useEffect, useRef } from 'react'
import { Dimensions, Pressable, Image } from 'react-native'
import { COLORS } from '../../Colors'
import TextAlpha from '../ComponentsShared/TextAlpha'
// import * as Svg from 'react-native-svg';

const { width, height } = Dimensions.get('window')
const guidelineBaseWidth = 415
const guidelineBaseHeight = 680

const horizontalScale = (size) => (width / guidelineBaseWidth) * size
const verticalScale = (size) => (height / guidelineBaseHeight) * size
let HS = width / 320
let VS = height / 640

export default function HomeMainButton(props) {
    const imageWidthHeight = horizontalScale(34)

    return (
        <Pressable
            onPress={() => props.press()}
            disabled={props.buttonsDisabled}
            style={({ pressed }) => [
                {
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: verticalScale(10),
                    // marginVertical: verticalScale(5),
                    marginHorizontal: horizontalScale(30),
                    opacity: pressed ? 0.5 : 1,
                    backgroundColor: COLORS.white,
                },
            ]}
        >
            <Image
                style={{
                    resizeMode: 'contain',
                    width: imageWidthHeight,
                    height: imageWidthHeight,
                    marginTop: verticalScale(20),
                    marginBottom: verticalScale(5),
                }}
                source={props.image}
            ></Image>
            <TextAlpha style={{ fontSize: 20, textAlign: 'center', marginBottom: verticalScale(15) }}>
                {props.title}
            </TextAlpha>
        </Pressable>
    )
}
