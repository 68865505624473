import 'react-native-gesture-handler'
import React, { useEffect } from 'react'
import { Image, View, Platform } from 'react-native'
import { useFonts } from 'expo-font'
import { NavigationContainer } from '@react-navigation/native' //this is from V6
import { createStackNavigator } from '@react-navigation/stack'
// import { createAppContainer } from "@react-navigation/native"; //this is necessary for V4 I think
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Ionicons } from '@expo/vector-icons'
// import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import BlankPlaceholder from './zApp/demo/BlankPlaceholder'

import HomeDemo from './zApp/demo/HomeDemo'
import MessagingDemo from './zApp/demo/MessagingDemo'
import AnswersDemo from './zApp/demo/AnswersDemo'
import PhotosDemo from './zApp/demo/PhotosDemo'
import SettingsDemo from './zApp/demo/SettingsDemo'
import DoneVotingDemo from './zApp/demo/DoneVotingDemo'
import FeedbackDemo from './zApp/demo/FeedbackDemo'

import PhotosVoting from './zApp/main/PhotosVoting'
import AnswersVoting from './zApp/main/AnswersVoting'
import Messaging from './zApp/main/Messaging'
import HomeScreen from './zApp/main/HomeScreen'
import MessagingMenu from './zApp/main/MessagingMenu'
import DoneVoting from './zApp/main/DoneVoting'
import ProfileEditingMenu from './zApp/main/ProfileEditingMenu'
import ProfilePhotosEditor from './zApp/main/ProfilePhotosEditor'
import ProfileAnswersEditor from './zApp/main/ProfileAnswersEditor'
import Settings from './zApp/main/Settings'
import Feedback from './zApp/main/Feedback'
import SignUpFeedback from './zApp/onboarding/SignUpFeedback'

import AnswerQuestions from './zApp/onboarding/AnswerQuestions'
import PictureOnboarding from './zApp/onboarding/PictureOnboarding'
import ExampleFace from './zApp/onboarding/ExamplePicturesFace'
import ExampleBody from './zApp/onboarding/ExamplePicturesBody'
import ExampleLife from './zApp/onboarding/ExamplePicturesLife'
import UploadFace from './zApp/onboarding/UploadPicturesFace'
import UploadBody from './zApp/onboarding/UploadPicturesBody'
import UploadLife from './zApp/onboarding/UploadPicturesLife'
import DoHaveAccount from './zApp/onboarding/DoHaveAccount'
import DemoOrCreate from './zApp/onboarding/DemoOrCreate'
import CreateAccount from './zApp/onboarding/CreateAccount'
import VerifyEmail from './zApp/onboarding/VerifyEmail'
import SignIn from './zApp/main/SignIn'
import Authentication from './zApp/onboarding/Authentication'
import GenderSex from './zApp/onboarding/GenderSex'
import AgeZipcode from './zApp/onboarding/AgeZipcode'
// import TermsOfService from './zApp/onboarding/TermsOfService'
import AccountCompleted from './zApp/onboarding/AccountCompleted'

//design for widths 320-480 (1024 max tablet width) and heights 640-896 (1366 max tablet height)

//i don't think you're using rn size matters so far even though you added it
import * as ImagePicker from 'expo-image-picker'
import * as Notifications from 'expo-notifications'
// import * as Permissions from "expo-permissions";
import Constants from 'expo-constants'

import * as uuid from 'uuid'

// import downloadImageExample, { images3 } from "./zApp/unused/DownloadImage";
// import { LogBox } from "react-native";
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// LogBox.ignoreLogs(["Setting a timer"]);
//NOT USING THEMECONTEXT????
export const ThemeContext = React.createContext()

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
    }),
})

const Stack = createStackNavigator()

export default function App() {
    let [loaded] = useFonts({
        Medium: require('./assets/fonts/MPLUSRounded1c-Medium.ttf'),
    })
    if (!loaded) {
        return null
    }

    const logoHeader = () => {}
    return (
        <NavigationContainer>
            {/* setting screenOptions={{cardStyle: {flex: 1}}} on the Stack.Navigator fixes awful whole-page-scrolling which is default on web */}

            {/* initial screen should be "Thrive" (HomeScreen, renamed to Thrive because name is displayed during PWA install on safari)  */}
            <Stack.Navigator initialRouteName="Thrive" screenOptions={{ headerShown: false, cardStyle: { flex: 1 } }}>
                <Stack.Screen name="AnswersDemo" component={AnswersDemo} />
                <Stack.Screen name="BlankPlaceholder" component={BlankPlaceholder} />
                <Stack.Screen name="MessagingDemo" component={MessagingDemo} />
                <Stack.Screen name="PhotosDemo" component={PhotosDemo} gestureEnabled="false" />
                <Stack.Screen name="SettingsDemo" component={SettingsDemo} />
                <Stack.Screen name="FeedbackDemo" component={FeedbackDemo} />
                <Stack.Screen name="DoneVotingDemo" component={DoneVotingDemo} gestureEnabled="false" />
                <Stack.Screen name="VerifyEmail" component={VerifyEmail} gestureEnabled="false" />
                <Stack.Screen name="GenderSex" component={GenderSex} gestureEnabled="false" />
                <Stack.Screen name="AgeZipcode" component={AgeZipcode} gestureEnabled="false" />
                {/* <Stack.Screen name="TermsOfService" component={TermsOfService} gestureEnabled="false" /> */}
                <Stack.Screen name="PictureOnboarding" component={PictureOnboarding} gestureEnabled="false" />
                <Stack.Screen name="ExampleFace" component={ExampleFace} gestureEnabled="false" />
                <Stack.Screen name="ExampleBody" component={ExampleBody} gestureEnabled="false" />
                <Stack.Screen name="ExampleLife" component={ExampleLife} gestureEnabled="false" />
                <Stack.Screen name="UploadFace" component={UploadFace} />
                <Stack.Screen name="UploadBody" component={UploadBody} />
                <Stack.Screen name="UploadLife" component={UploadLife} />
                <Stack.Screen name="AnswerQuestions" component={AnswerQuestions} gestureEnabled="false" />
                <Stack.Screen name="Authentication" component={Authentication} gestureEnabled="false" />
                <Stack.Screen name="AccountCompleted" component={AccountCompleted} gestureEnabled="false" />
                <Stack.Screen name="SignUpFeedback" component={SignUpFeedback} gestureEnabled="false" />

                {/* ALL THE BELOW (except the ones with headers) NEED UPDATES */}
                <Stack.Screen name="ProfileEditingMenu" component={ProfileEditingMenu} />
                <Stack.Screen name="ProfilePhotosEditor" component={ProfilePhotosEditor} />
                <Stack.Screen name="ProfileAnswersEditor" component={ProfileAnswersEditor} />
                <Stack.Screen name="Settings" component={Settings} />
                <Stack.Screen name="Feedback" component={Feedback} />
                <Stack.Screen name="MessagingMenu" component={MessagingMenu} />
                <Stack.Screen name="DoneVoting" component={DoneVoting} gestureEnabled="false" />
                <Stack.Screen name="PhotosVoting" component={PhotosVoting} gestureEnabled="false" />
                <Stack.Screen name="AnswersVoting" component={AnswersVoting} gestureEnabled="false" />
                {/* MESSAGING NEEDS TO BE FIXED */}
                <Stack.Screen name="Messaging" component={Messaging} />
                <Stack.Screen
                    name="CreateAccount"
                    component={CreateAccount}
                    style={{ display: 'flex', justifyContent: 'center' }}
                    options={{
                        headerShadowVisible: false, //idk if this change is visible
                        headerStyle: {
                            shadowColor: 'transparent', //fixes line on iOS
                            elevation: 0, //fixes line on Android (allegedly - not verified)
                        },
                        headerTitleAlign: 'center',
                        // setting headerleft to null removes the back button which react native forces upon you. they have a prop specifically for that purpose but it's broken af
                        headerLeft: (props) => null,
                        headerShown: true,
                        headerTitle: (
                            props // App Logo
                        ) => (
                            <View>
                                <View style={{ height: 17 }} />
                                <Image
                                    style={{ width: 120, height: 50, alignSelf: 'center' }}
                                    source={require('./assets/notButtons/ThriveLogo.png')}
                                    resizeMode="contain"
                                />
                                <View style={{ height: 20 }} />
                            </View>
                        ),
                    }}
                />

                <Stack.Screen
                    name="SignIn"
                    component={SignIn}
                    style={{ display: 'flex', justifyContent: 'center' }}
                    options={{
                        headerShadowVisible: false, //idk if this change is visible
                        headerStyle: {
                            shadowColor: 'transparent', //fixes line on iOS
                            elevation: 0, //fixes line on Android (allegedly - not verified)
                        },
                        headerTitleAlign: 'center',
                        // setting headerleft to null removes the back button which react native forces upon you. they have a prop specifically for that purpose but it's broken af
                        headerLeft: (props) => null,
                        headerShown: true,
                        headerTitle: (
                            props // App Logo
                        ) => (
                            <View>
                                {/* IF YOU ADJUST THIS, NEED TO ADJUST MODAL POSITIONING (HOMEDEMO, POSSIBLY ELSEWHERE). IT IS HARDCODED BASED OFF THIS BEING A HEIGHT OF 64 (IDK HOW 17+50+20 = 64, BUT IT DOES) */}
                                <View style={{ height: 17 }} />
                                <Image
                                    style={{ width: 120, height: 50, alignSelf: 'center' }}
                                    source={require('./assets/notButtons/ThriveLogo.png')}
                                    resizeMode="contain"
                                />
                                <View style={{ height: 20 }} />
                            </View>
                        ),
                    }}
                />

                <Stack.Screen
                    name="DoHaveAccount"
                    component={DoHaveAccount}
                    style={{ display: 'flex', justifyContent: 'center' }}
                    options={{
                        headerShadowVisible: false, //idk if this change is visible
                        headerStyle: {
                            shadowColor: 'transparent', //fixes line on iOS
                            elevation: 0, //fixes line on Android (allegedly - not verified)
                        },
                        headerTitleAlign: 'center',
                        // setting headerleft to null removes the back button which react native forces upon you. they have a prop specifically for that purpose but it's broken af
                        headerLeft: (props) => null,
                        headerShown: true,
                        headerTitle: (
                            props // App Logo
                        ) => (
                            <View>
                                <View style={{ height: 17 }} />
                                {/* IF YOU ADJUST THIS, NEED TO ADJUST MODAL POSITIONING (HOMEDEMO, POSSIBLY ELSEWHERE). IT IS HARDCODED BASED OFF THIS BEING A HEIGHT OF 64 (IDK HOW 17+50+20 = 64, BUT IT DOES) */}

                                <Image
                                    style={{ width: 120, height: 50, alignSelf: 'center' }}
                                    source={require('./assets/notButtons/ThriveLogo.png')}
                                    resizeMode="contain"
                                />
                                <View style={{ height: 20 }} />
                            </View>
                        ),
                    }}
                />
                <Stack.Screen
                    name="DemoOrCreate"
                    component={DemoOrCreate}
                    style={{ display: 'flex', justifyContent: 'center' }}
                    options={{
                        headerShadowVisible: false, //idk if this change is visible
                        headerStyle: {
                            shadowColor: 'transparent', //fixes line on iOS
                            elevation: 0, //fixes line on Android (allegedly - not verified)
                        },
                        headerTitleAlign: 'center',
                        // setting headerleft to null removes the back button which react native forces upon you. they have a prop specifically for that purpose but it's broken af
                        headerLeft: (props) => null,
                        headerShown: true,
                        headerTitle: (
                            props // App Logo
                        ) => (
                            <View>
                                <View style={{ height: 17 }} />
                                {/* IF YOU ADJUST THIS, NEED TO ADJUST MODAL POSITIONING (HOMEDEMO, POSSIBLY ELSEWHERE). IT IS HARDCODED BASED OFF THIS BEING A HEIGHT OF 64 (IDK HOW 17+50+20 = 64, BUT IT DOES) */}

                                <Image
                                    style={{ width: 120, height: 50, alignSelf: 'center' }}
                                    source={require('./assets/notButtons/ThriveLogo.png')}
                                    resizeMode="contain"
                                />
                                <View style={{ height: 20 }} />
                            </View>
                        ),
                    }}
                />
                <Stack.Screen
                    name="Thrive"
                    component={HomeScreen}
                    gestureEnabled="false"
                    style={{ display: 'flex', justifyContent: 'center' }}
                    options={{
                        headerShadowVisible: false, //idk if this change is visible
                        headerStyle: {
                            shadowColor: 'transparent', //fixes line on iOS
                            elevation: 0, //fixes line on Android (allegedly - not verified)
                        },
                        headerTitleAlign: 'center',
                        // setting headerleft to null removes the back button which react native forces upon you. they have a prop specifically for that purpose but it's broken af
                        headerLeft: (props) => null,
                        headerShown: true,
                        headerTitle: (
                            props // App Logo
                        ) => (
                            <View>
                                {/* IF YOU ADJUST THIS, NEED TO ADJUST MODAL POSITIONING (HOMEDEMO, POSSIBLY ELSEWHERE). IT IS HARDCODED BASED OFF THIS BEING A HEIGHT OF 64 (IDK HOW 17+50+20 = 64, BUT IT DOES) */}

                                <View style={{ height: 17 }} />

                                <Image
                                    style={{ width: 120, height: 50, alignSelf: 'center' }}
                                    source={require('./assets/notButtons/ThriveLogo.png')}
                                    resizeMode="contain"
                                />
                                <View style={{ height: 20 }} />
                            </View>
                        ),
                    }}
                />
                <Stack.Screen
                    name="HomeDemo"
                    component={HomeDemo}
                    gestureEnabled="false"
                    style={{ display: 'flex', justifyContent: 'center' }}
                    options={{
                        headerShadowVisible: false, //idk if this change is visible
                        headerStyle: {
                            shadowColor: 'transparent', //fixes line on iOS
                            elevation: 0, //fixes line on Android (allegedly - not verified)
                        },
                        headerTitleAlign: 'center',
                        // setting headerleft to null removes the back button which react native forces upon you. they have a prop specifically for that purpose but it's broken af
                        headerLeft: (props) => null,
                        headerShown: true,
                        headerTitle: (
                            props // App Logo
                        ) => (
                            <View>
                                {/* IF YOU ADJUST THIS, NEED TO ADJUST MODAL POSITIONING (HOMEDEMO, POSSIBLY ELSEWHERE). IT IS HARDCODED BASED OFF THIS BEING A HEIGHT OF 64 (IDK HOW 17+50+20 = 64, BUT IT DOES) */}

                                <View style={{ height: 17 }} />

                                <Image
                                    style={{ width: 120, height: 50, alignSelf: 'center' }}
                                    source={require('./assets/notButtons/ThriveLogo.png')}
                                    resizeMode="contain"
                                />
                                <View style={{ height: 20 }} />
                            </View>
                        ),
                    }}
                />
            </Stack.Navigator>
        </NavigationContainer>
    )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register()
// update: 6-29-23. idk what the serviceworkerregistration line does.
