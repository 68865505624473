import React, { useState, useEffect, useContext } from 'react'
import { Dimensions, View, Text, TextInput, Keyboard, Modal, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'

import * as Linking from 'expo-linking'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from './../../Colors'
import { db } from './../../FirebaseInitialization'
import { doc, setDoc, updateDoc } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function AgeZipcode({ navigation, route }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    function handleBackButtonClick() {
        return true
    }

    const { gender, sexOrientation, max, min } = route.params

    const [textConfirmation, setTextConfirmation] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const [modalOutOfArea, setModalOutOfArea] = useState(false)
    const [modalAlert, setModalAlert] = useState(false)
    const [alertModalText, setAlertModalText] = useState('error')
    const [age, setAge] = useState('')
    const [zipcode1, setZipcode1] = useState('')
    // const [zipcode2, setZipcode2] = useState("");
    // const [zipcode3, setZipcode3] = useState("");
    // const [zipcode4, setZipcode4] = useState("");
    // const [zipcode5, setZipcode5] = useState("");
    // const [zipcode6, setZipcode6] = useState("");
    // const [zipcode7, setZipcode7] = useState("");
    // const [zipcodes, setZipcodes] = useState([]);
    let zone1 = [53051, 53046, 53089, 53072, 53045, 53007, 53005, 53122, 53188, 53186, 53189, 53151, 53146, 53150]
    let zone2 = [
        53224, 53223, 53217, 53209, 53225, 53218, 53222, 53216, 53210, 53206, 53212, 53211, 53226, 53213, 53208, 53205,
        53233, 53203, 53202,
    ]
    let zone3 = [
        53214, 53215, 53204, 53227, 53219, 53207, 53228, 53220, 53221, 53235, 53110, 53130, 53129, 53132, 53154, 53172,
    ]
    let allZones = [
        53051, 53046, 53089, 53072, 53045, 53007, 53005, 53122, 53188, 53186, 53189, 53151, 53146, 53150, 53224, 53223,
        53217, 53209, 53225, 53218, 53222, 53216, 53210, 53206, 53212, 53211, 53226, 53213, 53208, 53205, 53233, 53203,
        53202, 53214, 53215, 53204, 53227, 53219, 53207, 53228, 53220, 53221, 53235, 53110, 53130, 53129, 53132, 53154,
        53172,
    ]
    function updateZipcode1(zipcode) {
        setZipcode1(zipcode)

        if (zipcode.length > 4) {
            zipcode = Number(zipcode)

            if (zone1.includes(zipcode)) {
                // let zipcodesShuffled = zone1;
                // for (var i = zipcodesShuffled.length - 1; i > 0; i--) {
                //     var j = Math.floor(Math.random() * (i + 1));
                //     var temp = zipcodesShuffled[i];
                //     zipcodesShuffled[i] = zipcodesShuffled[j];
                //     zipcodesShuffled[j] = temp;
                //     setZipcodes(zipcodesShuffled);
                //     //for alpha launch, just shuffling zipcodes within the three zones. probably not worth time to try to optimize order since they'll quickly run out of people in the nearest zipcode anyways
                // }
            } else if (zone2.includes(zipcode)) {
                // let zipcodesShuffled = zone2;
                // for (var i = zipcodesShuffled.length - 1; i > 0; i--) {
                //     var j = Math.floor(Math.random() * (i + 1));
                //     var temp = zipcodesShuffled[i];
                //     zipcodesShuffled[i] = zipcodesShuffled[j];
                //     zipcodesShuffled[j] = temp;
                //     setZipcodes(zipcodesShuffled);
                // }
            } else if (zone3.includes(zipcode)) {
                // let zipcodesShuffled = zone3;
                // for (var i = zipcodesShuffled.length - 1; i > 0; i--) {
                //     var j = Math.floor(Math.random() * (i + 1));
                //     var temp = zipcodesShuffled[i];
                //     zipcodesShuffled[i] = zipcodesShuffled[j];
                //     zipcodesShuffled[j] = temp;
                //     setZipcodes(zipcodesShuffled);
                // }
            } else {
                setModalOutOfArea(true)
            }
        }
    }

    function goToWebsite() {
        Linking.openURL('https://thrivedatingapp.com')
    }
    async function confirmation() {
        // console.log(zipcodes);
        if (age == '') {
            setModalAlert(true)
            setAlertModalText('Please enter your age to proceed')
        } else {
            if (age < 18) {
                setModalAlert(true)
                setAlertModalText('Please wait until you are 18 to create an account')
                const auth = getAuth()
                const uid = auth.currentUser.uid
                await updateDoc(doc(db, 'users', uid), { redFlag: true }) //flags their account for additional scrutiny during authentication. could even ask these users for two forms of age-stamped ID.
            } else {
                let zipcode = Number(zipcode1)
                if (allZones.includes(zipcode)) {
                    //converting numbers back to strings
                    // let temp2 = zipcodes;
                    // temp2.map(String);
                    // setZipcodes(temp2);
                    setModalVisible(true)
                } else {
                    setModalOutOfArea(true)
                }

                // // console.log("gender", gender);
                // // console.log("sex", sexOrientation);
                // // console.log("age", age);
                // let zipcodesRough = [
                //     zipcode1,
                //     zipcode2,
                //     zipcode3,
                //     zipcode4,
                //     zipcode5,
                //     zipcode6,
                //     zipcode7,
                // ];
                // let zipcodesFinal = [];
                // const arrayLength = zipcodesRough.length;
                // for (var i = 0; i < arrayLength; i++) {
                //     if (zipcodesRough[i].length == 5) {
                //         if (zipcodesFinal.includes(zipcodesRough[i]) == false)
                //             zipcodesFinal.push(zipcodesRough[i]);
                //     } else if (zipcodesRough[i].length == 0) {
                //     } else {
                //         window.alert("Zipcodes must be 5 digits");
                //         zipcodesFinal = [];
                //         break;
                //     }
                // }
                // //made keyboard not cover textinput at bottom. DONE. Use scrollview wrapped around whole thing. DOESN'T FIX ON IOS
                // if (zipcodesFinal.length != 0) {
                //     setZipcodes(zipcodesFinal);
                //     setModalVisible(true);
                // } else {
                // }
            }
        }
    }

    async function uploadAndProceed() {
        setModalVisible(false)
        const genderSex = gender + sexOrientation

        const auth = getAuth()
        const uid = auth.currentUser.uid

        await updateDoc(doc(db, 'users', uid), {
            age: age,
            max: max,
            min: min,
            genderSex: genderSex,
            zipcode: zipcode1,
            distPref: '100', //hardcoded for now, let them pick later
        })
        await setDoc(doc(db, 'users', uid, 'photos', 'nameAgeHeight'), {
            age: age,
        })
        logEvent(analytics, 'finished_age_zipcode_screen')
        navigation.navigate('PictureOnboarding')
    }

    return (
        <SafeAreaView
            style={{
                backgroundColor: COLORS.white,
                flex: 1,
                justifyContent: 'center',
            }}
        >
            {/* <View
                style={{
                    height: Platform.OS === "android" ? 24 : 0,
                    backgroundColor: "grey",
                }}

                </TouchableWithoutFeedback>

            /> */}
            {/* <TouchableWithoutFeedback onPress={killKeyboard}> */}
            {/* <ScrollView> */}
            <View style={styles.container}>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={() => {
                        setModalVisible(!modalVisible)
                    }}
                >
                    <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                        <View
                            style={{
                                backgroundColor: COLORS.lightGreen,
                                paddingVertical: 30 * VS,
                                paddingHorizontal: 10 * HS,
                                margin: 20,
                                borderRadius: 10,
                                alignItems: 'center',
                            }}
                        >
                            <AlphaText
                                style={{
                                    color: COLORS.nearBlack,
                                    fontSize: 30,
                                    textAlign: 'center',
                                }}
                            >
                                Is this correct?
                            </AlphaText>

                            <AlphaText
                                style={{
                                    fontSize: 16,
                                    color: COLORS.nearBlack,
                                    padding: 20,
                                    textAlign: 'center',
                                }}
                            >
                                {textConfirmation}
                            </AlphaText>
                            <AlphaText
                                style={{
                                    fontSize: 12,
                                    color: COLORS.nearBlack,
                                    padding: 20,
                                    textAlign: 'center',
                                }}
                            >
                                {`By continuing, you signify that you agree to our Terms and Conditions, which, along with our Privacy Policy, can be viewed in the settings menu or on our website.`}
                            </AlphaText>
                            <View
                                style={{
                                    flexDirection: 'row',
                                }}
                            >
                                <Pressable
                                    onPress={() => setModalVisible(!modalVisible)}
                                    style={({ pressed }) => [
                                        {
                                            borderRadius: 5,
                                            padding: 20,
                                            marginHorizontal: 15,
                                            marginVertical: 10,
                                            opacity: pressed ? 0.5 : 1,
                                            backgroundColor: COLORS.darkGray,
                                        },
                                    ]}
                                >
                                    <AlphaText
                                        style={{
                                            fontSize: 14,
                                            color: COLORS.white,
                                            textAlign: 'center',
                                        }}
                                    >
                                        No
                                    </AlphaText>
                                </Pressable>

                                <Pressable
                                    onPress={() => uploadAndProceed()}
                                    style={({ pressed }) => [
                                        {
                                            borderRadius: 5,
                                            padding: 20,
                                            marginHorizontal: 15,
                                            marginVertical: 10,
                                            opacity: pressed ? 0.5 : 1,
                                            backgroundColor: COLORS.redButton,
                                        },
                                    ]}
                                >
                                    <AlphaText
                                        style={{
                                            fontSize: 14,
                                            color: COLORS.white,
                                            textAlign: 'center',
                                        }}
                                    >
                                        Yes
                                    </AlphaText>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalOutOfArea}
                    onRequestClose={() => {
                        setModalOutOfArea(!modalOutOfArea)
                    }}
                >
                    <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                        <View
                            style={{
                                backgroundColor: COLORS.white,
                                padding: 10,
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                flex: 1,
                                marginHorizontal: 10,
                                marginTop: 100,
                                marginBottom: 100,
                                borderRadius: 10,
                            }}
                        >
                            <AlphaText
                                style={{
                                    color: COLORS.nearBlack,
                                    fontSize: 30,
                                    textAlign: 'center',
                                }}
                            >
                                ZIP Not Supported Yet
                            </AlphaText>

                            <AlphaText
                                style={{
                                    fontSize: 16,
                                    color: COLORS.nearBlack,
                                    padding: 20,
                                    textAlign: 'center',
                                }}
                            >
                                Sorry, but we do not yet support your area. If you created an account there would be no
                                one nearby for you to match with. Please press below to access our website so you can
                                join our waitlist. Then we can notify you when we finish beta testing and expand
                                geographically.
                            </AlphaText>
                            <Pressable
                                onPress={goToWebsite}
                                style={({ pressed }) => [
                                    {
                                        borderRadius: 5,
                                        padding: 10,
                                        marginHorizontal: 15,
                                        marginVertical: 10,
                                        opacity: pressed ? 0.5 : 1,
                                        backgroundColor: COLORS.darkGray,
                                    },
                                ]}
                            >
                                <AlphaText
                                    style={{
                                        fontSize: 14,
                                        color: COLORS.white,
                                        textAlign: 'center',
                                    }}
                                >
                                    Join Waitlist
                                </AlphaText>
                            </Pressable>
                            <View
                                style={{
                                    flexDirection: 'row',
                                }}
                            >
                                <Pressable
                                    onPress={() => setModalOutOfArea(!modalOutOfArea)}
                                    style={({ pressed }) => [
                                        {
                                            borderRadius: 5,
                                            padding: 10,
                                            marginHorizontal: 15,
                                            marginVertical: 10,
                                            opacity: pressed ? 0.5 : 1,
                                            backgroundColor: COLORS.redButton,
                                        },
                                    ]}
                                >
                                    <AlphaText
                                        style={{
                                            fontSize: 14,
                                            color: COLORS.white,
                                            textAlign: 'center',
                                        }}
                                    >
                                        Close
                                    </AlphaText>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalAlert}
                    onRequestClose={() => {
                        setModalAlert(!modalAlert)
                    }}
                >
                    <View
                        style={{
                            backgroundColor: COLORS.white,
                            padding: 20,
                            // alignItems: "center",
                            justifyContent: 'center',
                            flexDirection: 'column',
                            flex: 1,
                            marginHorizontal: 30,
                            marginTop: 50,
                            marginBottom: 50,
                            borderRadius: 10,
                        }}
                    >
                        <AlphaText
                            style={{
                                color: COLORS.nearBlack,
                                fontSize: 30,
                                textAlign: 'center',
                                paddingBottom: 15,
                            }}
                        >
                            {alertModalText}
                        </AlphaText>

                        <View
                            style={{
                                flexDirection: 'row',
                            }}
                        >
                            <View style={{ flex: 0.5 }}></View>

                            <Pressable
                                onPress={() => setModalAlert(!modalAlert)}
                                style={({ pressed }) => [
                                    {
                                        flex: 6,
                                        borderRadius: 5,
                                        padding: 10,
                                        justifyContent: 'center',
                                        marginTop: 25,
                                        opacity: pressed ? 0.5 : 1,
                                        backgroundColor: COLORS.redButton,
                                    },
                                ]}
                            >
                                <AlphaText
                                    style={{
                                        fontSize: 14,
                                        color: COLORS.white,
                                        textAlign: 'center',
                                    }}
                                >
                                    Back
                                </AlphaText>
                            </Pressable>

                            <View style={{ flex: 0.5 }}></View>
                        </View>
                    </View>
                </Modal>
                <View
                    style={{
                        flexDirection: 'row',
                        // marginTop: 20 * VS,
                        marginHorizontal: 7 * HS,
                    }}
                >
                    <AlphaText
                        style={{
                            paddingRight: 12,
                            fontSize: 25,
                            color: COLORS.nearBlack,
                            marginVertical: 20,
                        }}
                    >
                        I am
                    </AlphaText>
                    <TextInput
                        style={{
                            color: COLORS.nearBlack,
                            borderWidth: 1,
                            marginTop: 17, //no HS or VS
                            height: 51 * HS,
                            fontFamily: 'Medium',
                            width: 42 * HS,
                            fontSize: 25 * ((VS + HS) / 2) * 0.85, //if you update the calculations on this line, need to update alphatext and other textinputs
                            backgroundColor: COLORS.white,
                            paddingHorizontal: 5,
                            borderRadius: 3,
                            flexDirection: 'row',
                        }}
                        keyboardType="number-pad"
                        placeholder="00"
                        placeholderTextColor={COLORS.darkGray}
                        maxLength={2}
                        onChangeText={(age) => {
                            setAge(age)
                            setTextConfirmation(`You are ${age} years old and want to date near ZIP code ${zipcode1}.`)
                        }}
                        value={age}
                    ></TextInput>
                    <AlphaText
                        style={{
                            paddingLeft: 12,
                            fontSize: 25,
                            color: COLORS.nearBlack,
                            marginVertical: 20,
                        }}
                    >
                        years old.
                    </AlphaText>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <AlphaText
                        style={{
                            fontSize: 13,
                            color: COLORS.nearBlack,
                            marginVertical: 5,
                            fontStyle: 'italic',
                        }}
                    >
                        {'"Lies make baby Jesus cry." '}
                    </AlphaText>
                    <AlphaText
                        style={{
                            fontSize: 13,
                            color: COLORS.nearBlack,
                            marginVertical: 5,
                        }}
                    >
                        {' -Rod Flanders'}
                    </AlphaText>
                </View>
                <AlphaText
                    style={{
                        fontSize: 13,
                        color: COLORS.nearBlack,
                        marginVertical: 5,
                    }}
                >
                    {'Please be honest about your age.'}
                </AlphaText>
                {/* <AlphaText
                    style={{
                        fontSize: 22,
                        color: COLORS.darkBlue,
                        marginTop: 25 * VS * VS,
                        marginBottom: 10,
                    }}
                >
                    I want to date in ZIP codes:
                </AlphaText>
                <AlphaText
                    style={{
                        fontSize: 15,
                        color: COLORS.nearBlack,
                        marginTop: 15,
                        marginHorizontal: 35 * HS,
                        textAlign: "center",
                    }}
                >
                    {"Currently supporting select ZIP codes in the Milwaukee area!"}
                </AlphaText> */}
                <View style={{ flexDirection: 'row' }}>
                    {/* <Image
                        style={{
                            width: 200 * HS,
                            height: 350 * VS,
                            resizeMode: "center",
                        }}
                        source={require("./../../assets/notButtons/TemporaryMap.png")}
                    ></Image> */}
                    <View style={{ flexDirection: 'row' }}>
                        <AlphaText
                            style={{
                                fontSize: 25,
                                color: COLORS.nearBlack,
                                marginTop: 10 * VS,
                                marginBottom: 10,
                            }}
                        >
                            {'My ZIP code: '}
                        </AlphaText>
                        <TextInput
                            style={{
                                // placeholderTextColor: COLORS.nearBlack,
                                color: COLORS.nearBlack,
                                borderWidth: 1,
                                marginTop: 10,
                                marginHorizontal: 5,
                                height: 40 * HS,
                                width: 90 * HS,
                                fontSize: 25 * ((VS + HS) / 2) * 0.85, //if you update the calculations on this line, need to update alphatext and other textinputs
                                fontFamily: 'Medium',
                                backgroundColor: COLORS.white,
                                paddingHorizontal: 5,
                                borderRadius: 3,
                                flexDirection: 'row',
                            }}
                            keyboardType="number-pad"
                            placeholder="00000"
                            placeholderTextColor={COLORS.darkGray}
                            maxLength={5}
                            textContentType="postalCode"
                            onChangeText={(zipcode1) => {
                                updateZipcode1(zipcode1)
                                setTextConfirmation(
                                    `You are ${age} years old and want to date near ZIP code ${zipcode1}.`
                                )
                            }}
                            value={zipcode1}
                        ></TextInput>
                    </View>
                </View>
                <Pressable
                    onPress={() => confirmation()}
                    style={({ pressed }) => [
                        {
                            color: '#2196f3',
                            borderRadius: 5,
                            padding: 15,
                            marginVertical: 35 * VS * VS,
                            backgroundColor: COLORS.redButton,
                            opacity: pressed ? 0.5 : 1,
                        },
                    ]}
                >
                    <AlphaText
                        style={{
                            fontSize: 18,
                            color: COLORS.white,
                            textAlign: 'center',
                        }}
                    >
                        Confirm Selections
                    </AlphaText>
                </Pressable>
                <AlphaText
                    style={{
                        fontSize: 14,
                        color: COLORS.nearBlack,
                        marginTop: 15,
                        marginHorizontal: 35 * HS,
                        textAlign: 'center',
                    }}
                >
                    {
                        "We'll automatically match you with people in the Milwaukee area. In the future, we'll give you control over the distance of potential matches you are shown."
                    }

                    {/* {
                        "We'll automatically generate a list of ZIP codes nearby for you to find dates in. In the future, we plan to give you control over the distance of potential matches you are shown."
                    } */}
                </AlphaText>
                {/* <AlphaText
                    style={{
                        fontSize: 16,
                        color: COLORS.white,
                        marginVertical: 5,
                        marginHorizontal: 20,
                        textAlign: "center",
                    }}
                >
                    {
                        "We recommend listing as many ZIP codes as you are willing to date in, starting with your favorite."
                    }
                </AlphaText>
               
                
                <AlphaText
                    style={{
                        fontSize: 14 ,
                        color: COLORS.white,
                        marginTop: 15,
                        marginHorizontal: 20,
                        textAlign: "center",
                    }}
                >
                    {
                        "P.S. Our apologies for making you look up ZIP codes. It's something we hope to improve in the future."
                    }
                </AlphaText> */}
            </View>
            {/* </ScrollView> */}
            {/* </TouchableWithoutFeedback> */}
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.white,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },
})
