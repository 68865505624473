import React, { useEffect, useState, useCallback } from 'react'
import { Dimensions, Modal, Pressable, Text, View } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useFocusEffect } from '@react-navigation/native'
import HomeMainButton from '../ComponentsHome/HomeMainButton'
import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
import ButtonSecondary from '../ComponentsShared/ButtonSecondary'
import AlphaText from '../ComponentsShared/TextAlpha'
import VoteIcon from 'assets/icons/VoteIcon.png'
import ChatIcon from 'assets/icons/ChatIcon.png'
import ProfileIcon from 'assets/icons/ProfileIcon.png'
import SettingsIcon from 'assets/icons/SettingsIcon.png'
import { db, messaging, functions, auth } from './../../FirebaseInitialization'
import { getDoc, getDocs, where, doc, collection, query, getCountFromServer } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'

import ModalAlert from '../ComponentsShared/ModalAlert'

import { COLORS } from '../../Colors'

const { width, height } = Dimensions.get('window')
let HS = width / 320
let VS = height / 640

function HomeScreen({ navigation, route }) {
    const [authorized, setAuthorized] = useState(0) //code: 0 is default (connection to server not established), code: 1 is all checks were valid, code: 2 means check failed and need to update
    const [activated, setActivated] = useState(false)
    const [modalPending, setModalPending] = useState(false)
    const [modalAlert, setModalAlert] = useState(false)
    const [alertModalText, setAlertModalText] = useState('error')
    const [modalUpdater, setModalUpdater] = useState(false)
    const [updateDetails, setUpdateDetails] = useState(false)

    const [newMessages, setNewMessages] = useState(0)

    const checkForCheckpoints = async () => {
        try {
            const checkpoint = await AsyncStorage.getItem('creationCheckpoint')
            // if (activated != true) {
            //     setModalPending(true); //this keeps displaying during account creation booo! tired of messing with it for now
            // }
            if (checkpoint == 'verifyEmail') {
                navigation.navigate('VerifyEmail')
            } else if (checkpoint == 'pictureOnboarding') {
                navigation.navigate('PictureOnboarding')
            } else if (checkpoint == 'uploadFace') {
                navigation.navigate('UploadFace')
            } else if (checkpoint == 'uploadBody') {
                navigation.navigate('UploadBody')
            } else if (checkpoint == 'uploadLife') {
                navigation.navigate('UploadLife')
            } else if (checkpoint == 'answerQuestions') {
                navigation.navigate('AnswerQuestions')
            }
        } catch (err) {
            console.log(err)
        }
    }
    const reloadApp = async () => {
        window.location.reload()
    }
    async function checkForForcedUpdate() {
        if (authorized == 0) {
            try {
                // wtf is this logic? three booleans, why not just check for a specific version number in a string? Is this something users can intercept and manipulate to pass the check?
                const checks = await getDoc(doc(db, 'updateChecker', 'checker'))
                const checkObj = checks.data()
                const checkArr = checkObj.check
                const check1 = checkArr[0]
                const check2 = checkArr[1]
                const check3 = checkArr[2]
                if (check1 == true && check2 == true && check3 == true) {
                    setAuthorized(1)
                } else {
                    setAuthorized(2)
                }
            } catch (err) {}
        }
    }
    async function checkUnreadMsgCount() {
        try {
            const auth = getAuth()
            const uid = auth.currentUser.uid
            const chatsListData = await getDoc(doc(db, 'users', uid, 'chats', 'chats'))
            const chatsList = chatsListData.data().chatsList

            console.log(chatsList)
            if (typeof chatsList.length !== 'undefined') {
                let totalUnreadMsgCount = 0
                console.log('length', chatsList.length)
                for (let i = 0; i < chatsList.length; i++) {
                    let chatName = '...'
                    let matchsUID = chatsList[i].uid
                    if (uid > matchsUID) {
                        chatName = uid + '_' + matchsUID
                    } else {
                        chatName = matchsUID + '_' + uid
                    }
                    const chatData = await getDoc(doc(db, 'chats', chatName))
                    const userLastSeen = chatData.data().userLastSeen[uid]
                    console.log(userLastSeen)
                    const q = query(
                        collection(db, 'chats', chatName, 'messages'),
                        where('createdAt', '>', userLastSeen)
                    )
                    const snapshot = await getCountFromServer(q)
                    totalUnreadMsgCount += snapshot.data().count
                    console.log("This didn't throw an error")
                }
                setNewMessages(totalUnreadMsgCount)
            }
        } catch (err) {
            console.log(err)
        }
    }

    function checkActivation() {
        let timeoutId = null //THIS WAS UPDATED ON 6/4 TO ADD CLEARTIMEOUT BUT IS UNTESTED
        // useEffect(() => {
        const fetchData = async () => {
            const auth = getAuth()
            const uid = auth.currentUser.uid

            const profile = await getDoc(doc(db, 'users', uid))
            const data = profile.data()
            let isActivated = false
            try {
                isActivated = data.activated
            } catch (err) {}
            if (isActivated != true) {
                setActivated(false)
                //setModalPending(true); //this was getting called during sign-up. moving to didFocus may fix
            } else if (isActivated == true) {
                setActivated(true)
            }
        }
        timeoutId = setTimeout(fetchData, 500)
        // NEED TO CLEAR THIS TIMER SOMEHOW

        // return () => {
        //     clearTimeout(timeoutId)
        // }
        // }, [])
    }

    // this is designed to call initially and when user navigates back to this screen
    useFocusEffect(
        useCallback(() => {
            const auth = getAuth()

            onAuthStateChanged(auth, (user) => {
                if (user) {
                    console.log('USER: ', user.uid)
                    checkActivation() // some of these calls could probably be combined or only run when they try to vote
                    checkForForcedUpdate()
                    checkForCheckpoints()
                    checkUnreadMsgCount()
                } else {
                    navigation.navigate('DoHaveAccount') // if not logged in, leave TEMPORARILY DISABLED MAYBE
                }
            })
        }, [])
    )

    const handleAlertUnactivated = () => {
        setModalAlert(true)
        setAlertModalText('Please await account activation email')
    }
    const handleAlertConnection = () => {
        setModalAlert(true)
        setAlertModalText('Could not connect. Please check your connection and try again.')
    }
    const handleAlertNeedUpdate = () => {
        // setModalAlert(true)
        // setAlertModalText('Please update your app version')
        setModalUpdater(true)
    }

    function handleOne() {
        // RE-ENABLE ONCE DONE TESTING
        if (true) {
            // if (authorized == 1 && activated == true) {
            navigation.navigate('AnswersVoting')
        } else if (authorized == 1 && activated == false) {
            handleAlertUnactivated()
        } else if (authorized == 0) {
            handleAlertConnection()
        } else if (authorized == 2) {
            handleAlertNeedUpdate()
        }
    }
    function handleTwo() {
        // if (authorized == 1 && activated == true) {
        // RE-ENABLE ONCE DONE TESTING
        if (true) {
            navigation.navigate('MessagingMenu')
        } else if (authorized == 1 && activated == false) {
            handleAlertUnactivated()
        } else if (authorized == 0) {
            handleAlertConnection()
        } else if (authorized == 2) {
            handleAlertNeedUpdate()
        }
    }
    function handleThree() {
        // if (authorized == 1) {
        // RE-ENABLE ONCE DONE TESTING
        if (true) {
            navigation.navigate('ProfileEditingMenu')
        } else if (authorized == 0) {
            handleAlertConnection()
        } else if (authorized == 2) {
            handleAlertNeedUpdate()
        }
    }
    function handleFour() {
        navigation.navigate('Settings')
    }

    return (
        <View style={{ display: 'flex', flex: 1, backgroundColor: COLORS.lightGreen }}>
            <Modal
                animationType="fade"
                transparent={true}
                visible={modalUpdater}
                onRequestClose={() => {
                    setModalUpdater(!modalUpdater)
                }}
            >
                <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                    <View
                        style={{
                            backgroundColor: COLORS.lightGreen,
                            padding: 20,
                            justifyContent: 'center',
                            flex: 1,
                            margin: 20,
                            borderRadius: 10,
                        }}
                    >
                        <AlphaText
                            style={{
                                color: COLORS.nearBlack,
                                fontSize: 30,
                                textAlign: 'center',
                            }}
                        >
                            Please Update
                        </AlphaText>

                        <AlphaText
                            style={{
                                fontSize: 20,
                                color: COLORS.nearBlack,
                                padding: 10,
                                paddingBottom: 10,
                                textAlign: 'center',
                            }}
                        >
                            Press the update button to update and reload the app.
                        </AlphaText>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <ButtonSecondary onPress={() => setModalUpdater(false)} buttonText={'Back'} />
                            <ButtonPrimary onPress={reloadApp} buttonText={'Update App'} />
                        </View>
                        {!updateDetails && (
                            <Pressable onPress={() => setUpdateDetails(!updateDetails)}>
                                {({ pressed }) => (
                                    <AlphaText
                                        style={{
                                            fontSize: 14,
                                            color: COLORS.nearBlack,
                                            textDecorationLine: 'underline',
                                            padding: 20,
                                            marginTop: 20,
                                            textAlign: 'center',
                                            opacity: pressed ? 0.5 : 1,
                                        }}
                                    >
                                        I pressed the update button above and am still getting stopped by this message
                                    </AlphaText>
                                )}
                            </Pressable>
                        )}
                        {updateDetails && (
                            <AlphaText
                                style={{
                                    fontSize: 14,
                                    color: COLORS.nearBlack,
                                    padding: 5,
                                    marginTop: 15,
                                }}
                            >
                                {`Please try the following:\n\nOpen your web browser and close any tabs related to Thrive. Then completely close (don't just minimize) and reopen the Thrive app.\n\nOn Android we primarily design for Chrome due to its popularity, so if you're using a different browser, you could uninstall Thrive and try installing from Chrome (this is not currently possible on iOS).`}
                            </AlphaText>
                        )}
                    </View>
                </View>
            </Modal>
            <Modal
                animationType="fade"
                transparent={true}
                visible={modalPending}
                onRequestClose={() => {
                    setModalPending(!modalPending)
                }}
            >
                <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                    <View
                        style={{
                            backgroundColor: COLORS.lightGreen,
                            padding: 20,
                            justifyContent: 'center',
                            flex: 1,
                            margin: 20,
                            borderRadius: 10,
                        }}
                    >
                        <AlphaText
                            style={{
                                color: COLORS.nearBlack,
                                fontSize: 30,
                                textAlign: 'center',
                            }}
                        >
                            Account Pending Review
                        </AlphaText>

                        <AlphaText
                            style={{
                                fontSize: 16,
                                color: COLORS.nearBlack,
                                padding: 20,
                                textAlign: 'center',
                            }}
                        >
                            You will not be able to interact with other users until we have a chance to review your
                            account. We'll email you when your account has been approved. Wait times will likely be
                            longer than normal during the weeks following the app's launch. We apologize for the
                            inconvenience.
                        </AlphaText>
                        <View style={{ alignItems: 'center' }}>
                            <ButtonPrimary onPress={() => setModalPending(false)} buttonText={'OK'} />
                        </View>
                    </View>
                </View>
            </Modal>
            <Modal
                animationType="fade"
                transparent={true}
                visible={modalAlert}
                onRequestClose={() => {
                    setModalAlert(!modalAlert)
                }}
            >
                <ModalAlert setModalAlert={setModalAlert} alertModalText={alertModalText} />
            </Modal>
            <View
                style={{
                    flex: 3,
                    flexDirection: 'column',
                    backgroundColor: COLORS.lightGreen,
                    height: '100%',
                    justifyContent: 'space-around',
                    marginVertical: 30,
                }}
            >
                <HomeMainButton title="Vote" image={VoteIcon} press={handleOne} />
                <View style={{ position: 'relative' }}>
                    <HomeMainButton title="Chat" image={ChatIcon} press={handleTwo} />
                    {newMessages !== 0 && (
                        <View
                            pointerEvents={'none'}
                            style={{
                                position: 'absolute',
                                right: 12,
                                top: -10,
                                width: 26 * HS,
                                height: 26 * HS,
                                backgroundColor: COLORS.redButton,
                                borderRadius: 13 * HS,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <AlphaText
                                style={{
                                    color: COLORS.white,
                                    fontSize: 12,
                                }}
                            >
                                {newMessages}
                            </AlphaText>
                        </View>
                    )}
                </View>
                <HomeMainButton title="Profile" image={ProfileIcon} press={handleThree} />
                <HomeMainButton title="Settings" image={SettingsIcon} press={handleFour} />
                <View style={{ height: 20 * VS }} />
            </View>
        </View>
    )
}

export default HomeScreen
