// const rawAnswers = {
//     religion1: '',
//     religion2: '',
//     religion3: '',
//     astrology: '',
//     career1: '',
//     career2: '',
//     career3: '',
//     political1: '',
//     political2: '',
//     political3: '',
//     food1: '',
//     food2: '',
//     fun1: '',
//     fun2: '',
//     fun3: '',
//     dealbreaker: '',
//     psych1: '',
//     psych2: '',
//     psych3: '',
//     psych4: '',
//     drugs1: '',
//     drugs2: '',
//     drugs3: '',
//     relationship1: '',
//     relationship2: '',
//     relationship3: '',
//     marriage1: '',
//     marriage2: '',
//     sex1: '',
//     sex2: '',
//     sex3: '',
//     kids1: '',
//     kids2: '',
//     kids3: '',
//     kids4: '',
//     forFun1: { question: "My favorite dog is...", answers: "Clifford"}
// }

// let answersFormatted = {
//     Dealbreaker: 'This is my dealbreaker',
//     Marriage: 'I want to get married to ONE people in ten years time',
//     Kids: 'blurb about kids goes here. And it goes on to take up several more lines, because...',
//     Relationship: 'blurb about relationship goes here',
//     Religion: 'blurb about religion goes here',
//     Career: 'blurb about career goes here',
//     Astrology: 'blurb about astrology goes here',
//     Politics: 'blurb about politics goes here',
//     Sex: 'blurb about sex goes here',
//     Alcohol: 'blurb about drugs goes here',
//     Food: 'blurb about food goes here',
//     Psychology: 'blurb about psychology goes here',
//     Fun: 'For fun I go water skiing.\nKevin Hart is my favorite comedian.\nFriends describe me as too sexy for my shirt 🏄.',
//     ForFun1: { question: "My favorite dog is...", answer: "Clifford"}
//     ForFun2: { question: "My worst first date was...", answer: "Projectile vomiting on the president"}
// }

// Maybe SHOULD HAVE VALIDATION FUNCTION FOR ANSWERQUESTIONS AND EDITOR?
const answerValidator = () => {
    // MAYBE JUST IGNORE THE CHARACTER LIMIT FOR NOW
    // MAYBE JUST IGNORE THE CHARACTER LIMIT FOR NOW
    // DEALBREAKER MUST HAVE AT LEAST 8 ALPHABETICAL CHARACTERS
    // 10 OTHER QUESTIONS MUST BE ANSWERED
    // A TYPED QUESTION IS CONSIDERED ANSWERED IF IT HAS AT LEAST 8 ALPHABETICAL CHARACTERS
    // A DROPDOWN QUESTION IS ANSWERED IF ITS NOT '' DUH
    // MAYBE JUST IGNORE THE CHARACTER LIMIT FOR NOW
    // MAYBE JUST IGNORE THE CHARACTER LIMIT FOR NOW
}

// see above examples to understand structure of I/O - convert to TS later on
export const answerProcessor = (rawAnswers) => {
    let answersFormatted = {}

    const containsNonSpaceCharacters = (str) => {
        return /\S/.test(str)
    }

    const formatDealbreaker = () => {
        answersFormatted.Dealbreaker = rawAnswers.dealbreaker
        //don't check if it contains nonspacecharacters because this question is mandatory
    }

    const formatForFun = () => {
        answersFormatted.ForFun1 = rawAnswers.forFun1
        answersFormatted.ForFun2 = rawAnswers.forFun2
        answersFormatted.ForFun3 = rawAnswers.forFun3
    }

    const formatFun = () => {
        let first = ''
        let second = ''
        let third = ''
        if (containsNonSpaceCharacters(rawAnswers.fun1)) {
            first = `For fun I ${rawAnswers.fun1}${rawAnswers.fun1.endsWith('.') ? '' : '.'} `
        }
        if (containsNonSpaceCharacters(rawAnswers.fun2)) {
            second = `I always laugh at ${rawAnswers.fun2}${rawAnswers.fun2.endsWith('.') ? '' : '.'} `
        }
        if (containsNonSpaceCharacters(rawAnswers.fun3)) {
            third = `Friends might describe me as ${rawAnswers.fun3}.`
        }
        answersFormatted.Fun = first + second + third
    }

    const formatPsychology = () => {
        let first = ''
        let second = ''
        let third = ''
        let fourth = ''
        if (containsNonSpaceCharacters(rawAnswers.psych1)) {
            first = `Familial influence: ${rawAnswers.psych1}. `
        }
        if (containsNonSpaceCharacters(rawAnswers.psych2)) {
            second = `Therapy done: ${rawAnswers.psych2}. `
        }
        if (containsNonSpaceCharacters(rawAnswers.psych3)) {
            third = `A philosophy I find compelling is ${rawAnswers.psych3}${
                rawAnswers.psych3.endsWith('.') ? '' : '.'
            } `
        }
        if (containsNonSpaceCharacters(rawAnswers.psych4)) {
            fourth = `Diagnoses: ${rawAnswers.psych4}${rawAnswers.psych4.endsWith('.') ? '' : '.'}`
        }
        answersFormatted.Psychology = first + second + third + fourth
    }

    const formatFood = () => {
        let first = ''
        let second = ''
        if (containsNonSpaceCharacters(rawAnswers.food1)) {
            first = `I eat ${rawAnswers.food1}${rawAnswers.food1.endsWith('.') ? '' : '.'} `
        }
        if (containsNonSpaceCharacters(rawAnswers.food2)) {
            second = `Cooking: ${rawAnswers.food2}${rawAnswers.food2.endsWith('.') ? '' : '.'}`
        }
        answersFormatted.Food = first + second
    }

    const formatAlcohol = () => {
        let first = ''
        let second = ''
        let third = ''
        if (containsNonSpaceCharacters(rawAnswers.drugs1)) {
            if (rawAnswers.drugs1 == 'never') {
                first = 'I never use tobacco products. '
            } else {
                first = `I use tobacco products ${rawAnswers.drugs1}. `
            }
        }
        if (containsNonSpaceCharacters(rawAnswers.drugs2)) {
            second = `Alcohol per week: ${rawAnswers.drugs2}${rawAnswers.drugs2.endsWith('.') ? '' : '.'} `
        }
        if (containsNonSpaceCharacters(rawAnswers.drugs3)) {
            third = `Drugs: ${rawAnswers.drugs3}${rawAnswers.drugs3.endsWith('.') ? '' : '.'}`
        }
        answersFormatted.Alcohol = first + second + third
    }

    const formatSex = () => {
        let first = ''
        let second = ''
        let third = ''
        if (containsNonSpaceCharacters(rawAnswers.sex1)) {
            first = `Q: Premarital sex? A: ${rawAnswers.sex1}. `
        }
        if (containsNonSpaceCharacters(rawAnswers.sex2)) {
            second = `In a perfect world, I'd have sex ${rawAnswers.sex2} times in an average week. `
        }
        if (containsNonSpaceCharacters(rawAnswers.sex3)) {
            third = `Kinks: ${rawAnswers.sex3}${rawAnswers.sex3.endsWith('.') ? '' : '.'}`
        }

        answersFormatted.Sex = first + second + third
    }

    const formatPolitics = () => {
        let first = ''
        let second = ''
        let third = ''
        if (containsNonSpaceCharacters(rawAnswers.political1)) {
            first = `${rawAnswers.political1}. `
        }
        if (containsNonSpaceCharacters(rawAnswers.political2)) {
            second = `${rawAnswers.political2}. `
        }
        if (containsNonSpaceCharacters(rawAnswers.political3)) {
            if (rawAnswers.political3 == 'Their political views should be similar to mine') {
                third = "I want my partner's political views to be similar to mine."
            } else if (rawAnswers.political3 == 'Our political views need some common ground') {
                third = 'Our political views need some common ground.'
            } else if (rawAnswers.political3 == "I'm not picky as long as they tolerate my views") {
                third = "I'm not picky about my partner's political views as long as they tolerate mine."
            } else if (rawAnswers.political3 == "I'm willing to adapt my political views to theirs") {
                third = "I'm willing to adapt my political views to my partner's."
            } else {
                third = rawAnswers.political3
            }
        }
        answersFormatted.Politics = first + second + third
    }

    const formatAstrology = () => {
        if (containsNonSpaceCharacters(rawAnswers.astrology)) {
            answersFormatted.Astrology = rawAnswers.astrology
        } else {
            answersFormatted.Astrology = ''
        }
    }

    const formatCareer = () => {
        let first = ''
        let second = ''
        let third = ''
        if (containsNonSpaceCharacters(rawAnswers.career1)) {
            if (rawAnswers.career1 == 'None yet') {
                first = "I don't have any degrees yet. "
            } else {
                first = `I have a ${rawAnswers.career1}. `
            }
        }
        if (containsNonSpaceCharacters(rawAnswers.career2)) {
            second = `My current job/school is ${rawAnswers.career2}${rawAnswers.career2.endsWith('.') ? '' : '.'} `
        }
        if (containsNonSpaceCharacters(rawAnswers.career3)) {
            third = `My dream job is ${rawAnswers.career3}${rawAnswers.career3.endsWith('.') ? '' : '.'}`
        }
        answersFormatted.Career = first + second + third
    }

    const formatReligion = () => {
        let first = ''
        let second = ''
        let third = ''
        if (containsNonSpaceCharacters(rawAnswers.religion1)) {
            first = rawAnswers.religion1
        }
        if (containsNonSpaceCharacters(rawAnswers.religion2)) {
            if (rawAnswers.religion2 == 'nonexistent') {
                second = '. '
            } else {
                second = `. My religious beliefs are ${rawAnswers.religion2}. `
            }
        }
        if (containsNonSpaceCharacters(rawAnswers.religion3)) {
            third = `My partner's religion ${rawAnswers.religion3}.`
        }
        answersFormatted.Religion = first + second + third
    }

    const formatRelationship = () => {
        let first = ''
        let second = ''
        let third = ''
        if (containsNonSpaceCharacters(rawAnswers.relationship1)) {
            if (rawAnswers.relationship1 == 'I want partner to support me financially') {
                first = 'I want my partner to support me financially.'
            } else if (rawAnswers.relationship1 == 'I want partner who can support self financially') {
                first = 'I want a partner who can support themself financially.'
            } else if (rawAnswers.relationship1 == "I don't mind supporting my partner financially") {
                first = rawAnswers.relationship1
            } else {
                first = rawAnswers.relationship1
            }
        }
        if (containsNonSpaceCharacters(rawAnswers.relationship2)) {
            second = ` Q: Believe in predestined soulmates? A: ${rawAnswers.relationship2}${
                rawAnswers.relationship2.endsWith('.') ? '' : '.'
            }`
        }
        if (containsNonSpaceCharacters(rawAnswers.relationship3)) {
            third = ` Q: Couples counseling? A: ${rawAnswers.relationship3}${
                rawAnswers.relationship3.endsWith('.') ? '' : '.'
            }`
        }
        answersFormatted.Relationship = first + second + third
    }

    const formatKids = () => {
        let first = ''
        let second = ''
        let third = ''
        let fourth = ''
        if (containsNonSpaceCharacters(rawAnswers.kids1)) {
            if (rawAnswers.kids1 == '0') {
                first = `I don't have children yet. `
                if (containsNonSpaceCharacters(rawAnswers.kids2)) {
                    if (rawAnswers.kids2 == 'Yes') {
                        second = 'I want kids. '
                    } else if (rawAnswers.kids2 == 'Probably') {
                        second = 'I probably want kids. '
                    } else if (rawAnswers.kids2 == 'Probably not') {
                        second = "I probably don't want kids. "
                    } else if (rawAnswers.kids2 == 'No') {
                        second = "I don't want kids. "
                    } else {
                        second = rawAnswers.kids2
                    }
                }
            } else if (rawAnswers.kids1 == '1') {
                first = `I have 1 child already. `
                if (containsNonSpaceCharacters(rawAnswers.kids2)) {
                    if (rawAnswers.kids2 == 'Yes') {
                        second = 'I want more kids. '
                    } else if (rawAnswers.kids2 == 'Probably') {
                        second = 'I probably want more kids. '
                    } else if (rawAnswers.kids2 == 'Probably not') {
                        second = "I probably don't want more kids. "
                    } else if (rawAnswers.kids2 == 'No') {
                        second = "I don't want more kids. "
                    } else {
                        second = rawAnswers.kids2
                    }
                }
            } else {
                first = `I have ${rawAnswers.kids1} kids already. `
                if (containsNonSpaceCharacters(rawAnswers.kids2)) {
                    if (rawAnswers.kids2 == 'Yes') {
                        second = 'I want more kids. '
                    } else if (rawAnswers.kids2 == 'Probably') {
                        second = 'I probably want more kids. '
                    } else if (rawAnswers.kids2 == 'Probably not') {
                        second = "I probably don't want more kids. "
                    } else if (rawAnswers.kids2 == 'No') {
                        second = "I don't want more kids. "
                    } else {
                        second = rawAnswers.kids2
                    }
                }
            }
        }

        if (containsNonSpaceCharacters(rawAnswers.kids3)) {
            third = `Ideally I'd have ${rawAnswers.kids3} kids in total. `
        }
        if (containsNonSpaceCharacters(rawAnswers.kids4)) {
            if (rawAnswers.kids4 == 'ASAP') {
                fourth = 'I want to have my next child ASAP.'
            } else if (rawAnswers.kids4 == 'Not applicable') {
                fourth = ''
            } else {
                fourth = `I want to have my next child in ${rawAnswers.kids4}.`
            }
        }
        answersFormatted.Kids = first + second + third + fourth
    }

    const formatMarriage = () => {
        let first = ''
        let second = ''
        if (containsNonSpaceCharacters(rawAnswers.marriage1)) {
            if (rawAnswers.marriage1 == 'Yes') {
                first = 'I want to get married'
            } else if (rawAnswers.marriage1 == 'Probably') {
                first = 'I probably want to get married'
            } else if (rawAnswers.marriage1 == 'Probably not') {
                first = "I probably don't want to get married"
            } else if (rawAnswers.marriage1 == 'No') {
                first = "I don't want to get married"
            } else {
                first = rawAnswers.marriage1
            }
        }
        if (containsNonSpaceCharacters(rawAnswers.marriage2)) {
            if (rawAnswers.marriage2 == 'ASAP') {
                second = ' ASAP.'
            } else if (rawAnswers.marriage2 == "What's the rush?") {
                second = ', but in no hurry.'
            } else if (rawAnswers.marriage2 == 'Not applicable') {
                second = ''
            } else {
                second = ` in ${rawAnswers.marriage2}.`
            }
        }
        answersFormatted.Marriage = first + second
    }

    formatDealbreaker()
    formatFun()
    formatPsychology()
    formatFood()
    formatAlcohol()
    formatSex()
    formatPolitics()
    formatAstrology()
    formatCareer()
    formatReligion()
    formatRelationship()
    formatKids()
    formatMarriage()
    formatForFun()
    return answersFormatted

    // MODAL SHOULD SAY "PLEASE SAVE THE JOKES FOR YOUR CHAT CONVERSATIONS"
    // OR OR OR, FOR THE BS FREEFORM QUESTIONS AT THE END SAY SOMETHING LIKE, "IF YOU'RE A COMEDIAN, BELOW IS THE PLACE TO SHOW IT. (above answers should not be jokes)"

    // THIS WILL BE CALLED IN ANSWERSDEMO, ANSWERSVOTING, ANSWERQUESTIONS (TO SHOW THEM A PREVIEW), AND maybe ProfileAnswersEditor
}
