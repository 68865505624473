import React, { useContext } from 'react'
import { View } from 'react-native'
import AlphaText from './TextAlpha'
import { COLORS } from '../../Colors'
// import { styles } from "./StylesDoHaveAccount" //styles for modalS?????
import { ScreenSizeContext } from '../../ScreenSizeContext'

export default function ModalProcessing() {
    const { HS, VS } = useContext(ScreenSizeContext)

    return (
        <View
            style={{
                backgroundColor: COLORS.modalSurroundings,
                flex: 1,
                justifyContent: 'center',
            }}
        >
            <View
                style={{
                    backgroundColor: COLORS.lightGreen,
                    padding: 30 * HS,
                    margin: 20,
                    borderRadius: 10,
                    alignItems: 'center',
                }}
            >
                <AlphaText
                    style={{
                        color: COLORS.nearBlack,
                        fontSize: 30,
                        textAlign: 'center',
                    }}
                >
                    Processing Request
                </AlphaText>
                <AlphaText
                    style={{
                        color: COLORS.nearBlack,
                        fontSize: 20,
                        textAlign: 'center',
                    }}
                >
                    Please Wait
                </AlphaText>
            </View>
        </View>
    )
}
