import React, { useState, useEffect, useContext } from 'react'
import { Dimensions, View, TextInput, StyleSheet, Pressable } from 'react-native'

import { db } from './../../FirebaseInitialization'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
import ButtonSecondary from '../ComponentsShared/ButtonSecondary'
import { SafeAreaView } from 'react-native-safe-area-context'
import AlphaText from './../ComponentsShared/TextAlpha'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'
import { COLORS } from './../../Colors'
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function SignUpFeedback({ navigation }) {
    const { HS, VS } = useContext(ScreenSizeContext)
    const styles = StyleSheet.create({
        textInput: {
            borderWidth: 2,
            color: COLORS.nearBlack,
            marginVertical: 5,
            // flex: 1,
            minHeight: 75 * VS,
            fontSize: 16 * ((VS + HS) / 2) * 0.85, //if you update the calculations on this line, need to update alphatext and other textinputs
            fontFamily: 'Medium',
            backgroundColor: COLORS.white,
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 5 * HS,
        },
        container: {
            backgroundColor: COLORS.lightGreen,
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            alignSelf: 'center',
            flex: 1,
        },
    })

    const [text1, setText1] = useState('')
    const [text2, setText2] = useState('')
    const [text3, setText3] = useState('')
    const [text4, setText4] = useState('')

    async function handleFeedback() {
        const auth = getAuth()
        const currentUser = auth.currentUser

        await addDoc(collection(db, 'feedback'), {
            createdOn: serverTimestamp(),
            createdBy: currentUser.uid,
            email: currentUser.email,
            signUpFeedback: true,
            Question1: text1,
            Question2: text2,
            Question3: text3,
            Question4: text4,
        })
        //BEST WAY MIGHT BE TO TRIGGER EMAIL TO INFO@THRIVEDATINGAPP.COM ACCOUNT WITH A SUBJECT LINE OF "APP-FEEDBACK-hOzKWsQiK4X3XRjwQ4ICx5vRoZ53" SO CAN EASILY FILTER
        //attach their uid and email to it for easy follow ups?
        logEvent(analytics, 'finished_signup_feedback_screen')
        navigation.navigate('AccountCompleted')
    }
    function handleSkip() {
        logEvent(analytics, 'finished_signup_feedback_screen')
        navigation.navigate('AccountCompleted')
    }

    return (
        <SafeAreaView style={{ backgroundColor: COLORS.lightGreen, flex: 1 }}>
            <View style={styles.container}>
                <AlphaText
                    style={{
                        color: COLORS.nearBlack,
                        marginVertical: 10,
                        fontSize: 30,
                        textAlign: 'center',
                    }}
                >
                    Account Creation Feedback
                </AlphaText>
                <View style={{ alignSelf: 'center', width: '95%' }}>
                    <AlphaText
                        style={{
                            color: COLORS.nearBlack,
                            fontSize: 16,
                            textAlign: 'center',
                            marginHorizontal: 15 * HS,
                            marginVertical: 7,
                        }}
                    >
                        We're striving to make Thrive the best dating app ever. Your feedback is essential in that
                        process.
                    </AlphaText>
                    <AlphaText
                        style={{
                            color: COLORS.nearBlack,
                            fontSize: 16,
                            textAlign: 'center',
                            marginHorizontal: 15 * HS,
                            marginVertical: 7,
                        }}
                    >
                        All fields are optional.
                    </AlphaText>
                    <TextInput
                        multiline
                        style={styles.textInput}
                        placeholder="How would you describe the account creation process overall?"
                        placeholderTextColor={COLORS.darkGray}
                        maxLength={1000}
                        onChangeText={(text1) => setText1(text1)}
                        value={text1}
                        // numberOfLines={3}
                    ></TextInput>
                    <TextInput
                        multiline
                        style={styles.textInput}
                        placeholder="Which question(s) were your least favorite to have to answer?"
                        placeholderTextColor={COLORS.darkGray}
                        maxLength={1000}
                        onChangeText={(text2) => setText2(text2)}
                        value={text2}
                    ></TextInput>
                    <TextInput
                        multiline
                        style={styles.textInput}
                        placeholder="Which freeform questions had too short of a character limit? Or which multiple choice had no answers that fit you?"
                        placeholderTextColor={COLORS.darkGray}
                        maxLength={1000}
                        onChangeText={(text3) => setText3(text3)}
                        value={text3}
                    ></TextInput>
                    <TextInput
                        multiline
                        style={styles.textInput}
                        placeholder="If you could add a question, what would it be?"
                        placeholderTextColor={COLORS.darkGray}
                        maxLength={1000}
                        onChangeText={(text4) => setText4(text4)}
                        value={text4}
                    ></TextInput>

                    <View style={{ flexDirection: 'row', marginBottom: 20, justifyContent: 'center' }}>
                        <ButtonSecondary onPress={handleSkip} buttonText={'Skip'} />
                        <ButtonPrimary onPress={handleFeedback} buttonText={'Submit Feedback'} />
                    </View>
                </View>
            </View>
        </SafeAreaView>
    )
}
