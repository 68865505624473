import { View, Pressable } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { styles } from './StylesSettings'

export default function ModalDelete({ setModalDelete, DeletingAccount }) {
    return (
        <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
            <View
                style={{
                    backgroundColor: COLORS.modalColor,
                    paddingHorizontal: 20,
                    paddingVertical: 30,
                    justifyContent: 'center',
                    marginHorizontal: 15,
                    borderRadius: 10,
                }}
            >
                <AlphaText style={[styles.modalTitle, { paddingBottom: 15 }]}>
                    Are you sure you want to delete your account?
                </AlphaText>
                <AlphaText style={styles.middleText}>
                    This action is irreversible. This will delete all data you've shared with Thrive permanently.
                </AlphaText>
                {/* <AlphaText
                        style={{
                            color: COLORS.darkBlue,
                            fontSize: 16,
                            textAlign: "center",
                            marginTop: 10, //below is text to use once you've implemented pausing
                        }} //If you'd simply like to hide all your content from other users indefinitely, please go back and pause your account instead, so that you retain the option to reverse the decision at a later date.
                    >
                        If you'd simply like to hide all your content from other
                        users indefinitely, please go back and pause your
                        account instead, so that you retain the option to
                        reverse the decision at a later date. 
                    </AlphaText> */}
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 0.5 }}></View>
                    <Pressable
                        onPress={() => setModalDelete(false)}
                        style={({ pressed }) => [
                            {
                                flex: 6,
                                borderRadius: 5,
                                padding: 10,
                                justifyContent: 'center',
                                marginTop: 25,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.redButton,
                            },
                        ]}
                    >
                        <AlphaText style={{ fontSize: 16, textAlign: 'center', color: COLORS.white }}>Back</AlphaText>
                    </Pressable>
                    <View style={{ flex: 1 }}></View>

                    <Pressable
                        onPress={DeletingAccount}
                        style={({ pressed }) => [
                            {
                                flex: 6,
                                borderRadius: 5,
                                padding: 10,
                                justifyContent: 'center',
                                marginTop: 25,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.darkGray,
                            },
                        ]}
                    >
                        <AlphaText
                            style={{ fontSize: 16, textAlign: 'center', color: COLORS.white }}
                        >{`Delete\nEverything`}</AlphaText>
                    </Pressable>
                    <View style={{ flex: 0.5 }}></View>
                </View>
            </View>
        </View>
    )
}
