import React, { useState } from 'react'
import { View, Modal, Dimensions, StyleSheet, Pressable } from 'react-native'
import { getAuth } from 'firebase/auth'
import AsyncStorage from '@react-native-async-storage/async-storage'
import AlphaText from '../ComponentsShared/TextAlpha'
import EmailPasswordInput from '../ComponentsShared/EmailPasswordInput'

const { width, height } = Dimensions.get('window')
let HS = width / 320
let VS = height / 640

import { signIn } from '../../FirebaseMethods'
import { COLORS } from './../../Colors'
import { getToken } from 'firebase/messaging'
import { db, messaging, functions, auth } from './../../FirebaseInitialization'

export default function SignIn({ navigation }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [modalAlert, setModalAlert] = useState(false)
    const [alertModalText, setAlertModalText] = useState('error')

    function requestPermission() {
        console.log('Requesting permission...')
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.')
            }
        })
    }
    // if ('serviceWorker' in navigator) {}
    navigator.serviceWorker.register('/service-worker.js').then((registration) => {
        getToken(messaging, {
            vapidKey: 'BEl2i89QfAtpqzwnGLb2tZeO8LfsQI9HPHiiFGmEfSsfdmwz2WI0n1XGyucoucz61Wm-Koag_NcaZmPkavH5Bcg', // to view the key on firebase website, go to project settings, cloud messaging, web configuration, and then you have to click delete key to be able to actually view the whole key because their UI is shit. Don't accidentally actually delete the key though
            serviceWorkerRegistration: registration,
        })
            .then((currentToken) => {
                if (currentToken) {
                    //send the token to the server, so add the token to their firestore I guess
                    console.log('there is a token so send to firestore')
                } else {
                    requestPermission()
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err)
            })
    })
    // //////////////EMAIL LINK AUTHENTICATION///////////////////////
    // async function sendSignInLink() {
    //     let actionCodeSettings = {
    //         url: "https://www.vibedating.app", //must be an https (not http) for security
    //         handleCodeInApp: true,
    //         iOS: {
    //             bundleId: "com.vibedatingreal.vibe",
    //         },
    //         android: {
    //             packageName: "com.vibedatingreal.vibe",
    //             installApp: true,
    //             // minimumVersion: '12'
    //         },
    //         //   dynamicLinkDomain: 'example.page.link'
    //     };
    //     //NEED TO TEST TO SEE WHAT HAPPENS IF USER DOESN'T TYPE ANYTHING OR TYPES INVALID STUFF INTO EMAIL
    //     await firebase
    //         .auth()
    //         .sendSignInLinkToEmail(email, actionCodeSettings)
    //         .then(() => {
    //             window.alert("Login link sent to ", email);
    //             try {
    //                 AsyncStorage.setItem("emailForSignIn", email);
    //             } catch (e) {}
    //         })
    //         .catch((error) => {
    //             let errorCode = error.code;
    //             let errorMessage = error.message;
    //             console.log(errorCode, errorMessage);
    //         });

    //     navigation.navigate("Sign In 2");
    // }

    ////////OLD PASSWORD METHOD/////////////////////

    async function handlePress() {
        if (!email) {
            setModalAlert(true)
            setAlertModalText('Email field is required.')
        }

        if (!password) {
            setModalAlert(true)
            setAlertModalText('Password field is required.')
        }

        await signIn(email, password)
        const auth = getAuth()
        const user = auth.currentUser
        if (user) {
            const verificationCheck = user.emailVerified //please note that resetting password seems to verify email as well as through normal email verification link
            // console.log(verificationCheck);
            if (verificationCheck == true) {
                setEmail('') //IGNORE ME UNTIL HAVE FREE TIME... COULD PASS EMAIL STATE THROUGH TO SIGN UP SCREEN WHEN NAVIGATING BETWEEN SIGNIN & SIGNUP AND VICE VERSA
                setPassword('')
                navigation.navigate('Thrive')
            }
            if (verificationCheck == false) {
                setModalAlert(true)
                setAlertModalText(
                    'Your email is unverified. Please click your email verification link or contact an Admin.'
                )
            }
        } else {
            //no user signed in
        }
    }

    function handlePasswordReset() {
        //CHECK IF THEY'VE ENTERED A VALID EMAIL ALREADY. IF SO, DO THIS
        //IF NOT, THEN YOU NEED TO DISPLAY THE TEXTINPUT BOX IN THE MODAL WITH A RESET BUTTON
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                setModalAlert(true)
                setAlertModalText('We sent you an email to reset your password!')
            })
            .catch((error) => {
                let errorCode = error.code
                let errorMessage = error.message
                setModalAlert(true)
                setAlertModalText('Please enter your email before pressing button.')
                window.alert(errorCode, errorMessage)
            })
    }

    return (
        <View style={styles.container}>
            <Modal
                animationType="fade"
                transparent={true}
                visible={modalAlert}
                onRequestClose={() => {
                    setModalAlert(!modalAlert)
                }}
            >
                <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                    <View
                        style={{
                            backgroundColor: COLORS.white,
                            padding: 20,
                            // alignItems: "center",
                            justifyContent: 'center',
                            flexDirection: 'column',
                            flex: 1,
                            marginHorizontal: 30,
                            marginTop: 50,
                            marginBottom: 50,
                            borderRadius: 20,
                        }}
                    >
                        <AlphaText
                            style={{
                                color: COLORS.nearBlack,
                                fontSize: 30,
                                textAlign: 'center',
                                paddingBottom: 15,
                            }}
                        >
                            {alertModalText}
                        </AlphaText>

                        <View
                            style={{
                                flexDirection: 'row',
                            }}
                        >
                            <View style={{ flex: 0.5 }}></View>

                            <Pressable
                                onPress={() => setModalAlert(!modalAlert)}
                                style={({ pressed }) => [
                                    {
                                        flex: 6,
                                        borderRadius: 5,
                                        padding: 10,
                                        justifyContent: 'center',
                                        marginTop: 25,
                                        backgroundColor: COLORS.redButton,
                                        opacity: pressed ? 0.5 : 1,
                                    },
                                ]}
                            >
                                <AlphaText
                                    style={{
                                        fontSize: 17,
                                        color: COLORS.white,
                                        textAlign: 'center',
                                    }}
                                >
                                    Back
                                </AlphaText>
                            </Pressable>

                            <View style={{ flex: 0.5 }}></View>
                        </View>
                    </View>
                </View>
            </Modal>
            <AlphaText
                style={{
                    color: COLORS.nearBlack,
                    marginBottom: 40 * VS * VS,
                    fontSize: 40,
                }}
            >
                Sign In
            </AlphaText>

            <div style={{ width: '90%' }}>
                <EmailPasswordInput inputType={'email'} value={email} setStateGeneric={setEmail} />
                <EmailPasswordInput inputType={'password'} value={password} setStateGeneric={setPassword} />
            </div>
            <Pressable
                onPress={handlePress}
                style={({ pressed }) => [
                    {
                        color: '#2196f3',
                        borderRadius: 10 * HS,
                        padding: 7 * VS,
                        marginTop: 40 * VS * VS,
                        backgroundColor: COLORS.darkGray,
                        opacity: pressed ? 0.5 : 1,
                    },
                ]}
            >
                <AlphaText
                    style={{
                        fontSize: 18,
                        color: COLORS.white,
                        paddingHorizontal: 15,
                        paddingVertical: 10,
                    }}
                >
                    Sign In
                </AlphaText>
            </Pressable>

            <Pressable style={{ marginTop: 25 }} onPress={() => navigation.navigate('DemoOrCreate')}>
                {({ pressed }) => (
                    <AlphaText
                        style={{
                            fontSize: 14,
                            color: COLORS.nearBlack,
                            textDecorationLine: 'underline',
                            opacity: pressed ? 0.5 : 1,
                        }}
                    >
                        Don't have an account?
                    </AlphaText>
                )}
            </Pressable>
            <Pressable style={styles.buttonSecondary} onPress={handlePasswordReset}>
                {({ pressed }) => (
                    <AlphaText
                        style={{
                            fontSize: 14,
                            color: COLORS.nearBlack,
                            textDecorationLine: 'underline',
                            opacity: pressed ? 0.5 : 1,
                        }}
                    >
                        Password reset
                    </AlphaText>
                )}
            </Pressable>

            {/* <Pressable
                    style={styles.button}
                    onPress={handleBob} 
                >
                    <AlphaText style={styles.buttonText}>Sign in as Bob</AlphaText>
                </Pressable>
                <Pressable
                    style={styles.button}
                    onPress={handleKate} 
                >
                    <AlphaText style={styles.buttonText}>Sign in as Kate</AlphaText>
                </Pressable> */}
        </View>
    )
}

const styles = StyleSheet.create({
    buttonSecondary: {
        marginTop: 20,
    },
    container: {
        flex: 1,
        backgroundColor: COLORS.lightGreen,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
    },
})
