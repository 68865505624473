import React, { useState, useEffect, useRef, useContext } from 'react'
import { Animated, Image, View, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import KidsIcon from './../../assets/icons/KidsIcon.png' //DON'T DELETE THESE. THEY ARE USED
import RelationshipIcon from './../../assets/icons/RelationshipIcon.png'
import MarriageIcon from './../../assets/icons/MarriageIcon.png'
import FunIcon from './../../assets/icons/FunIcon.png'
import PsychologyIcon from './../../assets/icons/PsychologyIcon.png'
import FoodIcon from './../../assets/icons/FoodIcon.png'
import AlcoholIcon from './../../assets/icons/AlcoholIcon.png'
import PoliticsIcon from './../../assets/icons/PoliticsIcon.png'
import SexIcon from './../../assets/icons/SexIcon.png'
import AstrologyIcon from './../../assets/icons/AstrologyIcon.png'
import CareerIcon from './../../assets/icons/CareerIcon.png'
import ReligionIcon from './../../assets/icons/ReligionIcon.png' //DON'T DELETE THESE. THEY ARE USED
import { ScreenSizeContext } from './../../ScreenSizeContext'
import { useModal } from '../main/AnswersVoting'

export default function CapsuleButton({
    keyword,
    pressed,
    answers,
    handlePress,
    color = COLORS.lightGreen,
    initialDeployed = false,
}) {
    const { HS, VS } = useContext(ScreenSizeContext)
    const firstUpdate = useRef(true)
    const [deployed, setDeployed] = useState(initialDeployed)
    const [ViewWidth] = useState(new Animated.Value(0))
    const { openAnswerQuestionsModal } = useModal()

    useEffect(() => {
        if (firstUpdate.current == false && pressed[keyword]) {
            Animated.timing(ViewWidth, {
                toValue: 1,
                duration: 200,
                useNativeDriver: true,
            }).start()
            setTimeout(() => {
                setDeployed(true)
            }, 200)
        }

        if (firstUpdate.current == false && !pressed[keyword]) {
            setDeployed(false)
            Animated.timing(ViewWidth, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true,
            }).start()
            // setTimeout(() => {
            //     setDeployed(false)
            // }, 500)
        }
        if (firstUpdate.current == true) {
            //prevents from animating on initial render
            firstUpdate.current = false
        }
    }, [pressed])

    const WidthValue = ViewWidth.interpolate({
        inputRange: [0, 1],
        outputRange: [103 * HS, 248 * HS], //second value is first value + the paddings, and the gap from deepdivedemo
    })

    return (
        <Pressable
            style={[styles.capsule, { paddingHorizontal: 17 * HS, backgroundColor: color }]}
            onPress={() => handlePress(keyword)}
        >
            <Animated.View style={{ flexDirection: 'row', width: WidthValue }}>
                {/* IMAGE TEMPORARILY DISABLED BECAUSE IOS WON'T BUILD (AND IOS IS ONLY PLACE YOU CAN CONSOLE LOG FFS) */}
                <Image style={styles.icon} source={require(`./../../assets/icons/${keyword}Icon.png`)} />
                <AlphaText style={styles.capsuleText}>{keyword}</AlphaText>
            </Animated.View>
            {deployed && (
                <View>
                    <AlphaText style={{ fontSize: 15 }}>{answers[keyword]}</AlphaText>
                    {answers[keyword].includes(`♥`) && (
                        <Pressable
                            style={{
                                backgroundColor: COLORS.redButton,
                                borderRadius: 10,
                                paddingVertical: 3,
                                paddingHorizontal: 7,
                            }}
                            onPress={() => openAnswerQuestionsModal?.(keyword)}
                        >
                            <AlphaText style={{ fontSize: 12, color: COLORS.white, textAlign: 'center' }}>
                                Answer this question to reveal their ♥ ♥ ♥
                            </AlphaText>
                        </Pressable>
                    )}
                </View>
            )}
        </Pressable>
    )
}

const styles = StyleSheet.create({
    capsule: {
        // marginTop: 10,
        backgroundColor: COLORS.lightGreen,
        borderRadius: 20,
        paddingVertical: 10,

        // flexGrow: 1,
        flexDirection: 'column',
        flexShrink: 1,
    },
    capsuleText: {
        fontSize: 15,
    },
    icon: {
        resizeMode: 'contain',
        width: 20,
        height: 20,
        marginRight: 10,
        marginLeft: -7,
        marginTop: 5,
        tintColor: COLORS.nearBlack,
    },
})
