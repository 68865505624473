import React from 'react'
import { Dimensions, View, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from './../../Colors'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'

const { width, height } = Dimensions.get('window')
let HS = width / 320
let VS = height / 640
export default function DoneVotingDemo({ navigation }) {
    //
    const handleReturnHome = function () {
        // LOG tutorial_complete
        logEvent(analytics, 'tutorial_begin')

        navigation.navigate('HomeDemo')
    }

    return (
        <View style={styles.container}>
            <AlphaText
                style={{
                    fontSize: 40,
                    color: COLORS.darkGray,
                    marginBottom: 20,
                }}
            >
                Nice Work!
            </AlphaText>

            <AlphaText style={styles.AlphaText}>
                If you voted "Yes" on someone's text profile and on their photo set, then they're approved by you in the
                cloud.
            </AlphaText>

            <AlphaText style={styles.AlphaText}>
                When someone you approved does the same for you, you'll be notified of the match.
            </AlphaText>

            {/* <AlphaText style={styles.AlphaText}>
                   MAYBE PUT MORE COPY HERE EXPLAINING WHY APP WORKS AND WHY IT'S GOOD
                </AlphaText> */}
            {/* <AlphaText style={styles.AlphaText}>
                Press the button below to return home, where you can create your
                account, try the voting demo again, or if you just need a rules
                refresher - press the settings button.
            </AlphaText> */}

            <Pressable
                onPress={handleReturnHome}
                style={({ pressed }) => [
                    {
                        justifyContent: 'center',
                        paddingHorizontal: 5,
                        borderRadius: 10,
                        marginTop: 25,
                        backgroundColor: COLORS.redButton,
                        opacity: pressed ? 0.5 : 1,
                    },
                ]}
            >
                <AlphaText
                    style={{
                        color: COLORS.white,
                        fontSize: 18,
                        padding: 10,
                    }}
                >
                    Return Home
                </AlphaText>
            </Pressable>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.white,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },

    AlphaText: {
        marginHorizontal: 30,
        marginVertical: 10,
        textAlign: 'center',
        fontSize: 16,
        color: COLORS.nearBlack,
        lineHeight: 25 * HS,
    },
})
