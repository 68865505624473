import { Image, Pressable, } from "react-native";

export default function VotingButton(props) {

    return (
        <Pressable onPress={() => props.press()}
            disabled={props.disabled}
            style={({ pressed }) => [
                {
                    opacity: pressed || props.disabled ? 0.5 : 1,
                    marginHorizontal: 17,
                    marginVertical: 15
                }
            ]}>
            <Image source={props.source} style={{ height: 60, width: 60 }} />
        </Pressable>
    );
}
