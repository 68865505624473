import React, { useState, useEffect } from 'react'
import { Dimensions, View, Image, Text, Modal, Pressable, StyleSheet, BackHandler } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
import ModalPrimary from '../ComponentsShared/ModalPrimary'
import ModalNameHeight from '../ComponentsAuthentication/ModalNameHeight'
import ModalAlert from '../ComponentsShared/ModalAlert'
import * as ImagePicker from 'expo-image-picker'
import { db } from './../../FirebaseInitialization'
import { updateDoc, doc } from 'firebase/firestore'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
// import "react-native-get-random-values";
import * as uuid from 'uuid'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { createIdLink, createPendingUser } from '../../FirebaseMethods'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from './../../Colors'
import AddPhotoIcon from './../../assets/icons/AddPhotoIconNB.png'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'

const { width, height } = Dimensions.get('window')

let HS = width / 320
let VS = height / 640

export default function Authentication({ navigation }) {
    const [modalProcessing, setModalProcessing] = useState(false)
    const [modalNameHeight, setModalNameHeight] = useState(false)
    const [modalAlert, setModalAlert] = useState(false)
    const [alertModalText, setAlertModalText] = useState('error')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [nickname, setNickname] = useState('')
    const [feet, setFeet] = useState('')
    const [inches, setInches] = useState('')

    const [imageOne, setImageOne] = useState('')
    let selectImageAsync = async () => {
        let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (permissionResult.granted === false) {
            setModalAlert(true)
            setAlertModalText('Granting permission to access your image gallery is required to upload photos')
        }
        let pickerResult = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
        })
        setImageOne(pickerResult.assets[0].uri)
    }

    let handleImagePicked = async (uri) => {
        try {
            await uploadImageAsync(uri)
            AsyncStorage.setItem('creationCheckpoint', 'spent')
            setModalNameHeight(true) //THIS FUCKS IT IN TESTING BECAUSE HAVE TO RUN FUCKER TWICE FOR AUTH TO WORK
            //LOOK INTO WHY HAVE TO RUN TWICE - BECAUSE ITS FUCKING SHIT UP
        } catch (e) {
            setImageOne('')
            setModalAlert(true)
            setAlertModalText('Upload failed. Please try again. (Contact admin if error repeats)')
        } finally {
            setModalProcessing(false)
        }
    }

    async function uploadImageAsync(uri) {
        try {
            const img = await fetch(uri)
            const bytes = await img.blob()
            const auth = getAuth()
            const uid = auth.currentUser.uid
            const storage = getStorage()
            const remoteUri = 'users/' + uid + '/' + uuid.v4()
            const userRef = ref(storage, remoteUri)
            await uploadBytesResumable(userRef, bytes)
            const toSave = await getDownloadURL(ref(storage, remoteUri))

            createIdLink(toSave, uid)
            createPendingUser(uid)
        } catch (err) {
            console.log(err)
        }
    }
    async function uploadAndProceed() {
        if (imageOne == '') {
            setModalAlert(true)
            setAlertModalText('Please select an ID photo by tapping the camera icon above before proceeding.')
        } else {
            setModalProcessing(true)
            await handleImagePicked(imageOne)
        }
    }

    async function submitName() {
        const auth = getAuth()
        const uid = auth.currentUser.uid
        let totalHeight = undefined
        if (feet != '' && inches != '') {
            totalHeight = feet + `'` + inches + `"`
        }
        let nicknameToDisplay = nickname
        if (nicknameToDisplay === '') {
            nicknameToDisplay = firstName
        }
        if (firstName != '' && lastName != '') {
            await updateDoc(doc(db, 'users', uid), {
                height: totalHeight, //**********************************MAKE SURE THIS IS WORKING (verified that it's formatting correctly and is a string)
                nickname: nicknameToDisplay,
                firstName: firstName, //**********************************MAKE SURE THIS IS WORKING
                lastName: lastName,
            })
            await updateDoc(doc(db, 'users', uid, 'photos', 'nameAgeHeight'), {
                height: totalHeight,
                nickname: nicknameToDisplay,
            })
            setModalNameHeight(false)
            logEvent(analytics, 'finished_authentication_screen')
            navigation.navigate('SignUpFeedback')
        } else {
            setModalAlert(true)
            setAlertModalText('Please enter your first and last name.')
        }
    }

    return (
        <SafeAreaView style={{ backgroundColor: COLORS.lightGreen, flex: 1 }}>
            <View style={styles.container}>
                <Modal animationType="fade" transparent={true} visible={modalProcessing}>
                    <ModalPrimary>
                        <AlphaText
                            style={{
                                fontSize: 20,
                            }}
                        >
                            Processing Request
                        </AlphaText>
                        <AlphaText
                            style={{
                                fontSize: 20,
                                marginTop: 20,
                            }}
                        >
                            Please Wait
                        </AlphaText>
                    </ModalPrimary>
                </Modal>
                <Modal animationType="fade" transparent={true} visible={modalNameHeight}>
                    <ModalNameHeight
                        submitName={submitName}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        nickname={nickname}
                        setNickname={setNickname}
                        feet={feet}
                        setFeet={setFeet}
                        inches={inches}
                        setInches={setInches}
                    />
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalAlert}
                    onRequestClose={() => {
                        setModalAlert(!modalAlert)
                    }}
                >
                    <ModalAlert setModalAlert={setModalAlert} alertModalText={alertModalText} />
                </Modal>
                <AlphaText style={{ fontSize: 35 }}>Authentication</AlphaText>
                <AlphaText
                    style={{
                        marginHorizontal: 30,
                        marginVertical: 10,
                        textAlign: 'center',
                        fontSize: 14,
                    }}
                >
                    To improve the safety and user experience of our app, we require all users to submit a photo of a
                    valid form of government photo identification. Your ID will never be visible to other users.
                </AlphaText>

                <Pressable
                    style={{
                        marginHorizontal: 15,
                        padding: 5,
                        borderWidth: 2,
                        borderColor: COLORS.nearBlack,
                        borderStyle: 'dashed',
                    }}
                    onPress={selectImageAsync}
                >
                    {imageOne === '' && <Image style={styles.photoIcon} source={AddPhotoIcon} />}
                    {imageOne !== '' && <Image style={styles.photoIcon} source={{ uri: imageOne }} />}
                </Pressable>

                <AlphaText
                    style={{
                        marginHorizontal: 30,
                        marginVertical: 10,
                        textAlign: 'center',
                        fontSize: 14,
                    }}
                >
                    This authentication step drastically reduces the number of bots, spam, and abusive/banned users, and
                    enables us to verify that all users are 18 and up.
                </AlphaText>
                <ButtonPrimary buttonText={'Upload your photo ID'} onPress={uploadAndProceed} />
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.lightGreen,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },

    photoIcon: {
        width: 102 * HS * 0.9,
        height: 136 * HS * 0.9,
        resizeMode: 'contain',
    },
})
