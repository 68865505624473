import React, { useContext } from 'react'
import { ScreenSizeContext } from './../../ScreenSizeContext'
import { Pressable } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'

export const textStyles = {
    fontFamily: 'Medium',
    fontSize: 20,
    color: COLORS.nearBlack,
} // is textStyles not actually used anywhere?

export default function ButtonPrimary({ buttonText, onPress }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    const styles = {
        pressable: {
            borderRadius: 7 * ((VS + HS) / 2),
            paddingVertical: 7 * ((VS + HS) / 2),
            paddingHorizontal: 14 * ((VS + HS) / 2),
            marginHorizontal: 10,
            marginTop: 20,
            justifyContent: 'center',
            alignItems: 'center',
        },
    }

    return (
        <Pressable
            onPress={onPress}
            style={({ pressed }) => [
                styles.pressable,
                { backgroundColor: COLORS.redButton, opacity: pressed ? 0.5 : 1 },
            ]}
        >
            <AlphaText style={{ color: COLORS.white, fontSize: 20 }}>{buttonText}</AlphaText>
        </Pressable>
    )
}
