import { useContext } from 'react'
import { TextInput, View, Dimensions } from 'react-native'
import { COLORS } from '../../Colors'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function EmailPasswordInput({ inputType, value, setStateGeneric }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    let placeholder = 'Error'
    let textContentType = 'emailAddress'
    let keyboardType = 'email-address'

    if (inputType == 'email') {
        placeholder = 'Email'
        textContentType = 'emailAddress'
        keyboardType = 'email-address'
    } else if (inputType == 'password') {
        placeholder = 'Password'
        textContentType = 'password'
        keyboardType = 'default'
    }
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
            {inputType == 'email' && <MailOutlineIcon />}
            {inputType == 'password' && <LockOpenIcon />}
            <View style={{ width: 10 }} />

            <TextInput
                style={{
                    color: COLORS.nearBlack,
                    borderWidth: 1,
                    margin: 10 * VS,
                    width: '100%',
                    height: 40 * VS,
                    fontSize: 18 * ((VS + HS) / 2) * 0.85, //if you update the calculations on this line, need to update alphatext and other textinputs
                    backgroundColor: COLORS.white,
                    placeholderTextColor: COLORS.darkGray,
                    paddingHorizontal: 10,
                    borderRadius: 10 * HS,
                    fontFamily: 'Medium',
                }}
                placeholder={placeholder}
                value={value}
                textContentType={textContentType}
                onChangeText={(value) => setStateGeneric(value)}
                keyboardType={keyboardType}
                autoCapitalize="none"
            />
            {/* this view offsets the icon so that the textinput is centered */}
            <View style={{ width: 30 }} />
        </View>
    )
}
