import React, { useState, useRef, useEffect } from 'react'
import { Animated, Dimensions, Image, Modal, Pressable, ScrollView, StyleSheet, View } from 'react-native'

import { SafeAreaView } from 'react-native-safe-area-context'
import { getAuth } from 'firebase/auth'
import { getDoc, getDocs, doc, arrayUnion, updateDoc, collection } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { db } from './../../FirebaseInitialization'

import VotingFooter from '../ComponentsShared/VotingFooter'
import PhotoHeader from '../ComponentsPhotos/PhotosHeader'
import PhotoSubHeader from '../ComponentsPhotos/PhotosSubHeader'
import ModalReport from '../ComponentsShared/ModalReport'
import AlphaText from '../ComponentsShared/TextAlpha'
import { LinearGradient } from 'expo-linear-gradient'

import { COLORS } from '../../Colors'
const { width, height } = Dimensions.get('window')
const imageBorderWidth = 5
const displayedWidth = width - 2 * imageBorderWidth
// I BELIEVE YOU'RE PLANNING ON HANDLING MATCH DETECTION IN THIS FILE (DETECT VOTED YES ON EACH HALF OF A PROFILE, THEREFORE CALL CLOUD FUNCTION WITH THAT UID)

export default function PhotosVoting({ route, navigation }) {
    const [imageData, setImageData] = useState({})
    const [person, setPerson] = useState(1)
    const [votes, setVotes] = useState({ 1: null, 2: null, 3: null, 4: null, 5: null, 6: null, 7: null })
    const [reportModal, setReportModal] = useState(false)
    const [displayedHeights, setDisplayedHeights] = useState({})
    const [nameAgeHeight, setNameAgeHeight] = useState({
        1: { name: '', age: '' },
        2: { name: '', age: '' },
        3: { name: '', age: '' },
        4: { name: '', age: '' },
        5: { name: '', age: '' },
        6: { name: '', age: '' },
        7: { name: '', age: '' },
    })

    // TEMPORARY INITIALIZATION OF VARS WITH FAKE DATA FOR TESTING, THESE WILL BE TAKEN FROM PARAMS
    // let lineup = [
    //     'dMYyKwfJTasVIwh7W1iQ3yFuhybQ',
    //     'dW2iZsUgLtyYfGSMjul3FVPf7ToE',
    //     'baIp5pE4Q3vcTHQQbHGcbKZTvc2M',
    //     'YUF2YkXG3nSGwVucLq9VOP0E69dW',
    //     'NwaDNgG2BFNna3rYV6VaZHANrkOg',
    //     'MzLu6yjzSdt5LeMlC149ehjS6p0q',
    //     'lCWxtd91Y76YDKUWfsmEkT2Z5qlO',
    //     // 'CVe4t6EsNIOmk65kDTmB0wY4Pl9b',
    // ]
    // let votedYes = []
    // let votedMaybe = []

    // GET PARAMETERS FROM ANSWERSVOTING
    //isn't this an outdated way of getting parameters?
    const { lineup, votedYes, votedMaybe } = route.params

    // const lineup = navigation.getParam("lineup"); //params are passed from AnswersVoting.js as per example https://reactnavigation.org/docs/params/
    // const votedYes = navigation.getParam("votedYes"); //lineup allows for getting photos from correct user. votedYes can be checked against photos which were liked (votedYes2) to detect match

    const getPics = async () => {
        let i = 1
        let updatedImageData = {}
        for (const uid of lineup) {
            let personsPhotosObject = {}
            const snapshot = await getDocs(collection(db, 'users', uid, 'photos'))

            snapshot.forEach((doc) => {
                let data = doc.data()
                let docId = doc.id //the thing you use to identify if face or body
                let photoUrl = data?.photoLink // the link itself
                if (docId.startsWith('face')) {
                    personsPhotosObject.face = photoUrl
                } else if (docId.startsWith('body')) {
                    personsPhotosObject.body = photoUrl
                } else if (docId.startsWith('life1')) {
                    personsPhotosObject.life1 = photoUrl
                } else if (docId.startsWith('life2')) {
                    personsPhotosObject.life2 = photoUrl
                } else if (docId.startsWith('life3')) {
                    personsPhotosObject.life3 = photoUrl
                } else if (docId.startsWith('nameAgeHeight')) {
                    setNameAgeHeight((oldNameAgeHeight) => {
                        return {
                            ...oldNameAgeHeight,
                            [i]: { name: data.nickname, age: data.age, height: data?.height },
                        }
                    })
                }
            })
            updatedImageData[i] = personsPhotosObject
            i++
        }
        setImageData(updatedImageData)
    }

    useEffect(() => {
        getPics()
        sizePics()
        console.log('PhotosVoting', votedMaybe, votedYes)
    }, [])
    useEffect(() => {
        console.log(nameAgeHeight)
    }, [nameAgeHeight])

    useEffect(() => {
        sizePics()
    }, [imageData, person])

    // REACT NATIVE CAN'T AUTOHEIGHT THE IMAGES BASED ON WIDTH AND ASPECT RATIO, SO WE HAVE TO DO IT OURSELVES

    const sizePics = () => {
        const calculatedDisplayedHeights = {}
        const fetchImageSizes = async () => {
            for (const [key, imageUrl] of Object.entries(imageData[person] || {})) {
                await getImageSize(imageUrl, key, calculatedDisplayedHeights)
            }
            setDisplayedHeights(calculatedDisplayedHeights)
        }

        const getImageSize = (imageUrl, key, heights) => {
            return new Promise((resolve, reject) => {
                Image.getSize(
                    imageUrl,
                    (imageWidth, imageHeight) => {
                        const scaleFactor = imageWidth / displayedWidth
                        const calculatedDisplayedHeight = imageHeight / scaleFactor
                        heights[key] = calculatedDisplayedHeight
                        resolve()
                    },
                    reject
                )
            })
        }

        fetchImageSizes()
    }

    function confirmAndProceed() {
        Object.entries(votes).forEach(([key, vote]) => processVote(key, vote))
        // console.log('votes', votes)
        // console.log('votedYes', votedYes)
        // console.log('votedMaybe', votedMaybe)

        navigation.navigate('DoneVoting')
    }

    const matchDetectionAndProcessing = httpsCallable(getFunctions(), 'matchDetectionAndProcessing')

    async function processVote(key, vote) {
        const auth = getAuth()
        const uid = auth.currentUser.uid
        const othersUid = lineup[Number(key) - 1]

        // console.log(key, vote)

        if (vote == 'yes') {
            if (votedYes.includes(othersUid)) {
                const thisUsersDoc = await getDoc(doc(db, 'users', uid)) //MIGHT BE NICE NOT TO HAVE TO WAIT ON RETRIEVING THEIR NAME HERE EVERYTIME THEY VOTE YES ON ANSWERS AND PHOTOS. CAN WE MAKE THEIR OWN NAME AVAILABLE TO THEM THROUGH USECONTEXT OR SOMETHING?
                const usersName = thisUsersDoc.data().nickname
                matchDetectionAndProcessing({
                    uid2: othersUid,
                    matchsName: nameAgeHeight[key].name,
                    callersName: usersName,
                })
                updateDoc(doc(db, 'users', uid), {
                    likesList: arrayUnion(matchingUID[i]),
                })
            } else if (votedMaybe.includes(othersUid)) {
                incrementPriorityList(othersUid)
            }
        }
        if (vote == 'skip') {
            if (votedYes.includes(othersUid)) {
                incrementPriorityList(othersUid)
            }
        }
        if (vote == 'no') {
            updateDoc(doc(db, 'users', uid), {
                blacklist: arrayUnion(othersUid),
            })
        }
    }

    // THIS IS UNTESTED
    async function incrementPriorityList(othersUid) {
        const priorityListData = await getDoc(doc(db, 'users', uid, 'functionAccess', 'priorityList'))
        const priorityList = priorityListData.data()
        if (!priorityList.hasOwnProperty(othersUid)) {
            updateDoc(doc(db, 'users', uid, 'functionAccess', 'priorityList'), {
                [othersUid]: 1,
            })
        } else if (priorityList[othersUid] == 2) {
            updateDoc(doc(db, 'users', uid, 'functionAccess', 'priorityList'), {
                [othersUid]: 3,
            })
        }
    }

    // NEED TO PULL THIS BELOW DATA FROM FIRESTORE
    // const nameAgeHeight = {
    //     //loaded from database when day's matches are fetched
    //     //or just this data for the demo screen
    //     1: {
    //         name: 'Bob',
    //         age: '22',
    //         height: `5'2"`,
    //     },
    //     2: {
    //         name: 'Ralph',
    //         age: '24',
    //     },
    //     3: {
    //         name: 'Joe',
    //         age: '26',
    //         height: `6'1"`,
    //     },
    //     4: {
    //         name: 'Jim',
    //         age: '27',
    //         height: `6'3"`,
    //     },
    //     5: {
    //         name: 'Doug',
    //         age: '20',
    //         height: `5'9"`,
    //     },
    //     6: {
    //         name: 'Lou',
    //         age: '18',
    //         height: `5'8"`,
    //     },
    //     7: {
    //         name: 'Paul',
    //         age: '25',
    //         height: `5'8"`,
    //     },
    // }

    //STILL WANT TO REDUCE IMAGE SIZE TO ONLY AS LARGE AS REQUIRED WHEN UPLOADING

    const scrollRef = useRef()

    useEffect(() => {
        scrollRef.current?.scrollTo({
            y: 0,
            animated: false,
        })
    }, [person])

    // GRADIENT STUFF
    const topGradientOpacity = useRef(new Animated.Value(0)).current
    const bottomGradientOpacity = useRef(new Animated.Value(1)).current

    const onScroll = (event) => {
        const { contentOffset, contentSize, layoutMeasurement } = event.nativeEvent

        const maxScrollOffset = contentSize.height - layoutMeasurement.height
        const scrollOffset = contentOffset.y

        const topGradientOpacityValue = scrollOffset / maxScrollOffset
        const bottomGradientOpacityValue = 1 - topGradientOpacityValue

        topGradientOpacity.setValue(topGradientOpacityValue)
        bottomGradientOpacity.setValue(bottomGradientOpacityValue)
    }
    const gradientColor = 'rgba(204,206,206,0.4)' //the tint of this is based off COLORS.darkGray but I turned up both the lightness and opaqueness on the photos screens so it's very similar color on white background as the text screen, but you can actually see it when covering the photos
    const gradientHeight = 100

    return (
        <SafeAreaView style={styles.container}>
            <Modal visible={reportModal} animationType="fade" transparent={true}>
                {/* TEST UID OF PROFILE!!! */}
                <ModalReport
                    setReportModal={setReportModal}
                    demo={false}
                    uidOfProfile={lineup[person - 1]}
                    screen={'photos'}
                />
            </Modal>
            <PhotoHeader name={nameAgeHeight[person].name} number={person} />
            <View style={{ flex: 1 }}>
                <ScrollView
                    style={{ paddingHorizontal: 20, backgroundColor: COLORS.white }}
                    ref={scrollRef}
                    showsVerticalScrollIndicator={false}
                    onScroll={(event) => onScroll(event)}
                    scrollEventThrottle={50}
                >
                    <PhotoSubHeader age={nameAgeHeight[person].age} personsHeight={nameAgeHeight[person]?.height} />

                    {imageData[person]?.face && (
                        <Image
                            source={{ uri: imageData[person].face }}
                            style={[styles.image, { height: displayedHeights.face }]}
                        />
                    )}
                    {imageData[person]?.body && (
                        <Image
                            source={{ uri: imageData[person].body }}
                            style={[styles.image, { height: displayedHeights.body }]}
                        />
                    )}
                    {imageData[person]?.life1 && (
                        <Image
                            source={{ uri: imageData[person].life1 }}
                            style={[styles.image, { height: displayedHeights.life1 }]}
                        />
                    )}
                    {imageData[person]?.life2 && (
                        <Image
                            source={{ uri: imageData[person].life2 }}
                            style={[styles.image, { height: displayedHeights.life2 }]}
                        />
                    )}
                    {imageData[person]?.life3 && (
                        <Image
                            source={{ uri: imageData[person].life3 }}
                            style={[styles.image, { height: displayedHeights.life3 }]}
                        />
                    )}

                    <View style={{ alignItems: 'center', marginVertical: 25 }}>
                        <Pressable onPress={() => setReportModal(true)}>
                            <AlphaText>Hide & Report</AlphaText>
                        </Pressable>
                    </View>
                </ScrollView>
                <Animated.View
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        height: gradientHeight,
                        opacity: topGradientOpacity,
                    }}
                    pointerEvents={'none'}
                >
                    <LinearGradient colors={[gradientColor, 'transparent']} style={{ flex: 1 }} />
                </Animated.View>
                <Animated.View
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        height: gradientHeight,
                        opacity: bottomGradientOpacity,
                    }}
                    pointerEvents={'none'}
                >
                    <LinearGradient colors={['transparent', gradientColor]} style={{ flex: 1 }} />
                </Animated.View>
            </View>
            <VotingFooter
                person={person}
                setPerson={setPerson}
                votes={votes}
                setVotes={setVotes}
                setConfirmationModal={null}
                screen="PhotosVoting"
                initialYesVotes={5}
                confirmAndProceed={confirmAndProceed}
            />
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        backgroundColor: COLORS.white,
        flex: 1,
    },
    image: {
        marginTop: 10,
        alignSelf: 'center',
        resizeMode: 'contain',
        width: displayedWidth,
        borderRadius: 10,
    },
})
