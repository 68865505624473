import React from 'react'
import { View, StyleSheet, Pressable, Dimensions } from 'react-native'
import 'firebase/auth'
import { COLORS } from './../../Colors'
import AlphaText from '../ComponentsShared/TextAlpha'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'

const { width, height } = Dimensions.get('window')

let HS = width / 320
let VS = height / 640

export default function DemoOrCreate({ navigation }) {
    function handleCreateAccount() {
        navigation.navigate('CreateAccount')
    }
    function handleTryDemo() {
        logEvent(analytics, 'tutorial_begin')
        navigation.navigate('HomeDemo')
    }

    return (
        <View style={{ display: 'flex', flex: 1, backgroundColor: COLORS.lightGreen, justifyContent: 'center' }}>
            <View style={styles.mainContainer}>
                <AlphaText
                    style={{
                        color: COLORS.nearBlack,
                        marginBottom: 10 * VS,
                        marginHorizontal: 20 * HS,
                        fontSize: 40,
                        textAlign: 'center',
                    }}
                >
                    Have you tried the demo?
                </AlphaText>
                <View>
                    <Pressable
                        onPress={handleTryDemo}
                        style={({ pressed }) => [styles.pressable, { opacity: pressed ? 0.5 : 1 }]}
                    >
                        <AlphaText style={styles.buttonText}>Try Demo</AlphaText>
                        <AlphaText style={styles.buttonText2}>{'(Recommended)'}</AlphaText>
                    </Pressable>
                    <Pressable
                        onPress={handleCreateAccount}
                        style={({ pressed }) => [styles.pressable, { opacity: pressed ? 0.5 : 1 }]}
                    >
                        <AlphaText style={styles.buttonText}>Create Account</AlphaText>
                    </Pressable>
                </View>
                <Pressable
                    style={({ pressed }) => [styles.buttonSecondary, { opacity: pressed ? 0.5 : 1 }]}
                    onPress={() => navigation.navigate('SignIn')}
                >
                    {({ pressed }) => (
                        <AlphaText
                            style={{
                                fontSize: 14,
                                textDecorationLine: 'underline',
                                color: COLORS.nearBlack,
                                opacity: pressed ? 0.5 : 1,
                            }}
                        >
                            Already have an account?
                        </AlphaText>
                    )}
                </Pressable>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    buttonText: {
        fontSize: 18,
        color: COLORS.nearBlack,
        textAlign: 'center',
    },
    buttonText2: {
        //for "(Recommended)" text
        fontSize: 16,
        color: COLORS.nearBlack,
        textAlign: 'center',
    },
    buttonSecondary: {
        marginTop: 30,
    },
    container: {
        backgroundColor: COLORS.white,
        flex: 1,
    },
    mainContainer: {
        backgroundColor: COLORS.lightGreen,
        marginBottom: 50 * VS,
        alignItems: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },
    pressable: {
        minHeight: 80 * VS,
        justifyContent: 'center',
        borderRadius: 10 * HS,
        padding: 15,
        marginTop: 20,
        backgroundColor: COLORS.white,
    },
})
