import React, { useState, useRef } from "react";
import { Text, View } from "react-native";


export default function BlankPlaceholder() {
    return (
        <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
            <Text style={{ textAlign: "center" }}>This website is not currently live. We apologize for the inconvenience.</Text>
        </View >
    )

}

