import React, { useContext } from 'react'
import { View, Pressable, ScrollView, StyleSheet, Image, Text } from 'react-native'
import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
import ModalPrimary from '../ComponentsShared/ModalPrimary'
import AlphaText from '../ComponentsShared/TextAlpha'
import { answerProcessor } from '../ComponentsShared/answerProcessor'
import { COLORS } from '../../Colors'
// import { styles } from "./StylesDoHaveAccount" //styles for modalS?????
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function ModalConfirmation({ setModalConfirmationVisible, handleContinue, answers }) {
    const { HS, VS } = useContext(ScreenSizeContext)
    console.log(answers)
    const answersPreview = answerProcessor(answers)
    console.log(answersPreview)

    return (
        <View
            style={{
                backgroundColor: COLORS.modalSurroundings,
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
            }}
        >
            <View
                style={{
                    backgroundColor: COLORS.lightGreen,
                    padding: 30 * HS,
                    borderRadius: 10,
                    width: '90%',
                    maxHeight: '90%',
                    alignItems: 'center',
                }}
            >
                <AlphaText
                    style={{
                        fontSize: 20,
                        textAlign: 'center',
                    }}
                >
                    Please confirm the accuracy of your responses.
                </AlphaText>
                <AlphaText
                    style={{
                        fontSize: 16,
                        textAlign: 'center',
                    }}
                >
                    Formatting will be better when displayed to others.
                </AlphaText>
                <ScrollView
                    style={{ flex: 1, backgroundColor: 'white', minWidth: '100%' }}
                    showsVerticalScrollIndicator={true}
                >
                    <View>
                        <AlphaText style={{ fontSize: 16 }}>{'Marriage\n' + answersPreview.Marriage}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\nKids\n' + answersPreview.Kids}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\nDealbreaker\n' + answersPreview.Dealbreaker}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\n'}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>
                            {'\nRelationship\n' + answersPreview.Relationship}
                        </AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\nFun\n' + answersPreview.Fun}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\nPsychology\n' + answersPreview.Psychology}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\nFood\n' + answersPreview.Food}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\nAlcohol\n' + answersPreview.Alcohol}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\nSex\n' + answersPreview.Sex}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\nPolitics\n' + answersPreview.Politics}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\nAstrology\n' + answersPreview.Astrology}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\nCareer\n' + answersPreview.Career}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\nReligion\n' + answersPreview.Religion}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\n'}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\n' + answersPreview.ForFun1.question}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\n' + answersPreview.ForFun1.answer}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\n\n' + answersPreview.ForFun2.question}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\n' + answersPreview.ForFun2.answer}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\n\n' + answersPreview.ForFun3.question}</AlphaText>
                        <AlphaText style={{ fontSize: 16 }}>{'\n' + answersPreview.ForFun3.answer}</AlphaText>
                    </View>
                </ScrollView>

                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    <ButtonPrimary onPress={() => setModalConfirmationVisible(false)} buttonText={'Edit'} />
                    <ButtonPrimary onPress={handleContinue} buttonText={'Approve'} />
                </View>
            </View>
        </View>
    )
}
