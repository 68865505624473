import React, { useState, useEffect } from 'react'
import { View, Image, Pressable, StyleSheet, Dimensions } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
// import backgroundArt from '../../assets/notButtons/backgroundArt.jpg'

import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from '../../Colors'

const { width, height } = Dimensions.get('window')

let HS = width / 320
let VS = height / 640
export default function ProfileEditingMenu({ navigation }) {
    function handlePhoto() {
        navigation.navigate('ProfilePhotosEditor')
    }
    function handleAnswer() {
        navigation.navigate('ProfileAnswersEditor')
    }
    function handleBack() {
        navigation.goBack()
    }

    return (
        <View style={styles.container}>
            <AlphaText
                style={{
                    textAlignVertical: 'center',
                    fontSize: 35,
                    color: COLORS.darkGray,
                    marginTop: 40 * VS,
                }}
            >
                Profile Editor
            </AlphaText>
            {/* ADD ART HERE ONCE YOU GET A SUITABLE IMAGE */}
            {/* <Image
                style={{
                    // tintColor: '#FFFFFF',
                    width: width,
                    height: width,
                }}
                source={backgroundArt}
            /> */}
            <Pressable
                onPress={handlePhoto}
                style={({ pressed }) => [styles.bigButton, { opacity: pressed ? 0.5 : 1 }]}
            >
                <AlphaText style={styles.bigButtonText}>Your Photos</AlphaText>
            </Pressable>
            <Pressable
                onPress={handleAnswer}
                style={({ pressed }) => [styles.bigButton, { opacity: pressed ? 0.5 : 1 }]}
            >
                <AlphaText style={styles.bigButtonText}>Your Answers</AlphaText>
            </Pressable>
            <View style={{ marginBottom: 20 * VS }}>
                <ButtonPrimary buttonText={'Back'} onPress={handleBack} />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    bigButton: {
        borderRadius: 15,
        width: 200 * HS,
        height: 70 * VS,
        marginVertical: 10,
        justifyContent: 'center',
        backgroundColor: COLORS.darkGray,
    },
    bigButtonText: {
        fontSize: 20,
        color: COLORS.white,
        padding: 20,
        borderRadius: 15,
        textAlign: 'center',
    },
    container: {
        flex: 1,
        backgroundColor: COLORS.white,
        alignItems: 'center',
        justifyContent: 'space-between',
        alignContent: 'center',
    },
})
