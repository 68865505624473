import React, { useState, useContext } from 'react'
import { View, Pressable, StyleSheet, TextInput } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { mandatory } from '../onboarding/AnswerQuestions'
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function ForFun({ questionNumber, questionsArray, answers, setAnswers }) {
    // questionName = "ForFun1" || "ForFun3" || "ForFun3"
    const { HS, VS } = useContext(ScreenSizeContext)

    const questionName = 'forFun' + String(questionNumber)
    const [expanded, setExpanded] = useState(false)

    const handlePress = () => {
        setExpanded(!expanded)
    }

    // this shows format of answers object
    // answers: { career1: "janitor", religion: "jewish", forFun1: { question: "What's your favorite chocolate?", answer: "All of them"}}

    const chosenQuestionText = answers[questionName]?.question || ''
    const selectQuestion = (value) => {
        setAnswers({ ...answers, [questionName]: { ...answers[questionName], question: value } })
        setExpanded(false)
    }

    const answerText = answers[questionName]?.answer || ''
    const handleChangeAnswer = (value) => {
        setAnswers({ ...answers, [questionName]: { ...answers[questionName], answer: value } })
    }

    return (
        <View style={{ marginVertical: 10 }}>
            <View style={{ marginHorizontal: 10 }}>
                <AlphaText style={styles.labelText}>{`Prompt ${questionNumber}`}</AlphaText>
            </View>
            <Pressable onPress={handlePress}>
                <View
                    style={[
                        styles.dropdownQuestionBox,
                        { borderWidth: mandatory.includes(questionName) ? 2 : 0, borderColor: COLORS.redButton },
                    ]}
                >
                    <AlphaText style={styles.questionText}>{chosenQuestionText}</AlphaText>
                    {expanded &&
                        questionsArray.map((question) => {
                            return (
                                <Pressable onPress={() => selectQuestion(question)} key={question}>
                                    <AlphaText style={styles.questionText}>{question}</AlphaText>
                                </Pressable>
                            )
                        })}
                    {expanded && chosenQuestionText !== '' && (
                        <Pressable onPress={() => selectQuestion('')}>
                            <AlphaText style={styles.questionText}>Remove Question</AlphaText>
                        </Pressable>
                    )}
                </View>
            </Pressable>
            <View style={{ marginHorizontal: 10, marginTop: 8 }}>
                <AlphaText style={styles.labelText}>{`Answer ${questionNumber}`}</AlphaText>
            </View>
            <View
                style={[
                    styles.typedAnswerBox,
                    { borderWidth: mandatory.includes(questionName) ? 2 : 0, borderColor: COLORS.redButton },
                ]}
            >
                <TextInput
                    style={{
                        color: COLORS.darkGray,
                        fontFamily: 'Medium',
                        fontSize: 14 * ((VS + HS) / 2) * 0.85, //if you update calculations on this line, need to update alphatext and other textinputs
                        marginTop: 5,
                        height: 80,
                        backgroundColor: COLORS.white,
                        borderRadius: 8,
                        paddingHorizontal: 7,
                    }}
                    multiline={true}
                    onChangeText={handleChangeAnswer}
                    value={answerText}
                    maxLength={240}
                />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    dropdownQuestionBox: {
        backgroundColor: COLORS.white,
        minHeight: 55,
        borderRadius: 8,
        padding: 15,
        flexDirection: 'column',
    },
    typedAnswerBox: {
        backgroundColor: COLORS.white,
        borderRadius: 8,
        paddingTop: 4,
        paddingBottom: 6,
        paddingHorizontal: 8,
        flexDirection: 'column',
        marginBottom: 10, //sets bottom gap between...
    },
    labelText: {
        color: COLORS.nearBlack,
        fontSize: 18,
    },
    questionText: {
        color: COLORS.darkGray,
        fontSize: 14,
    },
})
