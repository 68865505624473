import { Image, StyleSheet, View } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import HeightIcon from './../../assets/icons/HeightIcon.png'
import CakeIcon from './../../assets/icons/CakeIcon.png'
import { COLORS } from '../../Colors'
export default function PhotoSubHeader({ personsHeight, age }) {
    return (
        <View style={{ height: 60, flexDirection: 'row', alignItems: 'center' }}>
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: COLORS.lightGreen,
                    borderRadius: 40,
                    paddingVertical: 10,
                    paddingHorizontal: 17,
                }}
            >
                <Image style={styles.icon} source={CakeIcon} />
                <AlphaText style={styles.AlphaText}>{age}</AlphaText>
            </View>
            {personsHeight && (
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: COLORS.lightGreen,
                        borderRadius: 40,
                        paddingVertical: 10,
                        paddingHorizontal: 17,
                        marginLeft: 10,
                    }}
                >
                    <Image style={styles.icon} source={HeightIcon} />
                    <AlphaText style={styles.AlphaText}>{personsHeight}</AlphaText>
                </View>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    AlphaText: {
        fontSize: 16,
        paddingLeft: 10,
    },
    icon: {
        resizeMode: 'contain',
        width: 15,
        height: 15,
    },
})
