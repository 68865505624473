import React, { useContext } from 'react'
import { Text, StyleSheet } from 'react-native'
import { COLORS } from '../../Colors'
import { ScreenSizeContext } from './../../ScreenSizeContext'

const defaultFontSize = 20
const defaultColor = COLORS.nearBlack

export default function AlphaText({ style, ...props }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    style = StyleSheet.flatten(style) // Flatten the style array into an object - Because otherwise files break when they use something like this: <AlphaText style={[styles.buttonText1, { padding: 10 }]}>
    const fontSize = (style && style.fontSize) || defaultFontSize
    const color = (style && style.color) || defaultColor
    const scaledFontSize = fontSize * ((VS + HS) / 2) * 0.85 //if you update the calculations on this line, need to update textinputs
    const combinedStyle = { ...style, fontSize: scaledFontSize, fontFamily: 'Medium', color: color }

    return <Text {...props} style={combinedStyle} />
}
