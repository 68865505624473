import React, { useState } from 'react'
import { View, Image, ScrollView, Dimensions, Modal, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from './../../Colors'
import termsIcon from 'assets/buttons/termsIcon.png'
import privacyPolicyIcon from 'assets/buttons/privacyPolicyIcon.png'
import ModalTerms from '../ComponentsSettings/ModalTerms'
import ModalPrivacyPolicy from '../ComponentsSettings/ModalPrivacyPolicy'
import ModalLogout1 from '../ComponentsSettings/ModalLogout1'
import ModalLogout2 from '../ComponentsSettings/ModalLogout2'
import ModalDelete from '../ComponentsSettings/ModalDelete'
import ModalDeleteConfirmation from '../ComponentsSettings/ModalDeleteConfirmation'
import ModalDeletionSuccess from '../ComponentsSettings/ModalDeletionSuccess'
import ModalRules from '../ComponentsSettings/ModalRules'
import ModalTips from '../ComponentsSettings/ModalTips'
import { styles } from './../ComponentsSettings/StylesSettings'

const { width, height } = Dimensions.get('window')
let HS = width / 320
let VS = height / 640
export default function Settings({ navigation }) {
    const [modalLogout1, setModalLogout1] = useState(false)
    const [modalLogout2, setModalLogout2] = useState(false)
    const [modalRules, setModalRules] = useState(false)
    const [modalTips, setModalTips] = useState(false)
    const [modalTerms, setModalTerms] = useState(false)
    const [modalPrivacyPolicy, setModalPrivacyPolicy] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [modalDeleteConfirmation, setModalDeleteConfirmation] = useState(false)
    const [modalDeletionSuccess, setModalDeletionSuccess] = useState(false)

    function handleBack() {
        navigation.navigate('Thrive')
    }
    const DeletingAccount = function () {
        setModalDelete(false)
        setModalDeleteConfirmation(true)
    }

    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white }}>
            <Modal visible={modalTerms} animationType="fade" transparent={true}>
                <ModalTerms setModalTerms={setModalTerms} />
            </Modal>
            <Modal visible={modalPrivacyPolicy} animationType="fade" transparent={true}>
                <ModalPrivacyPolicy setModalPrivacyPolicy={setModalPrivacyPolicy} />
            </Modal>

            <Modal visible={modalLogout1} animationType="fade" transparent={true}>
                <ModalLogout1
                    setModalLogout1={setModalLogout1}
                    setModalLogout2={setModalLogout2}
                    modalLogout1={modalLogout1}
                    navigation={navigation}
                    demo={false}
                />
            </Modal>
            <Modal visible={modalLogout2} animationType="fade" transparent={true}>
                <ModalLogout2 setModalLogout2={setModalLogout2} demo={false} />
            </Modal>

            <Modal visible={modalDelete} animationType="fade" transparent={true}>
                <ModalDelete setModalDelete={setModalDelete} DeletingAccount={DeletingAccount} />
            </Modal>
            <Modal visible={modalDeleteConfirmation} animationType="fade" transparent={true}>
                <ModalDeleteConfirmation
                    setModalDeleteConfirmation={setModalDeleteConfirmation}
                    setModalDeletionSuccess={setModalDeletionSuccess}
                    demo={false}
                    navigation={navigation}
                />
            </Modal>
            <Modal visible={modalDeletionSuccess} animationType="fade" transparent={true}>
                <ModalDeletionSuccess setModalDeletionSuccess={setModalDeletionSuccess} demo={false} />
            </Modal>

            <Modal visible={modalRules} animationType="fade" transparent={true}>
                <ModalRules setModalRules={setModalRules} />
            </Modal>
            <Modal visible={modalTips} animationType="fade" transparent={true}>
                <ModalTips setModalTips={setModalTips} />
            </Modal>
            {/* END OF MODALS IS HERE******************************************************************************************************************************************** */}
            <View
                style={{
                    marginTop: 30,
                    marginBottom: 7 * VS * VS,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    backgroundColor: COLORS.white,
                }}
            >
                <Pressable onPress={() => setModalTerms(true)}>
                    <Image source={termsIcon} style={{ width: 20, height: 20, marginTop: 7, resizeMode: 'contain' }} />
                </Pressable>
                <AlphaText style={{ fontSize: 30, color: COLORS.darkGray, textAlign: 'center' }}>
                    Settings Menu
                </AlphaText>
                <Pressable onPress={() => setModalPrivacyPolicy(true)}>
                    <Image
                        source={privacyPolicyIcon}
                        style={{ width: 20, height: 30, marginTop: 7, resizeMode: 'contain' }}
                    />
                </Pressable>
            </View>
            <ScrollView style={{ backgroundColor: COLORS.white }}>
                <View style={{ alignItems: 'center', marginTop: 10 }}>
                    <Pressable
                        onPress={() => navigation.navigate('Feedback')}
                        style={({ pressed }) => [styles.pressable1, { opacity: pressed ? 0.5 : 1 }]}
                    >
                        <AlphaText style={[styles.buttonText1, { padding: 10 }]}>Give Us Feedback</AlphaText>
                    </Pressable>
                    <Pressable
                        onPress={() => setModalLogout1(true)}
                        style={({ pressed }) => [styles.pressable1, { opacity: pressed ? 0.5 : 1 }]}
                    >
                        <AlphaText style={[styles.buttonText1, { padding: 10 }]}>Sign Out</AlphaText>
                    </Pressable>
                    <Pressable
                        onPress={() => setModalDelete(true)}
                        style={({ pressed }) => [styles.pressable1, { opacity: pressed ? 0.5 : 1 }]}
                    >
                        <AlphaText style={[styles.buttonText1, { padding: 10 }]}>Delete Account</AlphaText>
                    </Pressable>
                    <Pressable
                        onPress={() => setModalRules(true)}
                        style={({ pressed }) => [styles.pressable1, { opacity: pressed ? 0.5 : 1 }]}
                    >
                        <AlphaText style={[styles.buttonText1, { padding: 10 }]}>View Instructions and Rules</AlphaText>
                    </Pressable>
                    <Pressable
                        onPress={() => setModalTips(true)}
                        style={({ pressed }) => [styles.pressable1, { opacity: pressed ? 0.5 : 1 }]}
                    >
                        <AlphaText style={[styles.buttonText1, { padding: 10 }]}>Tips and Tricks</AlphaText>
                    </Pressable>
                    <AlphaText
                        style={{
                            color: COLORS.nearBlack,
                            fontSize: 16,
                            padding: 10,
                            marginHorizontal: 30,
                            textAlign: 'center',
                        }}
                    >
                        Check back in this menu from time to time. We plan on adding additional useful information and
                        settings here in the near future.
                    </AlphaText>
                </View>
            </ScrollView>
            <View
                style={{
                    bottom: 0,
                    paddingBottom: 30,
                    alignItems: 'center',
                }}
            >
                <Pressable
                    onPress={handleBack}
                    style={({ pressed }) => [
                        {
                            marginTop: 20,
                            maxWidth: 200,
                            paddingHorizontal: 5,
                            borderRadius: 5,
                            opacity: pressed ? 0.5 : 1,
                            backgroundColor: COLORS.redButton,
                        },
                    ]}
                >
                    <AlphaText style={[styles.buttonText1, { padding: 10 }]}>Save Settings</AlphaText>
                </Pressable>
            </View>
        </SafeAreaView>
    )
}
