import React, { useState, useRef, useEffect } from 'react'
import { Pressable, ScrollView, StyleSheet, View, Modal, Dimensions } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'

import Swipable from '../ComponentsAnswers/Swipable'
import { SafeAreaView } from 'react-native-safe-area-context'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'
// import { StatusBar } from 'expo-status-bar'

import { COLORS } from '../../Colors'
import ModalConfirmation from '../ComponentsAnswers/ModalConfirmation'
import ModalReport from '../ComponentsShared/ModalReport'
import VotingFooter from '../ComponentsShared/VotingFooter'

const { width: screenWidth, height: screenHeight } = Dimensions.get('window')

// SEE PHOTOSDEMO SCREEN FOR MORE COMMENTS
export default function AnswersDemo({ navigation }) {
    const [person, setPerson] = useState(1)
    const [votes, setVotes] = useState({ 1: null, 2: null, 3: null, 4: null, 5: null, 6: null, 7: null })
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const [modalTutorial2, setModalTutorial2] = useState(true)
    const [modalTutorial3, setModalTutorial3] = useState(false)
    const [modalTutorial4, setModalTutorial4] = useState(false)
    const [modalTutorial5, setModalTutorial5] = useState(false)
    const [location2, setLocation2] = useState(null)
    const [location3, setLocation3] = useState(null)
    const [location4, setLocation4] = useState(null)
    const [location5, setLocation5] = useState(null)

    useEffect(() => {
        setLocation2({ top: screenHeight * 0.45, right: 0 })
        setLocation3({ top: screenHeight * 0.48 })
        setLocation4({ bottom: screenHeight / 8 })
        setLocation5({ top: screenHeight / 12 })
    }, [])

    const Tooltip2 = ({ text }) => {
        return (
            <View style={{ alignItems: 'center', alignSelf: 'flex-end', marginRight: 10 }}>
                <View style={{ padding: 10, borderRadius: 10, backgroundColor: COLORS.redButton }}>
                    <AlphaText style={{ fontSize: 16, color: COLORS.white, textAlign: 'center' }}> {text} </AlphaText>
                    <Pressable
                        onPress={() => {
                            logEvent(analytics, 'tutorial_modal_2_closed')
                            setModalTutorial2(false)
                            setModalTutorial3(true)
                        }}
                        style={({ pressed }) => [
                            {
                                justifyContent: 'center',
                                alignSelf: 'center',
                                borderRadius: 5,
                                padding: 10,
                                marginHorizontal: 10,
                                marginVertical: 10,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.white,
                            },
                        ]}
                    >
                        <AlphaText style={{ fontSize: 16, color: COLORS.nearBlack, textAlign: 'center' }}>
                            {'Got It'}
                        </AlphaText>
                    </Pressable>
                </View>
                <View style={styles.triangleDown} />
            </View>
        )
    }
    const Tooltip3 = ({ text }) => {
        return (
            <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                <View
                    style={{
                        flexDirection: 'column',
                        width: '100%',
                        padding: 10,
                        borderRadius: 10,
                        backgroundColor: COLORS.redButton,
                    }}
                >
                    <AlphaText style={{ fontSize: 16, color: COLORS.white, textAlign: 'center' }}> {text} </AlphaText>
                    <Pressable
                        onPress={() => {
                            logEvent(analytics, 'tutorial_modal_3_closed')
                            setModalTutorial3(false)
                            setModalTutorial4(true)
                        }}
                        style={({ pressed }) => [
                            {
                                justifyContent: 'center',
                                alignSelf: 'center',
                                borderRadius: 5,
                                padding: 10,
                                marginHorizontal: 10,
                                marginVertical: 10,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.white,
                            },
                        ]}
                    >
                        <AlphaText style={{ fontSize: 16, color: COLORS.nearBlack, textAlign: 'center' }}>
                            {'Got It'}
                        </AlphaText>
                    </Pressable>
                </View>
                <View style={styles.triangleRight} />
            </View>
        )
    }
    const Tooltip4 = ({ text }) => {
        return (
            <View style={{ alignItems: 'center' }}>
                <View
                    style={{ padding: 10, borderRadius: 10, backgroundColor: COLORS.redButton, marginHorizontal: 10 }}
                >
                    <AlphaText style={{ fontSize: 16, color: COLORS.white, textAlign: 'center' }}> {text} </AlphaText>
                    <Pressable
                        onPress={() => {
                            logEvent(analytics, 'tutorial_modal_4_closed')
                            setModalTutorial4(false)
                            setModalTutorial5(true)
                        }}
                        style={({ pressed }) => [
                            {
                                justifyContent: 'center',
                                alignSelf: 'center',
                                borderRadius: 5,
                                padding: 10,
                                marginHorizontal: 10,
                                marginVertical: 10,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.white,
                            },
                        ]}
                    >
                        <AlphaText style={{ fontSize: 16, color: COLORS.nearBlack, textAlign: 'center' }}>
                            {'Got It'}
                        </AlphaText>
                    </Pressable>
                </View>
                <View style={styles.triangleDown} />
            </View>
        )
    }
    const Tooltip5 = ({ text }) => {
        return (
            <View style={{ alignItems: 'center' }}>
                <View style={styles.triangleUp} />

                <View style={{ padding: 10, borderRadius: 10, backgroundColor: COLORS.redButton }}>
                    <AlphaText style={{ fontSize: 16, color: COLORS.white, textAlign: 'center' }}> {text} </AlphaText>
                    <Pressable
                        onPress={() => {
                            logEvent(analytics, 'tutorial_modal_5_closed')
                            setModalTutorial5(false)
                        }}
                        style={({ pressed }) => [
                            {
                                justifyContent: 'center',
                                alignSelf: 'center',
                                borderRadius: 5,
                                padding: 10,
                                marginHorizontal: 10,
                                marginVertical: 10,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.white,
                            },
                        ]}
                    >
                        <AlphaText style={{ fontSize: 16, color: COLORS.nearBlack, textAlign: 'center' }}>
                            {'Got It'}
                        </AlphaText>
                    </Pressable>
                </View>
            </View>
        )
    }
    const yesPressed = () => {
        setConfirmationModal(false)
        navigation.navigate('PhotosDemo')
    }

    const answers = {
        1: {
            Dealbreaker: `I'm a firefighter who travels`,
            Marriage: 'I probably want to get married, maybe five years from now',
            Kids: `I don't have any children yet, but I want to have two someday`,
            Relationship: 'blurb about relationship goes here',
            Religion: 'blurb about religion goes here',
            Career: 'blurb about career goes here',
            Astrology: 'blurb about astrology goes here',
            Politics: 'blurb about politics goes here',
            Sex: 'blurb about sex goes here',
            Alcohol: 'blurb about drugs goes here',
            Food: 'blurb about food goes here',
            Psychology: 'blurb about psychology goes here',
            Fun: 'For fun I go water skiing.\nKevin Hart is my favorite comedian.\nFriends describe me as too sexy for my shirt 🏄.',
            ForFun1: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun2: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun3: { question: 'My favorite dog is...', answer: 'Clifford' },
        },
        2: {
            Dealbreaker: 'This is my dealbreaker',
            Marriage: 'I want to get married to seven people in ten years time',
            Kids: 'blurb about kids goes here. And it goes on to take up several more lines, because...',
            Relationship: 'blurb about relationship goes here',
            Religion: 'blurb about religion goes here',
            Career: 'blurb about career goes here',
            Astrology: 'blurb about astrology goes here',
            Politics: 'blurb about politics goes here',
            Sex: 'blurb about sex goes here',
            Alcohol: 'blurb about drugs goes here',
            Food: 'blurb about food goes here',
            Psychology: 'blurb about psychology goes here',
            Fun: 'For fun I go water skiing.\nKevin Hart is my favorite comedian.\nFriends describe me as too sexy for my shirt 🏄.',
            ForFun1: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun2: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun3: { question: 'My favorite dog is...', answer: 'Clifford' },
        },
        3: {
            Dealbreaker: 'This is my dealbreaker',
            Marriage: 'I want to get married to seven people in ten years time',
            Kids: 'blurb about kids goes here. And it goes on to take up several more lines, because...',
            Relationship: 'blurb about relationship goes here',
            Religion: 'blurb about religion goes here',
            Career: 'blurb about career goes here',
            Astrology: 'blurb about astrology goes here',
            Politics: 'blurb about politics goes here',
            Sex: 'blurb about sex goes here',
            Alcohol: 'blurb about drugs goes here',
            Food: 'blurb about food goes here',
            Psychology: 'blurb about psychology goes here',
            Fun: 'For fun I go water skiing.\nKevin Hart is my favorite comedian.\nFriends describe me as too sexy for my shirt 🏄.',
            ForFun1: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun2: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun3: { question: 'My favorite dog is...', answer: 'Clifford' },
        },
        4: {
            Dealbreaker: 'This is my dealbreaker',
            Marriage: 'I want to get married to seven people in ten years time',
            Kids: 'blurb about kids goes here. And it goes on to take up several more lines, because...',
            Relationship: 'blurb about relationship goes here',
            Religion: 'blurb about religion goes here',
            Career: 'blurb about career goes here',
            Astrology: 'blurb about astrology goes here',
            Politics: 'blurb about politics goes here',
            Sex: 'blurb about sex goes here',
            Alcohol: 'blurb about drugs goes here',
            Food: 'blurb about food goes here',
            Psychology: 'blurb about psychology goes here',
            Fun: 'For fun I go water skiing.\nKevin Hart is my favorite comedian.\nFriends describe me as too sexy for my shirt 🏄.',
            ForFun1: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun2: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun3: { question: 'My favorite dog is...', answer: 'Clifford' },
        },
        5: {
            Dealbreaker: 'This is my dealbreaker',
            Marriage: 'I want to get married to seven people in ten years time',
            Kids: 'blurb about kids goes here. And it goes on to take up several more lines, because...',
            Relationship: 'blurb about relationship goes here',
            Religion: 'blurb about religion goes here',
            Career: 'blurb about career goes here',
            Astrology: 'blurb about astrology goes here',
            Politics: 'blurb about politics goes here',
            Sex: 'blurb about sex goes here',
            Alcohol: 'blurb about drugs goes here',
            Food: 'blurb about food goes here',
            Psychology: 'blurb about psychology goes here',
            Fun: 'For fun I go water skiing.\nKevin Hart is my favorite comedian.\nFriends describe me as too sexy for my shirt 🏄.',
            ForFun1: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun2: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun3: { question: 'My favorite dog is...', answer: 'Clifford' },
        },
        6: {
            Dealbreaker: 'This is my dealbreaker',
            Marriage: 'I want to get married to seven people in ten years time',
            Kids: 'blurb about kids goes here. And it goes on to take up several more lines, because...',
            Relationship: 'blurb about relationship goes here',
            Religion: 'blurb about religion goes here',
            Career: 'blurb about career goes here',
            Astrology: 'blurb about astrology goes here',
            Politics: 'blurb about politics goes here',
            Sex: 'blurb about sex goes here',
            Alcohol: 'blurb about drugs goes here',
            Food: 'blurb about food goes here',
            Psychology: 'blurb about psychology goes here',
            Fun: 'For fun I go water skiing.\nKevin Hart is my favorite comedian.\nFriends describe me as too sexy for my shirt 🏄.',
            ForFun1: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun2: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun3: { question: 'My favorite dog is...', answer: 'Clifford' },
        },
        7: {
            Dealbreaker: 'This is my dealbreaker',
            Marriage: 'I want to get married to seven people in ten years time',
            Kids: 'blurb about kids goes here. And it goes on to take up several more lines, because...',
            Relationship: 'blurb about relationship goes here',
            Religion: 'blurb about religion goes here',
            Career: 'blurb about career goes here',
            Astrology: 'blurb about astrology goes here',
            Politics: 'blurb about politics goes here',
            Sex: 'blurb about sex goes here',
            Alcohol: 'blurb about drugs goes here',
            Food: 'blurb about food goes here',
            Psychology: 'blurb about psychology goes here',
            Fun: 'For fun I go water skiing.\nKevin Hart is my favorite comedian.\nFriends describe me as too sexy for my shirt 🏄.',
            ForFun1: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun2: { question: 'My favorite dog is...', answer: 'Clifford' },
            ForFun3: { question: 'My favorite dog is...', answer: 'Clifford' },
        },
    } //to be loaded from firestore (for real voting, but probs not demo)

    return (
        <SafeAreaView style={styles.container}>
            {/* <StatusBar /> */}
            {/* I dont think any of this statusbar props does anything */}
            {/* <StatusBar translucent={false} backgroundColor={COLORS.white} style="dark" /> */}

            <Modal visible={reportModal} animationType="fade" transparent={true}>
                <ModalReport setReportModal={setReportModal} demo={true} uidOfProfile={null} screen={'answers'} />
            </Modal>
            <Modal visible={confirmationModal} animationType="fade" transparent={true}>
                <ModalConfirmation setConfirmationModal={setConfirmationModal} yesPressed={yesPressed} />
            </Modal>
            {modalTutorial2 && location2 && (
                <View style={[styles.overlay, { width: '80%' }, location2 && location2]}>
                    <Tooltip2 text={'Tap on a section to expand it...'} />
                </View>
            )}
            {modalTutorial3 && (
                <View style={[styles.overlay, { width: '80%' }, location3 && location3]}>
                    <Tooltip3 text={'...or tap the  ⌵  to expand or collapse all'} />
                </View>
            )}
            {modalTutorial4 && (
                <View style={[styles.overlay, { width: '100%' }, location4 && location4]}>
                    <Tooltip4
                        text={
                            'Vote "No", "Not Today", or "Yes" on all seven people to proceed.\n\nYou have to vote on all seven!\n\nWhen you vote it will automatically take you to the next person...'
                        }
                    />
                </View>
            )}
            {modalTutorial5 && (
                <View style={[styles.overlay, location5 && location5]}>
                    <Tooltip5
                        text={
                            '...or you can tap the number of any person to view their profile directly, or scroll sideways between people by dragging across the middle of the screen.'
                        }
                    />
                </View>
            )}
            <Swipable
                person={person}
                setPerson={setPerson}
                votes={votes}
                answers={answers}
                setReportModal={setReportModal}
            />

            <VotingFooter
                person={person}
                setPerson={setPerson}
                votes={votes}
                setVotes={setVotes}
                initialYesVotes={3}
                setConfirmationModal={setConfirmationModal}
                screen="AnswersDemo"
            />
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        backgroundColor: COLORS.white,
        flex: 1,
    },

    triangleDown: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 10, // change these to flip (also have to move view of triangle in ToolTip component definition)
        borderRightWidth: 10,
        borderBottomWidth: 0, // change these to flip
        borderLeftWidth: 10,
        borderTopColor: COLORS.redButton, //change these to flip
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent', //change these to flip
        borderLeftColor: 'transparent',
    },
    triangleUp: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0, // change these to flip (also have to move view of triangle in ToolTip component definition)
        borderRightWidth: 10,
        borderBottomWidth: 10, // change these to flip
        borderLeftWidth: 10,
        borderTopColor: 'transparent', //change these to flip
        borderRightColor: 'transparent',
        borderBottomColor: COLORS.redButton, //change these to flip
        borderLeftColor: 'transparent',
    },
    triangleRight: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 10,
        borderRightWidth: 0,
        borderBottomWidth: 10,
        borderLeftWidth: 10,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        borderLeftColor: COLORS.redButton,
    },
    overlay: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
    },
})
