import { StyleSheet } from 'react-native'
import { COLORS } from '../../Colors'

// spent maybe an hour trying to get HS and VS to work inside the stylesheet
// tried variable import/export, useContext, and global.variables with no success - UPDATE: Yeah, you can only use hooks inside functional components or inside another hook. You could create a hook to return the desired styles, but idk how performant that would be
// should fix at a later date so there's less repeated cruft in the six Modal files for DoHaveAccount
export const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.white,
        padding: 10,
        alignItems: 'left',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
        marginHorizontal: 15,
        borderRadius: 20,
    },
    largeText: {
        color: COLORS.nearBlack,
        textAlign: 'center',
    },
    smallText: {
        color: COLORS.nearBlack,
    },
    italicText: {
        fontStyle: 'italic',
        color: COLORS.nearBlack,
        textAlign: 'center',
    },
    buttonText: {
        color: COLORS.white,
        textAlign: 'center',
    },
    button: {
        backgroundColor: COLORS.redButton,
    },
    iconTint: {
        tintColor: '#000000',
    },
})
