import React, { useState, useEffect } from 'react'
import { Dimensions, Image, Modal, Pressable, StyleSheet, View } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import 'firebase/auth'

import { COLORS } from './../../Colors'
import { isMobileOnly, isMobileSafari, isIOS, isFirefox, isChrome } from 'react-device-detect'
import ModalDestopWarning from '../ComponentsDoHaveAccount/ModalDesktopWarning'
import ModalSafariInstall from '../ComponentsDoHaveAccount/ModalSafariInstall'
import ModalNotSafariInstall from '../ComponentsDoHaveAccount/ModalNotSafariInstall'
import ModalAndroidFirefoxInstall from '../ComponentsDoHaveAccount/ModalAndroidFirefoxInstall'
import ModalAndroidOtherInstall from '../ComponentsDoHaveAccount/ModalAndroidOtherInstall'
import ModalAndroidChromeInstall from '../ComponentsDoHaveAccount/ModalAndroidChromeInstall'

const { width, height } = Dimensions.get('window')

let HS = width / 320
let VS = height / 640

export default function DoHaveAccount({ navigation }) {
    const [modalDesktopWarning, setModalDesktopWarning] = useState(false)
    const [modalSafariInstall, setModalSafariInstall] = useState(false)
    const [modalNotSafariInstall, setModalNotSafariInstall] = useState(false)
    const [modalAndroidFirefoxInstall, setModalAndroidFirefoxInstall] = useState(false)
    const [modalAndroidChromeInstall, setModalAndroidChromeInstall] = useState(false)
    const [modalAndroidOtherInstall, setModalAndroidOtherInstall] = useState(false)
    const [displayMessageTesting, setDisplayMessageTesting] = useState('default')

    function handleYes() {
        navigation.navigate('SignIn')
    }
    function handleNo() {
        navigation.navigate('DemoOrCreate')
    }

    useEffect(() => {
        console.log('SUCCESS')
        if (isMobileOnly == false) {
            setModalDesktopWarning(true)
        }
        // if (width > 500) {
        //     setModalDesktopWarning(true); OBSOLETE SINCE ADDED REACT-DEVICE-DETECT
        // }
        /////////////////////////PUT THIS CHUNK HERE and at the CREATE ACCOUNT SCREEN AND SOMEWHERE ELSE///////////////////////////////////////////
        else if (window.matchMedia('(display-mode: standalone)').matches) {
            setDisplayMessageTesting(displayMessageTesting + ' standalone')
            //don't display "download PWA" modal since they're already using PWA
        } else if (isIOS) {
            if (isMobileSafari) {
                setModalSafariInstall(true)
            } else {
                setModalNotSafariInstall(true)
            }
        } else if (isChrome) {
            setDisplayMessageTesting(displayMessageTesting + ' chromeinstall')
            setModalAndroidChromeInstall(true)
        } else if (isFirefox) {
            setDisplayMessageTesting(displayMessageTesting + ' Firefox install or installed')
            setModalAndroidFirefoxInstall(true)
        } else {
            setDisplayMessageTesting(displayMessageTesting + ' other browser install')
            setModalAndroidOtherInstall(true)
        }
    }, [])

    return (
        <View style={{ display: 'flex', flex: 1, backgroundColor: COLORS.lightGreen, justifyContent: 'center' }}>
            <Modal visible={modalDesktopWarning} animationType="fade" transparent={true}>
                <ModalDestopWarning setModalDesktopWarning={setModalDesktopWarning} />
            </Modal>
            <Modal visible={modalAndroidChromeInstall} animationType="fade" transparent={true}>
                <ModalAndroidChromeInstall setModalAndroidChromeInstall={setModalAndroidChromeInstall} />
            </Modal>
            <Modal visible={modalAndroidFirefoxInstall} animationType="fade" transparent={true}>
                <ModalAndroidFirefoxInstall setModalAndroidFirefoxInstall={setModalAndroidFirefoxInstall} />
            </Modal>
            <Modal visible={modalAndroidOtherInstall} animationType="fade" transparent={true}>
                <ModalAndroidOtherInstall setModalAndroidOtherInstall={setModalAndroidOtherInstall} />
            </Modal>
            <Modal visible={modalNotSafariInstall} animationType="fade" transparent={true}>
                <ModalNotSafariInstall setModalNotSafariInstall={setModalNotSafariInstall} />
            </Modal>
            <Modal visible={modalSafariInstall} animationType="fade" transparent={true}>
                <ModalSafariInstall setModalSafariInstall={setModalSafariInstall} />
            </Modal>
            <View style={styles.mainContainer}>
                <View
                    style={{
                        flex: 1,
                        marginBottom: '40%',
                        backgroundColor: COLORS.lightGreen,
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignSelf: 'center',
                    }}
                >
                    <AlphaText
                        style={{
                            color: COLORS.nearBlack,
                            marginVertical: 20,
                            fontSize: 40,
                            textAlign: 'center',
                        }}
                    >
                        Do you have an account?
                    </AlphaText>
                    <View>
                        <Pressable
                            onPress={handleNo}
                            style={({ pressed }) => [styles.pressable, { opacity: pressed ? 0.5 : 1 }]}
                        >
                            <AlphaText style={styles.buttonText}>No, I don't have an account yet</AlphaText>
                        </Pressable>
                        <Pressable
                            onPress={handleYes}
                            style={({ pressed }) => [styles.pressable, { opacity: pressed ? 0.5 : 1 }]}
                        >
                            <AlphaText style={styles.buttonText}>Yes, I already have an account</AlphaText>
                        </Pressable>
                    </View>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    buttonText: {
        fontSize: 20,
        color: COLORS.nearBlack,
        textAlign: 'center',
    },

    mainContainer: {
        backgroundColor: COLORS.lightGreen,
        marginBottom: 50 * VS,
        alignItems: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },
    pressable: {
        justifyContent: 'center',
        borderRadius: 10 * HS,
        padding: 15,
        marginTop: 20,
        backgroundColor: COLORS.white,
    },
})
