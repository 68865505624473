import React, { useContext } from 'react'
import { View, Pressable, ScrollView, StyleSheet, Image } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { styles } from './StylesDoHaveAccount'
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function modalAndroidOtherInstall({ setModalAndroidOtherInstall }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    return (
        <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
            <View
                style={[
                    styles.container,
                    {
                        marginTop: 20 * VS * VS,
                        marginBottom: 20 * VS * VS,
                    },
                ]}
            >
                <AlphaText style={[styles.largeText, { fontSize: 30 }]}>Install App for Full Features</AlphaText>
                <AlphaText
                    style={[
                        styles.smallText,
                        {
                            fontSize: 16,
                            padding: 5 * HS,
                            textAlign: 'left',
                            marginTop: 20,
                        },
                    ]}
                >
                    {'1. Tap the three dots or lines in your browser'}
                </AlphaText>
                <AlphaText
                    style={[
                        styles.smallText,
                        {
                            fontSize: 16,
                            padding: 5 * HS,
                            textAlign: 'left',
                        },
                    ]}
                >
                    {'2. Tap "Add Page to Home screen" or "Install" or something similar'}
                </AlphaText>
                <AlphaText
                    style={[
                        styles.smallText,
                        {
                            fontSize: 16 * HS,
                            padding: 5 * HS,
                            textAlign: 'center',
                        },
                    ]}
                >
                    {
                        '(Your browser has been detected as something other than chrome or firefox running on android. It should support PWA installation, but may not have been tested by our team. Private tabs may inhibit installation.)'
                    }
                </AlphaText>
                <AlphaText style={[styles.italicText, { fontSize: 13, padding: 5 * HS }]}>
                    {'\nYou can try the demo without installing, it may just be less responsive'}
                </AlphaText>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Pressable
                        onPress={() => setModalAndroidOtherInstall(false)}
                        style={({ pressed }) => [
                            styles.button,
                            {
                                borderRadius: 7 * VS,
                                paddingHorizontal: 15 * HS,
                                paddingVertical: 10 * HS,
                                marginTop: 25 * VS,
                                opacity: pressed ? 0.5 : 1,
                            },
                        ]}
                    >
                        <AlphaText style={[styles.buttonText, { fontSize: 16 }]}>I understand</AlphaText>
                    </Pressable>
                </View>
            </View>
        </View>
    )
}
