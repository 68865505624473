import { View, Pressable, ScrollView, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { styles } from './StylesSettings'

export default function ModalRules({ setModalRules }) {
    return (
        <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
            <View
                style={{
                    backgroundColor: COLORS.modalColor,
                    padding: 20,
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    flex: 1,
                    marginHorizontal: 20,
                    marginTop: 75,
                    marginBottom: 75,
                    borderRadius: 10,
                }}
            >
                <ScrollView>
                    <AlphaText style={styles.modalTitle}>How Selection Works</AlphaText>
                    <AlphaText style={styles.modalText1}>
                        {
                            "VOTING\n\n• Vote once daily (resets at 4AM CST)\n\n• Vote on a set of seven people\n\n\nVOTING ON TEXT PROFILES\n\n• Your 'yes' votes are limited\n\n• The X blacklists irreversibly\n\n• Vote on all seven to proceed\n\n\nVOTING ON PHOTO PROFILES\n\n• Vote on one person to move to next\n\n• Person order is randomized\n\n• First image will always be their face\n\n• Second image will always be their body"
                        }
                    </AlphaText>
                    <AlphaText style={styles.modalText1}>
                        {
                            "\nRULES FOR PROFILES\n\nPlease report rules violations!\n\n• Do not promote social media in any way. Any links to instagram, snapchat, onlyfans, etcetera, will result in an immediate ban. No links to social media or any other websites, no urls, no handles, etc.\n\n• No hate speech/symbols\n\n• No below-the-belt nudity\n\nNot really a rule, but please be kind to other users. We're all doing our best.\n\nPlease give us feedback so we can improve this app.\n\nWe hope you enjoy Thrive!!!"
                        }
                    </AlphaText>
                </ScrollView>
                <View
                    style={{
                        alignItems: 'center',
                    }}
                >
                    <Pressable
                        onPress={() => setModalRules(false)}
                        style={({ pressed }) => [
                            {
                                borderRadius: 5,
                                padding: 10,
                                marginTop: 15,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.redButton,
                            },
                        ]}
                    >
                        <AlphaText style={styles.buttonText1}>Back</AlphaText>
                    </Pressable>
                </View>
            </View>
        </View>
    )
}
