import React, { useState, useEffect, useCallback, useContext } from 'react'
import { Dimensions, Pressable, FlatList, StyleSheet, View, Image, TextInput } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from '../../Colors'
import sendArrow from 'assets/buttons/sendArrow.png'
import { MaterialIcons, AntDesign } from '@expo/vector-icons'
import { ScreenSizeContext } from './../../ScreenSizeContext'

// ATTENTION: THE UI IS BROKEN IN FIREFOX ON PC, BUT SEEMS TO WORK OKAY ELSEWHERE (NEED TO DO THOROUGH MOBILE TESTING)

const DATA = []
export default function MessagingDemo({ navigation }) {
    const { HS, VS } = useContext(ScreenSizeContext)
    const [selectedId, setSelectedId] = useState(null)
    const [newText, onChangeText] = useState([])
    const [msgCount, setMsgCount] = useState(0) //for rendering bot's messages
    const [userMsgCount, setUserMsgCount] = useState(0) //for keeping track of which bot response to send

    function backButton() {
        navigation.navigate('HomeDemo')
    }

    useEffect(() => {
        const initialMessage = setTimeout(() => {
            DATA.unshift({ id: 'bot' + Date.now(), text: 'Hey cutie!' })
            setMsgCount((msgCount) => msgCount + 1)
        }, 500)
        const secondMessage = setTimeout(() => {
            DATA.unshift({ id: 'bot' + Date.now(), text: "I'm Botty Beep, the world's dumbest chatbot" })
            setMsgCount((msgCount) => msgCount + 1)
        }, 1600)
        const thirdMessage = setTimeout(() => {
            DATA.unshift({
                id: 'bot' + Date.now(),
                text: 'If you create an account then you can chat here with real men',
            })
            setMsgCount((msgCount) => msgCount + 1)
        }, 4800)
        const fourthMessage = setTimeout(() => {
            DATA.unshift({ id: 'bot' + Date.now(), text: 'or women!!!' })
            setMsgCount((msgCount) => msgCount + 1)
        }, 7800)
        const fifthMessage = setTimeout(() => {
            DATA.unshift({
                id: 'bot' + Date.now(),
                text: "tbh, idk who you're attracted to since you haven't created an account yet",
            })
            setMsgCount((msgCount) => msgCount + 1)
        }, 9000)
        return () => {
            clearTimeout(initialMessage)
            clearTimeout(secondMessage)
            clearTimeout(thirdMessage)
            clearTimeout(fourthMessage)
            clearTimeout(fifthMessage)
        }
    }, [])

    const responses = [
        "well that's all I have to say for now. Did I mention I'm not very sophisticated?",
        'Why not head back to the home screen?',
        'please?',
        "I'm sorry Dave, I'm afraid I can't do that",
        'Achievement Unlocked!',
    ]
    const handleBotResponse = () => {
        const standardTimeout = setTimeout(() => {
            if (userMsgCount < 5) {
                DATA.unshift({ id: 'bot' + Date.now(), text: responses[userMsgCount] })
                setMsgCount((msgCount) => msgCount + 1)
            }
        }, 400)
        return () => {
            clearTimeout(standardTimeout)
        }
    }

    const sendPressed = () => {
        if (newText !== '') {
            DATA.unshift({
                id: 'you' + Date.now(),
                text: newText,
            })
            onChangeText('')
            setUserMsgCount((userMsgCount) => userMsgCount + 1)
            handleBotResponse()
            setTimeout(() => {
                flatListRef.current.scrollToIndex({ index: 0, animated: true })
            }, 0)
        }
    }

    const flatListRef = React.useRef(null)

    const renderItem = ({ item }) => {
        const bot = item.id.includes('bot')
        const backgroundColor = bot ? COLORS.lightRed : COLORS.lightGray
        const color = bot ? COLORS.nearBlack : COLORS.nearBlack
        const alignSelf = bot ? 'flex-start' : 'flex-end'

        return (
            <Pressable
                onPress={() => setSelectedId(item.id)}
                style={[styles.textBubble, { backgroundColor: backgroundColor, alignSelf: alignSelf }]}
            >
                <AlphaText style={[styles.text, { color: color }]}>{item.text}</AlphaText>
            </Pressable>
        )
    }

    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white, justifyContent: 'flex-end' }}>
            <View
                //setting margin to auto sorta overrides flexbox
                style={{ flex: 1, flexDirection: 'row', zIndex: 999, position: 'absolute', top: 20, left: 20 }}
            >
                <Pressable
                    onPress={() => backButton()}
                    style={({ pressed }) => [
                        {
                            opacity: pressed ? 0.5 : 1,
                            backgroundColor: COLORS.white,
                            borderRadius: 25,
                            paddingVertical: 10,
                            paddingRight: 7,
                            paddingStart: 3,
                        },
                    ]}
                >
                    <AntDesign name="left" size={40} color={COLORS.redButton} />
                </Pressable>
            </View>

            <View style={{ flex: 1, zIndex: 0 }}>
                <FlatList
                    contentContainerStyle={{ flexGrow: 1 }}
                    ref={flatListRef}
                    data={DATA}
                    renderItem={renderItem}
                    keyExtractor={(item) => item.id}
                    extraData={selectedId}
                    inverted
                />
                <View style={{ flexDirection: 'row', borderTopWidth: 1, marginTop: 10 }}>
                    <TextInput
                        placeholder="Type Message"
                        placeholderTextColor={COLORS.darkGray}
                        style={{
                            padding: 20,
                            fontFamily: 'Medium',
                            flex: 1,
                            fontSize: 18 * ((VS + HS) / 2) * 0.85, //if you update the calculations on this line, need to update alphatext and other textinputs
                        }}
                        onChangeText={onChangeText}
                        value={newText}
                        multiline={true}
                    />
                    <Pressable
                        onPress={sendPressed}
                        style={({ pressed }) => [
                            {
                                backgroundColor: COLORS.redButton,
                                marginLeft: 10,
                                marginRight: 20,
                                alignSelf: 'center',
                                borderRadius: 100,
                                paddingRight: 8,
                                paddingLeft: 15,
                                paddingVertical: 14,
                                opacity: pressed ? 0.5 : 1,
                            },
                        ]}
                    >
                        <Image source={sendArrow} style={{ resizeMode: 'contain', width: 30, height: 25 }} />
                    </Pressable>
                </View>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // marginTop: StatusBar.currentHeight || 0,
    },
    textBubble: {
        borderRadius: 10,
        padding: 10,
        marginVertical: 2,
        marginHorizontal: 16,
        maxWidth: '80%',
    },
    text: {
        fontSize: 18,
    },
})
