import { StyleSheet, Dimensions } from 'react-native'
import { COLORS } from '../../Colors'

export const styles = StyleSheet.create({
    modalTitle: {
        color: COLORS.nearBlack,
        fontSize: 30,
        textAlign: 'center',
    },
    modalText1: {
        color: COLORS.nearBlack,
        fontSize: 14,
        textAlign: 'left',
        paddingTop: 25,
    },
    termsHeading: {
        color: COLORS.nearBlack,
        fontSize: 16,
        textAlign: 'left',
        paddingTop: 10,
        paddingHorizontal: 5,
    },
    termsSubHeading: {
        color: COLORS.nearBlack,
        fontSize: 20,
        textAlign: 'left',
        paddingTop: 25,
        paddingHorizontal: 5,
        textAlign: 'center',
    },
    privacySubHeading: {
        color: COLORS.nearBlack,
        fontSize: 20,
        textAlign: 'left',
        paddingTop: 25,
    },
    middleText: {
        color: COLORS.nearBlack,
        fontSize: 18,
        textAlign: 'center',
        paddingTop: 10,
    },

    buttonText1: {
        color: COLORS.white,
        fontSize: 18,
        textAlign: 'center',
    },
    pressable1: {
        justifyContent: 'center',
        paddingHorizontal: 5,
        borderRadius: 5,
        marginVertical: 10,
        backgroundColor: COLORS.darkGray,
    },
})
