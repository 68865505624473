import { View } from "react-native";
import AlphaText from "../ComponentsShared/TextAlpha";
import { COLORS } from "../../Colors";
export default function PhotosHeader({ name, number }) {
    return (
        <View style={{
            height: 60, flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingHorizontal: 20, backgroundColor: COLORS.white
        }}>
            <AlphaText style={{ fontSize: 30 }}>{name}</AlphaText>
            <AlphaText style={{ fontSize: 30 }}>{number}</AlphaText>
        </View>
    );
}
