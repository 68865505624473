import { useEffect, useState } from 'react'
import { View } from 'react-native'
import AlphaText from './TextAlpha'
import VotingButton from './VotingButton'
import noButtonImage from 'assets/buttons/NoButton.png'
import skipButtonImage from 'assets/buttons/PauseButton.png'
import yesButtonImage from 'assets/buttons/YesButton.png'
import { COLORS } from '../../Colors'
import { useNavigation } from '@react-navigation/native'

export default function VotingFooter({
    person,
    setPerson,
    votes,
    setVotes,
    setConfirmationModal,
    screen,
    initialYesVotes,
    confirmAndProceed,
}) {
    const navigation = useNavigation()

    const handlePressed = (vote) => {
        setVotes({ ...votes, [person]: vote })
    }

    const [remainingVotes, setRemainingVotes] = useState(initialYesVotes)
    const [yesDisabled, setYesDisabled] = useState(false)

    useEffect(() => {
        if (remainingVotes === 0) {
            setYesDisabled(true)
        } else {
            setYesDisabled(false)
        }
    }, [remainingVotes])

    useEffect(() => {
        //triggered by voting, (waits for new votes state)
        let yesCount = 0
        Object.values(votes).forEach((vote) => {
            //counts yes votes used to set remaining
            if (vote === 'yes') {
                yesCount++
            }
        })

        setRemainingVotes(initialYesVotes - yesCount)
        if (Object.values(votes).includes(null)) {
            //if there's a person they haven't voted on
            let votesArray = Object.keys(votes).map((key) => [Number(key), votes[key]]) //convert obj to array ordered by person numbers
            for (let i = 0; i < votesArray.length; i++) {
                //finds first person who haven't voted on and makes them active
                if (votesArray[i].includes(null)) {
                    setPerson(votesArray[i][0])
                    break
                }
            }
        } else {
            if (screen === 'AnswersDemo') {
                setConfirmationModal(true)
            }
            if (screen === 'PhotosDemo') {
                navigation.navigate('DoneVotingDemo')
            }
            if (screen === 'AnswersVoting') {
                setConfirmationModal(true)
            }
            if (screen === 'PhotosVoting') {
                confirmAndProceed()
            }
        }
    }, [votes])

    return (
        // removing background color from view components disables the shadow on android. it requires both elevation and background color
        // after shadow library implementation this above comment will become obsolete.
        <View
            style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: COLORS.white,
            }}
        >
            <VotingButton source={noButtonImage} press={() => handlePressed('no')} />
            <VotingButton source={skipButtonImage} press={() => handlePressed('skip')} />
            <View>
                <VotingButton source={yesButtonImage} press={() => handlePressed('yes')} disabled={yesDisabled} />
                <View
                    pointerEvents={'none'}
                    style={{
                        alignSelf: 'flex-end',
                        position: 'absolute',
                        backgroundColor: COLORS.darkGray,
                        borderRadius: 20,
                        marginTop: 5,
                        marginRight: 5,
                    }}
                >
                    <AlphaText
                        style={{
                            paddingHorizontal: 10,
                            paddingVertical: 3,
                            color: COLORS.white,
                            fontSize: 15,
                        }}
                    >
                        {remainingVotes}
                    </AlphaText>
                </View>
            </View>
        </View>
    )
}
