import React, { useContext } from 'react'
import { View, Pressable, ScrollView, StyleSheet, Image, Text } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
import { COLORS } from '../../Colors'
// import { styles } from "./StylesDoHaveAccount" //styles for modalS?????
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function ModalIntro2({ setModalIntro2Visible }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    return (
        <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
            <View
                style={{
                    backgroundColor: COLORS.lightGreen,
                    padding: 30 * HS,
                    margin: 20,
                    borderRadius: 10,
                    alignItems: 'center',
                }}
            >
                <AlphaText style={{ fontSize: 18, textAlign: 'center' }}>
                    You will only see other's answers to questions that you have answered yourself. So answer as many as
                    possible or other people's data will be blurred out for some questions.
                </AlphaText>
                <AlphaText style={{ fontSize: 18, textAlign: 'center' }}>
                    {'\nPlease be descriptive and answer honestly!\n\n'}
                </AlphaText>
                <AlphaText style={{ fontSize: 18, textAlign: 'center' }}>
                    There are all kinds of people out there. If you want to find someone who likes you for who you
                    really are, you have to be honest about who you really are.
                </AlphaText>

                <ButtonPrimary buttonText={'Go to Questions'} onPress={() => setModalIntro2Visible(false)} />
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    pressable: {
        borderRadius: 7,
        padding: 10,
        height: 50,
        marginHorizontal: 10,
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
