export const COLORS = {
    greenButton: '#06B879', //unused
    yellowButton: '#E7D80C', //unused
    redButton: '#DB1D4E', //used a bunch of places, should probably rename since not just for buttons
    lightRed: '#F6BCCB', //lighter version of redButton. same hue and saturation but more white
    lightBlue: '#e8f6f7',
    darkBlue: '#689095',
    lightGreen: '#E7EEEE', //background color for homescreen, etc. See modalColor below when updating
    lightPink: '#F2E3E7',
    // white: '#FFFFFF', // TEMPORARILY CHANGED (DUH)
    white: 'white',
    lightGray: 'gainsboro',
    darkGray: '#868B8B',
    nearBlack: '#213131',
    modalColor: '#E7EEEE', //Keep this the same as lightGreen, I guess
    modalSurroundings: 'rgba(134,139,139,0.85)', //darkens everything except modal, this color is based off COLORS.darkGray with an alpha channel applied.
    lightModalSurroundings: 'rgba(134,139,139,0.4)',
}
