import React, { useState, useEffect, useRef } from 'react'
import { Animated, Dimensions, Image, ScrollView, StyleSheet, View, Pressable, Modal } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import VotingFooter from '../ComponentsShared/VotingFooter'
import PhotoHeader from '../ComponentsPhotos/PhotosHeader'
import PhotoSubHeader from '../ComponentsPhotos/PhotosSubHeader'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { LinearGradient } from 'expo-linear-gradient'
import ModalReport from '../ComponentsShared/ModalReport'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'
// import { Shadow } from "react-native-shadow-2"; leaving this here as breadcrumb I guess for a while

const { width: screenWidth, height: screenHeight } = Dimensions.get('window')
const imageBorderWidth = 5
const displayedWidth = screenWidth - 2 * imageBorderWidth

const data = {
    //loaded from database when day's matches are fetched
    //or just this data for the demo screen
    1: {
        name: 'Alex',
        age: '32',
        height: `5'6"`,
    },
    2: {
        name: 'Jamie',
        age: '24',
        height: `5'11"`,
    },
    3: {
        name: 'Old-Shoe',
        age: '26',
        height: `6'1"`,
    },
    4: {
        name: 'Avery',
        age: '27',
        height: `6'3"`,
    },
    5: {
        name: 'Morgan',
        age: '20',
        height: `5'9"`,
    },
    6: {
        name: 'Jessie',
        age: '18',
        height: `5'8"`,
    },
    7: {
        name: 'Dakota',
        age: '25',
        height: `5'8"`,
    },
}
const imageData = {
    1: {
        face: require('images/demo/temp1.jpg'),
        body: require('images/demo/temp2.jpg'),
        life1: require('images/demo/cc3.jpg'),
        // life2: require("images/demo/cc4.jpg"),
        // life3: require("images/demo/cc5.jpg"),
    },
    2: {
        face: require('images/demo/p1.jpg'),
        body: require('images/demo/p2.jpg'),
        life1: require('images/demo/p3.jpg'),
        // life2: require("images/demo/p4.jpg"),
        // life3: require("images/demo/p5.jpg"),
    },
    3: {
        face: require('images/demo/os1.jpg'),
        body: require('images/demo/os2.jpg'),
        life1: require('images/demo/os3.jpg'),
        // life2: require("images/demo/os4.jpg"),
        // life3: require("images/demo/os5.jpg"),
    },
    4: {
        face: require('images/demo/ic1.jpg'),
        body: require('images/demo/ic2.jpg'),
        life1: require('images/demo/ic3.jpg'),
        // life2: require("images/demo/ic4.jpg"),
        // life3: require("images/demo/ic5.jpg"),
    },
    5: {
        face: require('images/demo/st1.jpg'),
        body: require('images/demo/st2.jpg'),
        life1: require('images/demo/st3.jpg'),
        // life2: require("images/demo/st4.jpg"),
        // life3: require("images/demo/st5.jpg"),
    },
    6: {
        face: require('images/demo/su1.jpg'),
        body: require('images/demo/su2.jpg'),
        life1: require('images/demo/su3.jpg'),
        // life2: require("images/demo/su4.jpg"),
        // life3: require("images/demo/su5.jpg"),
    },
    7: {
        face: require('images/demo/sa1.jpg'),
        body: require('images/demo/sa2.jpg'),
        life1: require('images/demo/sa3.jpg'),
        // life2: require("images/demo/sa4.jpg"),
        // life3: require("images/demo/sa5.jpg"),
    },
}

export default function PhotosDemo({ route, navigation }) {
    const [person, setPerson] = useState(1)
    const [votes, setVotes] = useState({ 1: null, 2: null, 3: null, 4: null, 5: null, 6: null, 7: null })
    const [reportModal, setReportModal] = useState(false)
    const [modalTutorial6, setModalTutorial6] = useState(true)
    const [modalTutorial7, setModalTutorial7] = useState(false)
    const [location6, setLocation6] = useState(null)
    const [displayedHeights, setDisplayedHeights] = useState({})

    useEffect(() => {
        setLocation6({ top: screenHeight / 15 })
    }, [])
    const Tooltip6 = ({ text }) => {
        return (
            <View style={{ marginLeft: 20, alignItems: 'flex-end' }}>
                <View style={{ marginRight: 15 }}>
                    <View style={styles.triangleUp} />
                </View>
                <View style={{ padding: 10, borderRadius: 10, backgroundColor: COLORS.redButton }}>
                    <AlphaText style={{ fontSize: 16, color: COLORS.white, textAlign: 'center' }}> {text} </AlphaText>
                    <Pressable
                        onPress={() => {
                            logEvent(analytics, 'tutorial_modal_6_closed')
                            setModalTutorial6(false)
                            setModalTutorial7(true)
                        }}
                        style={({ pressed }) => [
                            {
                                justifyContent: 'center',
                                alignSelf: 'center',
                                borderRadius: 5,
                                padding: 10,
                                marginHorizontal: 10,
                                marginVertical: 10,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.white,
                            },
                        ]}
                    >
                        <AlphaText style={{ fontSize: 16, color: COLORS.nearBlack, textAlign: 'center' }}>
                            {'Got It'}
                        </AlphaText>
                    </Pressable>
                </View>
            </View>
        )
    }
    const Tooltip7 = ({ text }) => {
        return (
            <View
                style={{
                    padding: 10,
                    borderRadius: 10,
                    backgroundColor: COLORS.redButton,
                    marginHorizontal: 20,
                }}
            >
                <AlphaText style={{ fontSize: 16, color: COLORS.white, textAlign: 'center' }}> {text} </AlphaText>
                <Pressable
                    onPress={() => {
                        logEvent(analytics, 'tutorial_modal_7_closed')
                        setModalTutorial7(false)
                    }}
                    style={({ pressed }) => [
                        {
                            justifyContent: 'center',
                            alignSelf: 'center',
                            borderRadius: 5,
                            padding: 10,
                            marginHorizontal: 10,
                            marginVertical: 10,
                            opacity: pressed ? 0.5 : 1,
                            backgroundColor: COLORS.white,
                        },
                    ]}
                >
                    <AlphaText style={{ fontSize: 16, color: COLORS.nearBlack, textAlign: 'center' }}>
                        {'Got It'}
                    </AlphaText>
                </Pressable>
            </View>
        )
    }

    // REACT NATIVE CAN'T AUTOHEIGHT THE IMAGES BASED ON WIDTH AND ASPECT RATIO (neither can expo-image), SO WE HAVE TO DO IT OURSELVES
    useEffect(() => {
        const fetchImageSizes = async () => {
            let allPersonsHeights = { ...displayedHeights }
            for (const personKey in imageData) {
                for (const [key, imageUrl] of Object.entries(imageData[personKey] || {})) {
                    const imageHeight = await getImageSize(imageUrl)
                    // Ensure a sub-object for this person exists
                    if (!allPersonsHeights[personKey]) {
                        allPersonsHeights[personKey] = {}
                    }
                    allPersonsHeights[personKey][key] = imageHeight
                }
                setDisplayedHeights(allPersonsHeights) // moved this inside loop so that it updates first person sooner, but now it's updating state 7 times instead of 1
            }
        }

        const getImageSize = (imageUrl) => {
            return new Promise((resolve, reject) => {
                Image.getSize(
                    imageUrl,
                    (imageWidth, imageHeight) => {
                        const scaleFactor = imageWidth / displayedWidth
                        const calculatedDisplayedHeight = imageHeight / scaleFactor
                        resolve(calculatedDisplayedHeight) // just resolve with the height
                    },
                    reject
                )
            })
        }
        fetchImageSizes()
    }, [])

    //STILL WANT TO REDUCE IMAGE SIZE TO ONLY AS LARGE AS REQUIRED WHEN UPLOADING

    const scrollRef = useRef()

    useEffect(() => {
        scrollRef.current?.scrollTo({
            y: 0,
            animated: false,
        })
    }, [person])

    // GRADIENT STUFF
    const topGradientOpacity = useRef(new Animated.Value(0)).current
    const bottomGradientOpacity = useRef(new Animated.Value(1)).current

    const onScroll = (event) => {
        const { contentOffset, contentSize, layoutMeasurement } = event.nativeEvent

        const maxScrollOffset = contentSize.height - layoutMeasurement.height
        const scrollOffset = contentOffset.y

        const topGradientOpacityValue = scrollOffset / maxScrollOffset
        const bottomGradientOpacityValue = 1 - topGradientOpacityValue

        topGradientOpacity.setValue(topGradientOpacityValue)
        bottomGradientOpacity.setValue(bottomGradientOpacityValue)
    }
    const gradientColor = 'rgba(204,206,206,0.4)' //the tint of this is based off COLORS.darkGray but I turned up both the lightness and opaqueness on the photos screens so it's very similar color on white background as the text screen, but you can actually see it when covering the photos
    const gradientHeight = 100

    return (
        <SafeAreaView style={styles.container}>
            {modalTutorial6 && location6 && (
                <View style={[styles.overlay, location6 && location6]}>
                    <Tooltip6
                        text={`This displays the person's number out of seven, so you can manage your "Yes" votes. This does NOT correspond to the numbers you saw on the previous screen (they've been randomized).`}
                    />
                </View>
            )}
            {modalTutorial7 && (
                <View
                    style={[
                        styles.overlay,
                        {
                            backgroundColor: COLORS.modalSurroundings,
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                        },
                    ]}
                >
                    <Tooltip7
                        text={`If you didn't test out the "Hide & Report" button on the previous screen, try pressing it now after scrolling to the bottom of the screen. Then vote on all seven serially, to finish up.`}
                    />
                </View>
            )}
            <Modal visible={reportModal} animationType="fade" transparent={true}>
                <ModalReport setReportModal={setReportModal} demo={true} uidOfProfile={null} screen={'photos'} />
            </Modal>
            {/* <View ref={tooltip6Ref} onLayout={() => {}}> */}
            <PhotoHeader name={data[person].name} number={person} />
            {/* </View> */}
            {/* <View style={{ height: 20, backgroundColor: "green", position: "absolute" }}></View> */}
            <View style={{ flex: 1 }}>
                <ScrollView
                    style={{ paddingHorizontal: 20, backgroundColor: COLORS.white }}
                    ref={scrollRef}
                    showsVerticalScrollIndicator={false}
                    onScroll={(event) => onScroll(event)}
                    scrollEventThrottle={50}
                >
                    <PhotoSubHeader age={data[person].age} personsHeight={data[person].height} />
                    {imageData[person]?.face && (
                        <Image
                            source={{ uri: imageData[person].face }}
                            style={[styles.image, { height: displayedHeights[person]?.face }]}
                        />
                    )}
                    {imageData[person]?.body && (
                        <Image
                            source={{ uri: imageData[person].body }}
                            style={[styles.image, { height: displayedHeights[person]?.body }]}
                        />
                    )}
                    {imageData[person]?.life1 && (
                        <Image
                            source={{ uri: imageData[person].life1 }}
                            style={[styles.image, { height: displayedHeights[person]?.life1 }]}
                        />
                    )}
                    {imageData[person]?.life2 && (
                        <Image
                            source={{ uri: imageData[person].life2 }}
                            style={[styles.image, { height: displayedHeights[person]?.life2 }]}
                        />
                    )}
                    {imageData[person]?.life3 && (
                        <Image
                            source={{ uri: imageData[person].life3 }}
                            style={[styles.image, { height: displayedHeights[person]?.life3 }]}
                        />
                    )}
                    <View style={{ alignItems: 'center', marginVertical: 25 }}>
                        <Pressable onPress={() => setReportModal(true)}>
                            <AlphaText>Hide & Report</AlphaText>
                        </Pressable>
                    </View>
                </ScrollView>
                <Animated.View
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        height: gradientHeight,
                        opacity: topGradientOpacity,
                    }}
                    pointerEvents={'none'}
                >
                    <LinearGradient colors={[gradientColor, 'transparent']} style={{ flex: 1 }} />
                </Animated.View>
                <Animated.View
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        height: gradientHeight,
                        opacity: bottomGradientOpacity,
                    }}
                    pointerEvents={'none'}
                >
                    <LinearGradient colors={['transparent', gradientColor]} style={{ flex: 1 }} />
                </Animated.View>
            </View>
            <VotingFooter
                person={person}
                setPerson={setPerson}
                votes={votes}
                setVotes={setVotes}
                setConfirmationModal={null}
                screen="PhotosDemo"
                initialYesVotes={5}
            />
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        backgroundColor: COLORS.white,
        flex: 1,
    },
    image: {
        marginTop: 10,
        alignSelf: 'center',
        width: displayedWidth,
        borderRadius: 10,
    },
    triangleUp: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 0,
        borderRightWidth: 15,
        borderBottomWidth: 15,
        borderLeftWidth: 15,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: COLORS.redButton,
        borderLeftColor: 'transparent',
    },
    overlay: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
    },
})
