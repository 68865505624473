import { View, Pressable, ScrollView, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { styles } from './StylesSettings'

export default function ModalLogout2({ setModalLogout2, demo }) {
    return (
        <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
            <View
                style={{
                    backgroundColor: COLORS.modalColor,
                    padding: 20,
                    alignContent: 'space-around',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    flex: 1,
                    marginHorizontal: 20,
                    marginTop: 150,
                    marginBottom: 150,
                    borderRadius: 10,
                }}
            >
                <AlphaText style={styles.middleText}>
                    {demo &&
                        "You can't logout until you login (by first creating an account)! Remember, this is just a demo."}
                    {!demo && 'Logout successful, you may close the app.'}
                </AlphaText>
                <View style={{ alignItems: 'center' }}>
                    <Pressable
                        onPress={() => setModalLogout2(false)}
                        style={({ pressed }) => [
                            {
                                justifyContent: 'center',
                                alignSelf: 'center',
                                borderRadius: 5,
                                paddingVertical: 10,
                                paddingHorizontal: 20,
                                marginHorizontal: 10,
                                marginTop: 25,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.redButton,
                            },
                        ]}
                    >
                        <AlphaText style={styles.buttonText1}>OK</AlphaText>
                    </Pressable>
                </View>
            </View>
        </View>
    )
}
