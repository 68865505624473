import React, { useEffect, useState } from 'react'
import { Dimensions, View, Pressable, StyleSheet, Modal } from 'react-native'

import { SafeAreaView } from 'react-native-safe-area-context'
import AlphaText from '../ComponentsShared/TextAlpha'

import { COLORS } from './../../Colors'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'
const { width, height } = Dimensions.get('window')

let HS = width / 320
let VS = height / 640

function PictureOnboarding({ navigation }) {
    const handleContinue = () => {
        logEvent(analytics, 'finished_picture_onboarding_screen')
        navigation.navigate('ExampleFace')
    }

    const [modalVisible, setModalVisible] = useState(false)
    const [modal2Visible, setModal2Visible] = useState(false)

    const [pressed, setPressed] = useState(false)
    useEffect(() => {
        setModalVisible(true) //Does this once when it loads
    }, [])
    useEffect(() => {
        setModal2Visible(false)
    }, [])

    function handleBackButtonClick() {
        return true
    }
    async function save() {
        try {
            AsyncStorage.setItem('creationCheckpoint', 'pictureOnboarding')
        } catch (err) {
            // console.log(err);
        }
    }
    useEffect(() => {
        save()
    }, [])
    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white }}>
            <View style={styles.container}>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={() => {
                        setModalVisible(!modalVisible)
                    }}
                >
                    <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                        <View
                            style={{
                                backgroundColor: COLORS.white,
                                padding: 20,
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                flex: 1,
                                marginHorizontal: 30,
                                marginVertical: 90 * VS,
                                borderRadius: 10,
                            }}
                        >
                            <AlphaText
                                style={{
                                    color: COLORS.nearBlack,
                                    fontSize: 25,
                                    textAlign: 'center',
                                }}
                            >
                                PLEASE READ CAREFULLY OR YOU WILL BE BANNED
                            </AlphaText>
                            <AlphaText
                                style={{
                                    color: COLORS.nearBlack,
                                    fontSize: 15,
                                    textAlign: 'center',
                                    marginTop: 15 * VS,
                                }}
                            >
                                And this is the best dating app ever created, so you really don't want to be banned...
                            </AlphaText>
                            {/* Should maybe edit wording above so doesn't say "best dating app", although I think it probably is fine and qualifies as puffery. Maybe hire attorney once have $$$? */}
                            <Pressable
                                onPress={() => setModalVisible(!modalVisible)}
                                style={({ pressed }) => [
                                    {
                                        borderRadius: 5,
                                        padding: 10,
                                        marginTop: 25,
                                        backgroundColor: COLORS.redButton,
                                        opacity: pressed ? 0.5 : 1,
                                    },
                                ]}
                            >
                                <AlphaText style={{ fontSize: 15, color: COLORS.white }}>
                                    I happily understand
                                </AlphaText>
                            </Pressable>

                            <Pressable
                                onPress={() => setModalVisible(!modalVisible)}
                                style={({ pressed }) => [
                                    {
                                        borderRadius: 5,
                                        padding: 10,
                                        marginTop: 25,
                                        backgroundColor: COLORS.redButton,
                                        opacity: pressed ? 0.5 : 1,
                                    },
                                ]}
                            >
                                <AlphaText style={{ fontSize: 15, color: COLORS.white }}>
                                    I begrudgingly understand
                                </AlphaText>
                            </Pressable>
                        </View>
                    </View>
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modal2Visible}
                    onRequestClose={() => {
                        setModal2Visible(!modal2Visible)
                    }}
                >
                    <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                        <View
                            style={{
                                backgroundColor: COLORS.lightGreen,
                                padding: 10,
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                flex: 1,
                                marginHorizontal: 15 * HS,
                                marginVertical: 75 * VS,
                                borderRadius: 10,
                            }}
                        >
                            <AlphaText
                                style={{
                                    color: COLORS.nearBlack,
                                    fontSize: 25,
                                    textAlign: 'center',
                                }}
                            >
                                Why so many rules?
                            </AlphaText>
                            <AlphaText
                                style={{
                                    paddingTop: 10,
                                    color: COLORS.nearBlack,
                                    fontSize: 15,
                                    textAlign: 'center',
                                }}
                            >
                                We're aware that all the rules can make setting up an account a bit of a pain, but we
                                believe that the payoff is worth it. The rules help ensure that you can quickly get a
                                good idea of what potential matches look like, saving everyone time and heartache. The
                                fact of the matter is that everyone has their own tastes and preferences, so it's best
                                for all parties to be open and transparent both about who we are on the inside AND what
                                we look like on the outside.
                            </AlphaText>

                            <Pressable
                                onPress={() => setModal2Visible(!modal2Visible)}
                                style={({ pressed }) => [
                                    {
                                        borderRadius: 5,
                                        padding: 10,
                                        marginTop: 25,
                                        backgroundColor: COLORS.redButton,
                                        opacity: pressed ? 0.5 : 1,
                                    },
                                ]}
                            >
                                <AlphaText style={{ fontSize: 17, color: COLORS.white }}>Close</AlphaText>
                            </Pressable>
                        </View>
                    </View>
                </Modal>
                <AlphaText
                    style={{
                        fontSize: 40,
                        color: COLORS.nearBlack,
                        textAlign: 'center',
                    }}
                >
                    Picture Day
                </AlphaText>
                <AlphaText
                    style={{
                        fontSize: 24,
                        color: COLORS.nearBlack,
                        marginTop: 20,
                    }}
                >
                    Strict Rules:
                </AlphaText>

                <AlphaText
                    style={{
                        color: COLORS.nearBlack,
                        fontSize: 14,
                        paddingVertical: 5,
                    }}
                >
                    No hate speech/symbols.
                </AlphaText>
                <AlphaText
                    style={{
                        color: COLORS.nearBlack,
                        fontSize: 14,
                        paddingVertical: 5,
                    }}
                >
                    No below-the-belt nudity.
                </AlphaText>
                {/* <AlphaText
                    style={{
                        color: COLORS.nearBlack,
                        fontSize: 14,
                        paddingVertical: 5,
                    }}
                >
                    If someone other than yourself is in one of your photos,
                    their face must be covered.
                </AlphaText> */}
                <AlphaText
                    style={{
                        color: COLORS.nearBlack,
                        fontSize: 14,
                        paddingVertical: 5,
                    }}
                >
                    Do not promote your social media. Any links to your instagram, snapchat, onlyfans, etcetera, will
                    result in an immediate ban.
                </AlphaText>
                <Pressable onPress={() => setModal2Visible(!modal2Visible)}>
                    {({ pressed }) => (
                        <AlphaText
                            style={{
                                fontSize: 14,
                                fontStyle: 'italic',
                                fontWeight: 'bold',
                                color: COLORS.redButton,
                                textDecorationLine: 'underline',
                                opacity: pressed ? 0.5 : 1,
                                paddingVertical: 10,
                            }}
                        >
                            Why so many rules?
                        </AlphaText>
                    )}
                </Pressable>
                <AlphaText
                    style={{
                        fontSize: 24,
                        color: COLORS.nearBlack,
                        marginTop: 20,
                    }}
                >
                    Guidelines:
                </AlphaText>
                <AlphaText
                    style={{
                        color: COLORS.nearBlack,
                        fontSize: 14,
                        paddingVertical: 5,
                    }}
                >
                    {
                        "Please comply with the minimum photo requirements (additional details in sample gallery): unobstructed face photo, unobstructed body photo. We realize some people don't have many good photos of themselves, but when it comes to online dating they're pretty essential."
                    }
                </AlphaText>
                {/* <AlphaText style={{ fontSize: 24, color: COLORS.nearBlack, marginTop: 20 }}>
                    You will have the opportunity to add/switch photos later on. //ADD THIS BACK IN WHEN IT IS TRUE
                </AlphaText> */}
                {/* no minors in photos? */}
                <Pressable
                    style={{
                        marginVertical: 20,
                        borderRadius: 5,
                        padding: 7,
                        backgroundColor: COLORS.redButton,
                        opacity: pressed ? 0.5 : 1,
                    }}
                    onPressIn={() => setPressed(!pressed)}
                    onPressOut={() => setPressed(!pressed)}
                    onPress={handleContinue}
                >
                    <AlphaText style={styles.buttonText}>View Example Photos</AlphaText>
                </Pressable>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    buttonText: {
        fontSize: 20,
        color: COLORS.white,
        alignSelf: 'center',
    },
    container: {
        flex: 1,
        backgroundColor: COLORS.white,
        paddingHorizontal: 20,
        justifyContent: 'center',
    },
})
export default PictureOnboarding
