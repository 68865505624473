import { View, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'

export default function ForFun({ answers }) {
    return (
        <View style={{ marginTop: 30, alignItems: 'flex-start' }}>
            <AlphaText style={{ fontSize: 15 }}>For fun</AlphaText>
            {answers.ForFun1.question !== '' && (
                <View style={styles.largeBox}>
                    <AlphaText style={styles.questionText}>{answers.ForFun1.question}</AlphaText>
                    <AlphaText style={styles.boxText}>{answers.ForFun1.answer}</AlphaText>
                </View>
            )}
            {answers.ForFun2.question !== '' && (
                <View style={styles.largeBox}>
                    <AlphaText style={styles.questionText}>{answers.ForFun2.question}</AlphaText>
                    <AlphaText style={styles.boxText}>{answers.ForFun2.answer}</AlphaText>
                </View>
            )}
            {answers.ForFun3.question !== '' && (
                <View style={styles.largeBox}>
                    <AlphaText style={styles.questionText}>{answers.ForFun3.question}</AlphaText>
                    <AlphaText style={styles.boxText}>{answers.ForFun3.answer}</AlphaText>
                </View>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    boxText: {
        fontSize: 18,
    },
    largeBox: {
        marginTop: 10,
        backgroundColor: COLORS.lightBlue,
        borderRadius: 8,
        padding: 15,
        flexDirection: 'column',
        width: '100%', //to fill space if text is shorter than one line
    },

    questionText: {
        fontSize: 15,
        marginBottom: 10,
    },
})
