import React, { useState } from 'react'
import { View, Image, ScrollView, Dimensions, StyleSheet, Pressable } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from './../../Colors'
import AlphaText from '../ComponentsShared/TextAlpha'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'
var { width, height } = Dimensions.get('window')
let HS = width / 320
let VS = height / 640

var height = height - 200 //This amount is because there is a lot of text to top and bottom of photos, otherwise the top/bottom of pics gets cut off (well at least for body and lifestyle pics, the face pics are more square)

const images2 = [
    { image: require('./../../images/faceExamples/man-face1.jpg') },
    { image: require('./../../images/faceExamples/nope-face2.png') },
    { image: require('./../../images/faceExamples/nope-face3.jpg') },
    { image: require('./../../images/faceExamples/woman-face4.jpg') },
    { image: require('./../../images/faceExamples/woman-face-dog-bad5.jpg') },
    { image: require('./../../images/faceExamples/woman-face-flower-ok6.jpg') },
]

function ExamplePhotos({ navigation }) {
    const [current, setSlide] = useState(0)
    const change = ({ nativeEvent }) => {
        const slide = Math.ceil(nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width)
    }
    function handlePress() {
        logEvent(analytics, 'finished_example_face_screen')
        navigation.navigate('UploadFace')
    }
    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white }}>
            <View style={styles.container}>
                <AlphaText
                    style={{
                        fontSize: 30,
                        color: COLORS.nearBlack,
                        textAlign: 'center',
                    }}
                >
                    Example Face Photos
                </AlphaText>

                <ScrollView
                    pagingEnabled
                    horizontal
                    onScroll={change}
                    showsHorizontalScrollIndicator={false}
                    // style={styles.scrolltainer}
                    contentContainerStyle={{
                        flexGrow: 1,
                        alignItems: 'center',
                    }}
                >
                    {images2.map((pic, index) => (
                        <Image key={index} source={pic.image} style={styles.image} />
                    ))}
                </ScrollView>
                <View style={{ marginHorizontal: 10 * HS, marginVertical: 10 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <AlphaText
                            style={{
                                fontSize: 15,
                                color: COLORS.nearBlack,
                            }}
                        >
                            Swipe right for pics
                        </AlphaText>
                        <Pressable
                            onPress={() => handlePress()}
                            style={({ pressed }) => [
                                {
                                    borderRadius: 5,
                                    padding: 5,
                                    marginLeft: 20,
                                    backgroundColor: COLORS.redButton,
                                    opacity: pressed ? 0.5 : 1,
                                },
                            ]}
                        >
                            <AlphaText style={{ fontSize: 13, color: COLORS.white }}>Press here when done</AlphaText>
                        </Pressable>
                    </View>
                    <AlphaText
                        style={{
                            fontSize: 12,
                            color: COLORS.nearBlack,
                            fontWeight: 'bold',
                        }}
                    >
                        Do: Upload a clear headshot to the face-photo section so that others can see how you look
                    </AlphaText>
                    <AlphaText
                        style={{
                            fontSize: 11,
                            color: COLORS.nearBlack,
                            fontWeight: 'bold',
                        }}
                    >
                        Don't: Obscure face with objects or digital filters
                    </AlphaText>
                    <AlphaText
                        style={{
                            fontSize: 11,
                            color: COLORS.nearBlack,
                            fontWeight: 'bold',
                        }}
                    >
                        Don't: Use a pic with small face. No full body shots
                    </AlphaText>
                    <AlphaText
                        style={{
                            fontSize: 11,
                            color: COLORS.nearBlack,
                            fontWeight: 'bold',
                        }}
                    >
                        Don't: Use a pic taken from a weird-ass angle
                    </AlphaText>
                    <AlphaText
                        style={{
                            fontSize: 11,
                            color: COLORS.nearBlack,
                            fontWeight: 'bold',
                        }}
                    >
                        Don't: Use a group photo
                    </AlphaText>
                    {/* ADD AN EXAMPLE PHOTO WITH MULTIPLE PEOPLE IN IT */}
                </View>
            </View>
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        width,
        height,
        backgroundColor: COLORS.white,
    },
    scrolltainer: {
        flexGrow: 1,
        width,
        height,
        backgroundColor: COLORS.white,
    },
    image: {
        width,
        height,
        resizeMode: 'contain',
    },
})

export default ExamplePhotos
