import { View, Pressable, ScrollView, StyleSheet } from "react-native";
import AlphaText from "../ComponentsShared/TextAlpha";
import { COLORS } from "../../Colors";
import { styles } from "./StylesSettings"

export default function modalPrivacyPolicy({ setModalPrivacyPolicy }) {

    return (
        <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: "center" }}>
            <View
                style={{
                    backgroundColor: COLORS.modalColor,
                    padding: 20,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    flex: 1,
                    marginHorizontal: 20,
                    marginTop: 75,
                    marginBottom: 75,
                    borderRadius: 10,
                }}
            >
                <ScrollView>
                    <AlphaText style={styles.modalTitle}>
                        Privacy Policy
                    </AlphaText>
                    <AlphaText style={styles.modalText1}>
                        {"Your use of Thrive constitutes your agreement to our Terms and Conditions and acceptance of this Privacy Policy, as well as your consent to the practices it describes.\n\nThis privacy policy will likely be updated at some point. We will let you know when changes are made to the privacy policy and/or Terms and Conditions."}
                    </AlphaText>
                    <AlphaText style={styles.privacySubHeading}>
                        Data Collection
                    </AlphaText>
                    <AlphaText style={styles.modalText1}>
                        {"What data we collect\n\nThrive collects only the data that is necessary for facilitating your interactions with other app users and for maintaining the security and authentication of your account. Thrive collects data you provide through the app. This may include, but is not limited to: your name, email, age, gender, sexual orientation, religion, and myriad other information about who you are, what you like and dislike. Thrive collects the photos of yourself you upload to Thrive. Thrive may collect payment information you submit when purchasing a subscription. Thrive may collect information about your device’s operating system to ensure full functionality of the app.\n\nHow we collect data\n\nThrive collects this data when you: A. Type the information into a text field in Thrive and press a button to confirm submission of that information, B. Press a button indicating a selection from multiple options, C. Select a photo from your device to upload to Thrive’s cloud.\n\nWhat we do with the data\n\nThroughout the account creation process, Thrive informs you of which pieces of information will or will not be visible to other viewers. All data collected by Thrive is used for: A. Displaying this information to other users in a semi-anonymized manner detailed in the app’s demo and during account creation (and maybe on our website), B. Keeping your account securely under your control, C. Finding appropriate users to show to you based on your preferences and, in kind, showing your profile to other users based on their preferences."}
                    </AlphaText>
                    <AlphaText style={styles.privacySubHeading}>
                        Third Party Data Usage
                    </AlphaText>
                    <AlphaText style={styles.modalText1}>
                        {"Any third party entity that might have access to your data will provide equal protection of your data. Please note, we are not in the business of selling user data. Any user data sharing with third parties would be for the express purpose of maintaining/improving the app’s core functionality. For example, we do not have our own servers, but rather use the cloud services of one of the industry leaders in on-demand cloud computing. We won’t do anything with your data that we wouldn’t do with our own data."}
                    </AlphaText>
                    <AlphaText style={styles.privacySubHeading}>
                        Data Retention/Deletion
                    </AlphaText>
                    <AlphaText style={styles.modalText1} >
                        {"Thrive retains the data you provide potentially indefinitely unless you delete your account. Please note that your profile will not be shown to other users if you are deemed to be inactive. Users can also manually pause their account so that it is not visible to other users. If users want to nuke their account, they can request deletion of their data through the settings menu and it will be deleted irreversibly."}
                    </AlphaText>
                    <AlphaText style={styles.privacySubHeading}>
                        Data of Minors
                    </AlphaText>
                    <AlphaText style={styles.modalText1}>
                        {"Thrive is not intended for children. Individuals under the age of 18 are prohibited from using Thrive. We do not knowingly collect any data from minors and actively take measures to keep underage users off our platform."}
                    </AlphaText>
                </ScrollView>
                <View style={{ alignItems: "center", }}>
                    <Pressable
                        onPress={() => setModalPrivacyPolicy(false)}
                        style={({ pressed }) => [
                            {
                                borderRadius: 5,
                                padding: 10,
                                marginRight: 20,
                                marginTop: 15,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.redButton,
                            },
                        ]}
                    >
                        <AlphaText style={styles.buttonText1}>
                            Back
                        </AlphaText>
                    </Pressable>
                </View>
            </View>
        </View>
    );
}
