import React, { useState, useEffect, useContext } from 'react'
import { Dimensions, View, Text, TextInput, Keyboard, Modal, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { SafeAreaView } from 'react-native-safe-area-context'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'
import { COLORS } from '../../Colors'
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function GenderSex({ navigation }) {
    const { HS, VS } = useContext(ScreenSizeContext)
    const styles = StyleSheet.create({
        textInput: {
            color: COLORS.nearBlack,
            borderWidth: 1,
            height: 60 * HS,
            fontSize: 30 * ((VS + HS) / 2) * 0.85, //if you update the calculations on this line, need to update alphatext and other textinputs
            fontFamily: 'Medium',
            backgroundColor: COLORS.white,
            width: 50 * HS,
            paddingHorizontal: 5 * HS,
            borderRadius: 5 * VS,
            marginHorizontal: 10,
        },
    })

    function handleBackButtonClick() {
        return true
    }

    const [gender, setGender] = useState('')
    const [sexOrientation, setSexOrientation] = useState('')
    const [ageMin, setAgeMin] = useState('')
    const [ageMax, setAgeMax] = useState('')
    const [man1Color, setMan1Color] = useState(COLORS.darkGray)
    const [man2Color, setMan2Color] = useState(COLORS.darkGray)
    const [woman1Color, setWoman1Color] = useState(COLORS.darkGray)
    const [woman2Color, setWoman2Color] = useState(COLORS.darkGray)
    const [textConfirmation, setTextConfirmation] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const [modalWarningVisible, setModalWarningVisible] = useState(true)

    const [modalAlert, setModalAlert] = useState(false)
    const [alertModalText, setAlertModalText] = useState('error')

    function handleMan1() {
        if (man1Color == COLORS.darkGray) {
            setMan1Color(COLORS.redButton)
            setWoman1Color(COLORS.darkGray)
        }
        if (man1Color == COLORS.redButton) {
            setMan1Color(COLORS.darkGray)
        }
    }

    function handleWoman1() {
        if (woman1Color == COLORS.darkGray) {
            setWoman1Color(COLORS.redButton)
            setMan1Color(COLORS.darkGray)
        }
        if (woman1Color == COLORS.redButton) {
            setWoman1Color(COLORS.darkGray)
        }
    }
    function handleMan2() {
        if (man2Color == COLORS.darkGray) {
            setMan2Color(COLORS.redButton)
        }
        if (man2Color == COLORS.redButton) {
            setMan2Color(COLORS.darkGray)
        }
    }

    function handleWoman2() {
        if (woman2Color == COLORS.darkGray) {
            setWoman2Color(COLORS.redButton)
        }
        if (woman2Color == COLORS.redButton) {
            setWoman2Color(COLORS.darkGray)
        }
    }
    function confirmation() {
        if (ageMax < 18 || ageMin < 18) {
            setModalAlert(true)
            setAlertModalText('Please increase age range to 18 minimum')
            return
        }
        if (ageMax - ageMin < 3) {
            setModalAlert(true)
            setAlertModalText('Please make second number at least 3 greater than first')
            return
        }
        if (ageMax > 200) {
            setAgeMax(200) //TEST THIS
        }
        if (woman2Color == COLORS.redButton && man2Color == COLORS.redButton) {
            if (woman1Color == COLORS.redButton) {
                setTextConfirmation(
                    'You are a woman who wishes to date either a man or a woman.\n\nYou will be shown the same number of people per day as everyone else, but you will be shown both men and women. Their gender is unlikely to be apparent from their answers, so selecting to date both genders is only recommended if you are truly indifferent.'
                )
                setGender('F')
                setSexOrientation('B')
            }
            if (man1Color == COLORS.redButton) {
                setTextConfirmation(
                    'You are a man who wishes to date either a man or a woman.\n\nYou will be shown the same number of people per day as everyone else, but you will be shown both men and women. Their gender is unlikely to be apparent from their answers, so selecting to date both genders is only recommended if you are truly indifferent.'
                )
                setGender('M')
                setSexOrientation('B')
            }
        } else {
            if (woman1Color == COLORS.redButton && woman2Color == COLORS.redButton) {
                setTextConfirmation('You are a woman who wishes to date a woman')
                setGender('F')
                setSexOrientation('G')
            }
            if (woman1Color == COLORS.redButton && man2Color == COLORS.redButton) {
                setTextConfirmation('You are a woman who wishes to date a man')
                setGender('F')
                setSexOrientation('S')
            }
            if (man1Color == COLORS.redButton && woman2Color == COLORS.redButton) {
                setTextConfirmation('You are a man who wishes to date a woman')
                setGender('M')
                setSexOrientation('S')
            }
            if (man1Color == COLORS.redButton && man2Color == COLORS.redButton) {
                setTextConfirmation('You are a man who wishes to date a man')
                setGender('M')
                setSexOrientation('G')
            }
        }

        if (woman1Color == COLORS.redButton || man1Color == COLORS.redButton) {
            if (woman2Color == COLORS.redButton || man2Color == COLORS.redButton) {
                setModalVisible(true)
            } else {
                setModalAlert(true)
                setAlertModalText('Inadequate info. Have a ?, please see our FAQs.')
            }
        } else {
            setModalAlert(true)
            setAlertModalText('Inadequate info. Have a ?, please see our FAQs.')
        }
    }

    async function uploadAndProceed() {
        setModalVisible(false)
        console.log(gender)
        console.log(sexOrientation)
        console.log(ageMin)
        console.log(ageMax)
        logEvent(analytics, 'finished_gender_sex_screen')
        navigation.navigate('AgeZipcode', {
            gender: gender,
            sexOrientation: sexOrientation,
            max: ageMax,
            min: ageMin,
        })
    }
    function killKeyboard() {
        Keyboard.dismiss()
    }
    return (
        <SafeAreaView style={{ backgroundColor: COLORS.white, flex: 1 }}>
            {/* <TouchableWithoutFeedback onPress={killKeyboard}> */}
            <View
                style={{
                    backgroundColor: COLORS.white,
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignSelf: 'center',
                    flex: 1,
                }}
            >
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={() => {
                        setModalVisible(!modalVisible)
                    }}
                >
                    <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                        <View
                            style={{
                                backgroundColor: COLORS.lightGreen,
                                paddingVertical: 30 * VS,
                                paddingHorizontal: 10 * HS,
                                margin: 20,
                                borderRadius: 10,
                                alignItems: 'center',
                            }}
                        >
                            <AlphaText
                                style={{
                                    color: COLORS.nearBlack,
                                    fontSize: 30,
                                    textAlign: 'center',
                                }}
                            >
                                Is this correct?
                            </AlphaText>

                            <AlphaText
                                style={{
                                    fontSize: 14,
                                    color: COLORS.nearBlack,
                                    padding: 20,
                                    textAlign: 'center',
                                }}
                            >
                                {textConfirmation}
                            </AlphaText>

                            <View
                                style={{
                                    flexDirection: 'row',
                                }}
                            >
                                <Pressable
                                    onPress={() => setModalVisible(!modalVisible)}
                                    style={({ pressed }) => [
                                        {
                                            borderRadius: 5,
                                            padding: 10 * VS,
                                            marginHorizontal: 15,
                                            marginVertical: 10,
                                            opacity: pressed ? 0.5 : 1,
                                            backgroundColor: COLORS.darkGray,
                                        },
                                    ]}
                                >
                                    <AlphaText
                                        style={{
                                            fontSize: 17,
                                            color: COLORS.white,
                                            textAlign: 'center',
                                        }}
                                    >
                                        No
                                    </AlphaText>
                                </Pressable>
                                <Pressable
                                    onPress={() => uploadAndProceed()}
                                    style={({ pressed }) => [
                                        {
                                            borderRadius: 5,
                                            padding: 10 * VS,
                                            marginHorizontal: 15,
                                            marginVertical: 10,
                                            opacity: pressed ? 0.5 : 1,
                                            backgroundColor: COLORS.redButton,
                                        },
                                    ]}
                                >
                                    <AlphaText
                                        style={{
                                            fontSize: 17,
                                            color: COLORS.white,
                                            textAlign: 'center',
                                        }}
                                    >
                                        Yes
                                    </AlphaText>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalWarningVisible}
                    onRequestClose={() => {
                        setModalWarningVisible(!modalWarningVisible)
                    }}
                >
                    <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                        <View
                            style={{
                                backgroundColor: COLORS.white,
                                padding: 10,
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                flex: 1,
                                marginHorizontal: 15,
                                marginTop: 100,
                                marginBottom: 100,
                                borderRadius: 10,
                            }}
                        >
                            <AlphaText
                                style={{
                                    color: COLORS.nearBlack,
                                    fontSize: 30,
                                    textAlign: 'center',
                                }}
                            >
                                Caution
                            </AlphaText>

                            <AlphaText
                                style={{
                                    fontSize: 16,
                                    color: COLORS.nearBlack,
                                    padding: 20,
                                    textAlign: 'center',
                                }}
                            >
                                There are restrictions on which parts of your profile you are allowed to update and how
                                often, so please pay close attention when creating your profile, as choices may be
                                irreversible.
                            </AlphaText>

                            <View
                                style={{
                                    flexDirection: 'row',
                                }}
                            >
                                <Pressable
                                    onPress={() => setModalWarningVisible(!modalWarningVisible)}
                                    style={({ pressed }) => [
                                        {
                                            flex: 2,
                                            borderRadius: 5,
                                            paddingVertical: 10,
                                            paddingHorizontal: 20,
                                            marginTop: 25,
                                            marginHorizontal: 30,
                                            opacity: pressed ? 0.5 : 1,
                                            backgroundColor: COLORS.redButton,
                                        },
                                    ]}
                                >
                                    <AlphaText
                                        style={{
                                            fontSize: 17,
                                            color: COLORS.white,
                                            textAlign: 'center',
                                        }}
                                    >
                                        OK
                                    </AlphaText>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalAlert}
                    onRequestClose={() => {
                        setModalAlert(!modalAlert)
                    }}
                >
                    <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
                        <View
                            style={{
                                backgroundColor: COLORS.white,
                                padding: 20,
                                // alignItems: "center",
                                justifyContent: 'center',
                                flexDirection: 'column',
                                flex: 1,
                                marginHorizontal: 30,
                                marginTop: 50,
                                marginBottom: 50,
                                borderRadius: 10,
                            }}
                        >
                            <AlphaText
                                style={{
                                    color: COLORS.nearBlack,
                                    fontSize: 30,
                                    textAlign: 'center',
                                    paddingBottom: 15,
                                }}
                            >
                                {alertModalText}
                            </AlphaText>

                            <View
                                style={{
                                    flexDirection: 'row',
                                }}
                            >
                                <View style={{ flex: 0.5 }}></View>

                                <Pressable
                                    onPress={() => setModalAlert(!modalAlert)}
                                    style={({ pressed }) => [
                                        {
                                            flex: 6,
                                            borderRadius: 5,
                                            padding: 10,
                                            justifyContent: 'center',
                                            marginTop: 25,
                                            opacity: pressed ? 0.5 : 1,
                                            backgroundColor: COLORS.redButton,
                                        },
                                    ]}
                                >
                                    <AlphaText
                                        style={{
                                            fontSize: 14,
                                            color: COLORS.white,
                                            textAlign: 'center',
                                        }}
                                    >
                                        Back
                                    </AlphaText>
                                </Pressable>

                                <View style={{ flex: 0.5 }}></View>
                            </View>
                        </View>
                    </View>
                </Modal>
                <AlphaText
                    style={{
                        fontSize: 29,
                        color: COLORS.nearBlack,
                        marginBottom: 20,
                    }}
                >
                    I am a...
                </AlphaText>
                <View style={{ flexDirection: 'row' }}>
                    <Pressable
                        style={{
                            backgroundColor: man1Color,
                            borderRadius: 15,
                            marginLeft: 30,
                            marginRight: 15,
                            paddingVertical: 10,
                        }}
                        onPress={handleMan1}
                    >
                        <AlphaText
                            style={{
                                fontSize: 30,
                                color: COLORS.white,
                                padding: 20,
                                borderRadius: 15,
                                textAlign: 'center',
                            }}
                        >
                            man
                        </AlphaText>
                    </Pressable>
                    <Pressable
                        style={{
                            backgroundColor: woman1Color,
                            borderRadius: 15,
                            marginLeft: 15,
                            marginRight: 30,
                            paddingVertical: 10,
                        }}
                        onPress={handleWoman1}
                    >
                        <AlphaText
                            style={{
                                fontSize: 30,
                                color: COLORS.white,
                                padding: 20,
                                borderRadius: 15,
                                textAlign: 'center',
                            }}
                        >
                            woman
                        </AlphaText>
                    </Pressable>
                </View>
                <AlphaText
                    style={{
                        fontSize: 29,
                        color: COLORS.nearBlack,
                        marginTop: 30 * VS,
                        marginBottom: 10,
                    }}
                >
                    I want to date a
                </AlphaText>
                <View
                    style={{
                        flexDirection: 'row',
                        // backgroundColor: "teal",
                    }}
                >
                    <TextInput
                        style={styles.textInput}
                        keyboardType="number-pad"
                        placeholder="00"
                        placeholderTextColor={COLORS.darkGray}
                        maxLength={3}
                        onChangeText={(ageMin) => setAgeMin(ageMin)}
                        caretHidden={true}
                        value={ageMin}
                    ></TextInput>
                    <AlphaText
                        style={{
                            fontSize: 29,
                            color: COLORS.nearBlack,
                            paddingTop: 6 * HS,
                        }}
                    >
                        to
                    </AlphaText>
                    <TextInput
                        style={styles.textInput}
                        keyboardType="default"
                        placeholder="00"
                        placeholderTextColor={COLORS.darkGray}
                        maxLength={3}
                        onChangeText={(ageMax) => setAgeMax(ageMax)}
                        value={ageMax}
                        autoCapitalize="none"
                    ></TextInput>
                    <AlphaText
                        style={{
                            fontSize: 29,
                            color: COLORS.nearBlack,
                            paddingTop: 5 * HS,
                        }}
                    >
                        year old...
                    </AlphaText>
                </View>
                <View style={{ flexDirection: 'row', marginTop: 15 }}>
                    <Pressable
                        style={{
                            backgroundColor: man2Color,
                            borderRadius: 15,
                            marginLeft: 30,
                            marginRight: 15,
                            // flex: 1,
                            paddingVertical: 10,
                        }}
                        onPress={handleMan2}
                    >
                        <AlphaText
                            style={{
                                fontSize: 30,
                                color: COLORS.white,
                                padding: 20,
                                borderRadius: 15,
                                textAlign: 'center',
                            }}
                        >
                            man
                        </AlphaText>
                    </Pressable>
                    <Pressable
                        style={{
                            backgroundColor: woman2Color,
                            borderRadius: 15,
                            marginLeft: 15,
                            marginRight: 30,
                            // flex: 1,
                            paddingVertical: 10,
                        }}
                        onPress={handleWoman2}
                    >
                        <AlphaText
                            style={{
                                fontSize: 30,
                                color: COLORS.white,
                                padding: 20,
                                borderRadius: 15,
                                textAlign: 'center',
                            }}
                        >
                            woman
                        </AlphaText>
                    </Pressable>
                </View>

                <Pressable
                    onPress={() => confirmation()}
                    style={({ pressed }) => [
                        {
                            color: '#2196f3',
                            borderRadius: 15,
                            padding: 15,
                            marginTop: 40 * VS,
                            backgroundColor: COLORS.redButton,
                            opacity: pressed ? 0.5 : 1,
                        },
                    ]}
                >
                    <AlphaText
                        style={{
                            fontSize: 20,
                            color: COLORS.white,
                            textAlign: 'center',
                        }}
                    >
                        Confirm Selections
                    </AlphaText>
                </Pressable>
            </View>
            {/* </TouchableWithoutFeedback> */}
        </SafeAreaView>
    )
}
