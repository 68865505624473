import { View, Pressable, ScrollView, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { styles } from './StylesSettings'

export default function ModalTips({ setModalTips }) {
    return (
        <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
            <View
                style={{
                    backgroundColor: COLORS.modalColor,
                    padding: 20,
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    flex: 1,
                    marginHorizontal: 20,
                    marginTop: 75,
                    marginBottom: 75,
                    borderRadius: 10,
                }}
            >
                <ScrollView>
                    <AlphaText style={styles.modalTitle}>Tips and Tricks</AlphaText>
                    <AlphaText style={styles.modalText1}>
                        {
                            "We recommend viewing all 7 text profiles before picking your favorite one(s) to vote 'yes' on. Think carefully about which answers are most important to you and what you can live with versus true dealbreakers.\n\nFor photo profiles, it's recommended to vote 'yes' on everyone who you find sufficiently attractive to not disqualify themselves. If you find yourself routinely using all your 'yes' votes before you get to the end, you may want to get a bit more picky.\n\nKeep in mind that the more of your 'yes' votes you use, the more likely you are to get a match; There's a price to being picky. At the same time, voting 'yes' on someone you're definitely not interested in dating will only be a waste of time."
                        }
                    </AlphaText>
                    {/* 
                        
                         // TEMPORARILY DISABLED BECAUSE IDK IF PEOPLE WILL APPRECIATE THE TONE.
                        //GET FEEDBACK ON THIS PART BEFORE RE-INTEGRATING
                        <AlphaText
                            style={{ color: COLORS.darkBlue,   fontSize: 20,  textAlign: "center", paddingTop: 10,     }}
                        >
                            {"Real Talk"}
                        </AlphaText>
                        <AlphaText style={styles.modalText1}>
                            {
                                "People who are more conventionally attractive tend to have an easier time dating irl and on apps. Duh! If you're not picky when it comes to looks relative to most people (say if you run out of 'yes' votes on photos everytime), or have been using all your votes but have gone a long time without a match, consider adjusting your voting strategy for the photos section. Keep picking people you find sufficiently attractive, but prioritize those who others might not fancy as much. We don't ever recommend adjusting your voting behavior on the text profiles to try to boost matches - this is just shooting your future self in the foot - but adjusting your photo voting strategy has the potential to significantly increase your match rate, especially if you don't appear the pinnacle of conventional attractiveness in your photos either.\n\nReal Talk TLDR: If you've been on the app for six months voting daily without any matches, have you tried voting on the less hot people?"
                            }
                        </AlphaText> */}
                </ScrollView>
                <View
                    style={{
                        alignItems: 'center',
                    }}
                >
                    <Pressable
                        onPress={() => setModalTips(false)}
                        style={({ pressed }) => [
                            {
                                borderRadius: 5,
                                padding: 10,
                                marginTop: 15,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.redButton,
                            },
                        ]}
                    >
                        <AlphaText style={styles.buttonText1}>Back</AlphaText>
                    </Pressable>
                </View>
            </View>
        </View>
    )
}
