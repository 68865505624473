import React, { useState, useEffect } from 'react'
import { Dimensions, View, StyleSheet, Pressable } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import * as StoreReview from 'expo-store-review'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from './../../Colors'
const { width, height } = Dimensions.get('window')
let HS = width / 320
let VS = height / 640
export default function DoneVoting({ navigation }) {
    function handleBackButtonClick() {
        return true
    }
    const handleReturnHome = function () {
        navigation.navigate('HomeDemo')
    }

    const BullHonky = async () => {
        let dayCounter = await AsyncStorage.getItem('daysVoted')
        //console.log(dayCounter); //FYI, THIS COULD STAND TO BE REFINED DOWN THE LINE
        // dayCounter = 0;
        function initializeCounter() {
            dayCounter = 1
            return dayCounter
        }
        if (dayCounter == null) {
            initializeCounter
        }
        if (dayCounter == 1) {
            navigation.navigate('Feedback')
        }
        if (dayCounter == 5) {
            StoreReview.requestReview() //I THINK THIS SHOULD WORK, BUT IT ONLY DISPLAYS THE REVIEW THING ONCE PER MONTH PER DEVICE PER BUILD OR SOMETHING LIKE THAT BECAUSE RULES
        }
        if (dayCounter == 30) {
            navigation.navigate('Feedback')
        }
        //THIS BELOW CODE MAY OR MAY NOT EXECUTE IF ONE OF THE ABOVE IF STATEMENTS TRIPS. FIGURE OUT IF IT SHOULD OR SHOULDN'T AND THEN ADJUST CODE ACCORDINGLY (ELSE STATEMENT OR MOVE BELOW CODE ABOVE)
        // console.log(dayCounter);
        let dayNumber = parseInt(dayCounter)
        // console.log(typeof dayNumber);
        let newCount = dayNumber + 1
        let newString = newCount.toString()
        AsyncStorage.setItem('daysVoted', newString)
    }
    useEffect(() => {
        BullHonky()
    }, [])
    return (
        <View style={styles.container}>
            <View style={styles.container}>
                <AlphaText
                    style={{
                        fontSize: 40,
                        color: COLORS.darkGray,
                        marginBottom: 20,
                    }}
                >
                    Nice Work!
                </AlphaText>
                <AlphaText style={styles.text}>You are done voting for today!</AlphaText>
                <AlphaText style={styles.text}>Please check back again tomorrow for a new lineup.</AlphaText>
                <Pressable
                    onPress={handleReturnHome}
                    style={({ pressed }) => [
                        {
                            justifyContent: 'center',
                            paddingHorizontal: 5,
                            borderRadius: 10,
                            marginTop: 25,
                            backgroundColor: COLORS.redButton,
                            opacity: pressed ? 0.5 : 1,
                        },
                    ]}
                >
                    <AlphaText
                        style={{
                            color: COLORS.white,
                            fontSize: 18,
                            padding: 10,
                        }}
                    >
                        Return Home
                    </AlphaText>
                </Pressable>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.white,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },

    text: {
        marginHorizontal: 30,
        marginVertical: 10,
        textAlign: 'center',
        fontSize: 16,
        color: COLORS.nearBlack,
        lineHeight: 25 * HS,
    },
})
