import React from 'react'
import AlphaText from './TextAlpha'
import ModalPrimary from './ModalPrimary'
import ButtonPrimary from './ButtonPrimary'
import { COLORS } from '../../Colors'

export default function ModalAlert({ setModalAlert, alertModalText }) {
    return (
        <ModalPrimary>
            <AlphaText
                style={{
                    fontSize: 24,
                    textAlign: 'center',
                    paddingBottom: 15,
                }}
            >
                {alertModalText}
            </AlphaText>

            <ButtonPrimary buttonText={'Back'} onPress={() => setModalAlert(false)} />
        </ModalPrimary>
    )
}
