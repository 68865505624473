import React, { useState, useEffect } from 'react'
import { View, Image, ScrollView, Dimensions, StyleSheet, Pressable } from 'react-native'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from './../../Colors'
import AlphaText from '../ComponentsShared/TextAlpha'

var { width, height } = Dimensions.get('window')

let HS = width / 320
let VS = height / 640

var height = height - 200

const images2 = [
    { image: require('./../../images/lifestyleExamples/good1.jpeg') },
    { image: require('./../../images/lifestyleExamples/good2.jpg') },
    { image: require('./../../images/lifestyleExamples/good3.jpg') },
    // { image: require("./../../images/lifestyleExamples/bad4.jpg") },
    // { image: require("./../../images/lifestyleExamples/good5.jpeg") },
    // { image: require("./../../images/lifestyleExamples/good6.jpeg") },
    // { image: require("./../../images/lifestyleExamples/good7.jpeg") },
]

function ExamplePhotos({ navigation }) {
    function handleBackButtonClick() {
        return true
    }

    const [current, setSlide] = useState(0)
    const change = ({ nativeEvent }) => {
        const slide = Math.ceil(nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width)
    }
    function handlePress() {
        logEvent(analytics, 'finished_example_life_screen')
        navigation.navigate('UploadLife')
    }
    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white }}>
            <View style={styles.container}>
                <AlphaText
                    style={{
                        fontSize: 28,
                        color: COLORS.nearBlack,
                        textAlign: 'center',
                    }}
                >
                    Example Other Photos
                </AlphaText>
                <ScrollView
                    pagingEnabled
                    horizontal
                    onScroll={change}
                    showsHorizontalScrollIndicator={false}
                    // style={styles.scrolltainer}
                    contentContainerStyle={{
                        flexGrow: 1,
                        alignItems: 'center',
                    }}
                >
                    {images2.map((pic, index) => (
                        <Image key={index} source={pic.image} style={styles.image} />
                    ))}
                </ScrollView>
                <View style={{ marginHorizontal: 15 * HS, marginVertical: 10 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <AlphaText
                            style={{
                                fontSize: 15,
                                color: COLORS.nearBlack,
                            }}
                        >
                            Swipe right for pics
                        </AlphaText>
                        <Pressable
                            onPress={() => handlePress()}
                            style={({ pressed }) => [
                                {
                                    borderRadius: 5,
                                    padding: 5,
                                    marginLeft: 20,
                                    backgroundColor: COLORS.redButton,
                                    opacity: pressed ? 0.5 : 1,
                                },
                            ]}
                        >
                            <AlphaText style={{ fontSize: 13, color: COLORS.white }}>Press here when done</AlphaText>
                        </Pressable>
                    </View>
                    <AlphaText
                        style={{
                            fontSize: 12,
                            color: COLORS.nearBlack,
                            fontWeight: 'bold',
                        }}
                    >
                        Do: Upload photos to showcase yourself
                    </AlphaText>
                    <AlphaText
                        style={{
                            fontSize: 12,
                            color: COLORS.nearBlack,
                            fontWeight: 'bold',
                        }}
                    >
                        Do: Have fun!
                    </AlphaText>
                </View>
            </View>
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        width,
        height,
        backgroundColor: COLORS.white,
    },
    scrolltainer: {
        flexGrow: 1,
        width,
        height,
        backgroundColor: COLORS.white,
    },
    image: {
        width,
        height,
        resizeMode: 'contain',
    },
})

export default ExamplePhotos
