import React, { useEffect, useState } from 'react'
import { Dimensions, Pressable, Image, View } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { FlatList } from 'react-native-gesture-handler'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from './../../Colors'
import { db } from './../../FirebaseInitialization'
import { MaterialIcons, AntDesign } from '@expo/vector-icons'
import { getDoc, doc, query, collection, orderBy, limit, getDocs } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const { width, height } = Dimensions.get('window')
let HS = width / 320
let VS = height / 640

// GET UNREAD MSG ICON SYSTEM WORKING

export default function MessagingMenu({ navigation }) {
    const [chatListReal, setChatListReal] = useState([
        {
            displayName: 'Loading chats, this may take a few seconds...',
            id: 0,
            lastMsg: '...',
            theirUID: 'special',
        },
    ])

    function handleBack() {
        navigation.navigate('Thrive')
    }

    async function retrieveChats() {
        const auth = getAuth()
        const uid = auth.currentUser.uid
        const chatsListData = await getDoc(doc(db, 'users', uid, 'chats', 'chats'))
        const chatsList = chatsListData.data().chatsList

        if (typeof chatsList.length !== 'undefined') {
            let chatsListFormatted = []

            for (let i = 0; i < chatsList.length; i++) {
                let chatName = '...'
                let matchsUID = chatsList[i].uid
                if (uid > matchsUID) {
                    chatName = uid + '_' + matchsUID
                } else {
                    chatName = matchsUID + '_' + uid
                }
                const chatRef = collection(db, 'chats', chatName, 'messages')
                const q = query(chatRef, orderBy('createdAt', 'desc'), limit(1))
                const querySnapshot = await getDocs(q)
                let lastMsg = ''
                querySnapshot.forEach((doc) => {
                    lastMsg = doc.data().text
                })
                chatsListFormatted.push({
                    displayName: chatsList[i].name,
                    id: i,
                    lastMsg: lastMsg,
                    theirUID: matchsUID,
                })
            }

            setChatListReal(chatsListFormatted)
        } else {
            setChatListReal([
                {
                    displayName: `You don't have any active chats at the moment`,
                    id: 0,
                    lastMsg: '...',
                    theirUID: 'special',
                },
            ])
        }
    }

    useEffect(() => {
        retrieveChats()
    }, [])

    function goToChat({ item }) {
        const matchsUID = item.theirUID
        if (matchsUID == 'special') {
            return //verifies a real message has loaded or else does nothing.
        } else {
            navigation.navigate('Messaging', { matchsUID: matchsUID })
        }
    }

    const renderItem = ({ item }) => {
        return (
            <View style={{ paddingHorizontal: 30 }}>
                <Pressable
                    onPress={() => goToChat({ item })}
                    style={({ pressed }) => [
                        {
                            borderRadius: 5,
                            padding: 10,
                            marginTop: 25,
                            backgroundColor: COLORS.darkGray,
                            opacity: pressed ? 0.5 : 1,
                        },
                    ]}
                >
                    <AlphaText style={{ color: COLORS.white, fontSize: 12, fontWeight: 'bold' }}>
                        {item.displayName}
                    </AlphaText>
                    <AlphaText style={{ color: COLORS.white, fontSize: 12 }}>{item.lastMsg}</AlphaText>
                </Pressable>
            </View>
        )
    }
    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white }}>
            <View
                //setting margin to auto sorta overrides flexbox
                style={{ flex: 1, flexDirection: 'row', zIndex: 999, position: 'absolute', top: 20, left: 20 }}
            >
                <Pressable
                    onPress={() => handleBack()}
                    style={({ pressed }) => [
                        {
                            opacity: pressed ? 0.5 : 1,
                            backgroundColor: COLORS.white,
                            borderRadius: 25,
                            paddingVertical: 10,
                            paddingRight: 7,
                            paddingStart: 3,
                        },
                    ]}
                >
                    <AntDesign name="left" size={40} color={COLORS.redButton} />
                </Pressable>
            </View>
            <View style={{ flex: 1, marginTop: 40 * VS, paddingBottom: 50 }}>
                <AlphaText
                    style={{
                        color: COLORS.darkGray,
                        fontSize: 30,
                        textAlign: 'center',
                    }}
                >
                    Messaging
                </AlphaText>
                <FlatList
                    keyExtractor={(item, index) => index.toString()}
                    style={{ flex: 5 }}
                    data={chatListReal}
                    //extraData={doRefresh}
                    renderItem={renderItem}
                ></FlatList>
            </View>
        </SafeAreaView>
    )
}
