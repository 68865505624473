import React, { useState, useEffect } from 'react'
import { View, Modal, Pressable, StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from './../../Colors'
import AlphaText from '../ComponentsShared/TextAlpha'
import { getAuth, sendEmailVerification } from 'firebase/auth'
import ModalAlert from '../ComponentsShared/ModalAlert'
import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'

let done = false

export default function VerifyEmail({ navigation }) {
    const [modalAlert, setModalAlert] = useState(false)
    const [alertModalText, setAlertModalText] = useState('error')
    useEffect(() => {
        save()
    }, [])
    async function save() {
        try {
            AsyncStorage.setItem('creationCheckpoint', 'verifyEmail')
        } catch (err) {
            // console.log(err)
        }
    }
    async function handleContinue() {
        const auth = getAuth()
        const user = auth.currentUser

        if (user !== null) {
            await user.reload()
            const verificationCheck = user.emailVerified
            if (verificationCheck == true) {
                logEvent(analytics, 'finished_verify_email_screen')
                navigation.navigate('GenderSex')
            } else {
                setAlertModalText('Please check your email and click the link before continuing!')
                setModalAlert(true)
            }
        }
    }

    function sendAgain() {
        if (done == false) {
            const auth = getAuth()
            sendEmailVerification(auth.currentUser)
            setModalAlert(true)
            setAlertModalText('We re-sent the verification to the email you provided')
            done = true
        } else if (done == true) {
            setModalAlert(true)
            setAlertModalText(
                'Please double-check email and spam folder. If no luck, please close the app and try signing up again (maybe there was an email typo?)'
            )
        }
    }
    return (
        <SafeAreaView style={{ backgroundColor: COLORS.white, flex: 1, justifyContent: 'center' }}>
            <Modal
                animationType="fade"
                transparent={true}
                visible={modalAlert}
                onRequestClose={() => {
                    setModalAlert(!modalAlert)
                }}
            >
                <ModalAlert setModalAlert={setModalAlert} alertModalText={alertModalText} />
            </Modal>

            <View style={styles.container}>
                <AlphaText
                    style={{
                        fontSize: 35,
                        color: COLORS.nearBlack,
                        // marginTop: 40,
                    }}
                >
                    Email Verification
                </AlphaText>

                <AlphaText
                    style={{
                        marginHorizontal: 30,
                        marginVertical: 30,
                        textAlign: 'center',
                        fontSize: 16,
                        color: COLORS.nearBlack,
                    }}
                >
                    {
                        'An email has been sent to the address you provided. Please click the link in the email and then return to this screen to continue.\n\n\nIf you are unable to find the email please check your spam folder.'
                    }
                </AlphaText>

                <ButtonPrimary onPress={handleContinue} buttonText={'Continue'} />

                <Pressable
                    style={{
                        fontSize: 16,
                        textAlign: 'center',
                        marginTop: 40,
                        marginHorizontal: 40,
                    }}
                    onPress={sendAgain}
                >
                    {({ pressed }) => (
                        <AlphaText
                            style={{
                                textAlign: 'center',
                                fontSize: 16,
                                color: COLORS.nearBlack,
                                textDecorationLine: 'underline',
                                opacity: pressed ? 0.5 : 1,
                            }}
                        >
                            I checked my inbox and spam folder and it wasn't there
                        </AlphaText>
                    )}
                </Pressable>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.white,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },
})
