import React, { useState, useEffect } from 'react'
import { Dimensions, View, Image, Modal, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import * as ImagePicker from 'expo-image-picker'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import 'react-native-get-random-values'
import * as uuid from 'uuid'
import { createLifeLink } from '../../FirebaseMethods'

import { MaterialIcons } from '@expo/vector-icons'

import AsyncStorage from '@react-native-async-storage/async-storage'
import { SafeAreaView } from 'react-native-safe-area-context'
import AddPhotoIcon from './../../assets/icons/AddPhotoIconNB.png'
import ModalPicConfirmation from '../ComponentsUpload/ModalPicConfirmation'
import ModalProcessing from '../ComponentsShared/ModalProcessing'
import ModalAlert from '../ComponentsShared/ModalAlert'
import ModalSave from '../ComponentsShared/ModalSave'
import { COLORS } from './../../Colors'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'
const { width, height } = Dimensions.get('window')
let HS = width / 320
let VS = height / 640

export default function UploadLife({ navigation }) {
    useEffect(() => {
        save()
    }, [])

    const [modalProcessingVisible, setModalProcessingVisible] = useState(false)
    const [modalPicConfirmationVisible, setModalPicConfirmationVisible] = useState(false)
    const [modalSaveVisible, setModalSaveVisible] = useState(false)
    const [modalAlert, setModalAlert] = useState(false)
    const [alertModalText, setAlertModalText] = useState('error')

    // INSTEAD OF THREE DIFFERENT IMAGE STATES, JUST DO ONE OBJECT
    const [imageOne, setImageOne] = useState('')
    const [imageTwo, setImageTwo] = useState('')
    const [imageThree, setImageThree] = useState('')

    //DRY THIS NONSENSE BELOW
    let selectImageAsyncOne = async () => {
        let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (permissionResult.granted === false) {
            setModalAlert(true)
            setAlertModalText('Granting permission to access your image gallery is required to upload photos')
            return
        }
        let pickerResult = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
        })
        setImageOne(pickerResult.assets[0].uri)
    }

    let selectImageAsyncTwo = async () => {
        let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (permissionResult.granted === false) {
            setModalAlert(true)
            setAlertModalText('Granting permission to access your image gallery is required to upload photos')
            return
        }
        let pickerResult = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
        })

        setImageTwo(pickerResult.assets[0].uri)
    }
    let selectImageAsyncThree = async () => {
        let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (permissionResult.granted === false) {
            setModalAlert(true)
            setAlertModalText('Granting permission to access your image gallery is required to upload photos')
            return
        }
        let pickerResult = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
        })
        setImageThree(pickerResult.assets[0].uri)
    }
    const removeImageOne = () => {
        setImageOne('')
    }
    const removeImageTwo = () => {
        setImageTwo('')
    }
    const removeImageThree = () => {
        setImageThree('')
    }
    let handleImagePicked = async (uri, photoNumber) => {
        // console.log(uri);
        try {
            await uploadImageAsync(uri, photoNumber)
        } catch (e) {
            setModalProcessingVisible(false)
            setImageOne('')
            setImageTwo('')
            setImageThree('')

            setModalAlert(true)
            setAlertModalText('Upload failed. Please try again. (Contact admin if error repeats)')
            throw error
        }
    }
    async function uploadImageAsync(uri) {
        try {
            const img = await fetch(uri)
            const bytes = await img.blob()
            const auth = getAuth()
            const uid = auth.currentUser.uid
            const storage = getStorage()
            const remoteUri = 'users/' + uid + '/' + uuid.v4()
            const userRef = ref(storage, remoteUri)
            await uploadBytesResumable(userRef, bytes)
            const toSave = await getDownloadURL(ref(storage, remoteUri))
            let docName = `life${photoNumber}`
            createLifeLink(toSave, docName) //CALLS FIREBASE METHOD TO PUT A LINK TO THEIR PHOTO IN THE APPROPRIATE SECTION OF THEIR PART OF THE DATABASE
        } catch (err) {}
    }
    function uploadConfirmation() {
        setModalPicConfirmationVisible(true)
    }
    function noPhoto() {
        setModalSaveVisible(true)
    }
    async function save() {
        //DON'T FORGET TO CALL save IN THE USEEFFECT ON LOAD
        try {
            AsyncStorage.setItem('creationCheckpoint', 'uploadLife')
        } catch (err) {
            // console.log(err);
        }
    }

    async function saveAndQuit() {
        try {
            AsyncStorage.setItem('creationCheckpoint', 'uploadLife')
            setModalAlert(true)
            setAlertModalText('Account creation has been saved.\nYou may safely close app.')
        } catch (err) {
            setModalAlert(true)
            setAlertModalText(err)
        }
    }
    async function uploadAndProceed() {
        if (imageOne === '' && imageTwo === '' && imageThree === '') {
            setModalAlert(true)
            setAlertModalText('Please select at least one photo before proceeding.')
            setModalPicConfirmationVisible(false)
        } else {
            setModalProcessingVisible(true)
            setModalPicConfirmationVisible(!modalPicConfirmationVisible)
            try {
                if (imageOne != '') {
                    await handleImagePicked(imageOne, '1')
                }
                if (imageTwo != '') {
                    await handleImagePicked(imageTwo, '2')
                }
                if (imageThree != '') {
                    await handleImagePicked(imageThree, '3')
                }
                setModalProcessingVisible(false)
                logEvent(analytics, 'finished_upload_life_screen')
                navigation.navigate('AnswerQuestions')
            } catch {}
        }
    }

    return (
        <SafeAreaView
            style={{
                backgroundColor: COLORS.white,
                flex: 1,
                justifyContent: 'center',
            }}
        >
            <View style={styles.container}>
                <Modal animationType="fade" transparent={true} visible={modalProcessingVisible}>
                    <ModalProcessing />
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalPicConfirmationVisible}
                    onRequestClose={() => {
                        setModalPicConfirmationVisible(!modalPicConfirmationVisible)
                    }}
                >
                    <ModalPicConfirmation
                        setModalPicConfirmationVisible={setModalPicConfirmationVisible}
                        uploadAndProceed={uploadAndProceed}
                        multiplePics={true}
                    />
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalSaveVisible}
                    onRequestClose={() => {
                        setModalSaveVisible(!modalSaveVisible)
                    }}
                >
                    <ModalSave setModalSaveVisible={setModalSaveVisible} saveAndQuit={saveAndQuit} />
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalAlert}
                    onRequestClose={() => {
                        setModalAlert(!modalAlert)
                    }}
                >
                    <ModalAlert setModalAlert={setModalAlert} alertModalText={alertModalText} />
                </Modal>
                <View
                    style={{
                        justifyContent: 'center',
                        flex: 1,
                        alignItems: 'center',
                        // width: "95%",
                    }}
                >
                    <AlphaText
                        style={{
                            fontSize: 30,
                            color: COLORS.darkGray,
                            marginBottom: 10 * VS,
                            marginHorizontal: 10 * HS,
                            textAlign: 'center',
                        }}
                    >
                        Upload More Photos
                    </AlphaText>
                    <View
                        style={{
                            justifyContent: 'center',
                            flex: 1,
                            alignItems: 'center',
                            width: '95%',
                        }}
                    >
                        <AlphaText
                            style={{
                                marginBottom: 10,
                                textAlign: 'center',
                                fontSize: 16,
                                color: COLORS.nearBlack,
                                width: '90%',
                            }}
                        >
                            You may upload up to 3 additional photos. Have fun with it!
                        </AlphaText>
                    </View>
                    {/* <Image
                    style={{ width: 200, height: 131, margin: 7 }}
                    source={require("./../../images/PhotoIDicon.png")}  MAYBE DISPLAY PHOTO THEY'VE SELECTED AS LIKE A CONFIRMATION?
                ></Image> */}
                    <AlphaText style={styles.text2}>Overall rules always apply:</AlphaText>
                    <AlphaText style={styles.text2}>No hate speech/symbols</AlphaText>
                    <AlphaText style={styles.text2}>No below-the-belt nudity</AlphaText>
                    {/* <AlphaText style={styles.text2}>
                        If someone other than yourself is in one of your photos,
                        their face must be covered
                    </AlphaText> */}
                    <AlphaText style={styles.text2}>
                        Do not promote your social media. Any links to your instagram, snapchat, onlyfans, etcetera,
                        will result in an immediate ban.
                    </AlphaText>

                    <View style={{ flexDirection: 'row' }}>
                        <Pressable style={styles.buttonSecondary} onPress={selectImageAsyncOne}>
                            {imageOne === '' && <Image style={styles.photoIcon} source={AddPhotoIcon} />}
                            {imageOne !== '' && (
                                <>
                                    <Image style={styles.photoIcon} source={{ uri: imageOne }} />
                                    <Pressable style={styles.removeIcon} onPress={removeImageOne}>
                                        <MaterialIcons name="close" size={20} color="white" />
                                    </Pressable>
                                </>
                            )}
                        </Pressable>
                        <Pressable style={styles.buttonSecondary} onPress={selectImageAsyncTwo}>
                            {imageTwo === '' && <Image style={styles.photoIcon} source={AddPhotoIcon} />}
                            {imageTwo !== '' && (
                                <>
                                    <Image style={styles.photoIcon} source={{ uri: imageTwo }} />
                                    <Pressable style={styles.removeIcon} onPress={removeImageTwo}>
                                        <MaterialIcons name="close" size={20} color="white" />
                                    </Pressable>
                                </>
                            )}
                        </Pressable>
                        <Pressable style={styles.buttonSecondary} onPress={selectImageAsyncThree}>
                            {imageThree === '' && <Image style={styles.photoIcon} source={AddPhotoIcon} />}
                            {imageThree !== '' && (
                                <>
                                    <Image style={styles.photoIcon} source={{ uri: imageThree }} />
                                    <Pressable style={styles.removeIcon} onPress={removeImageThree}>
                                        <MaterialIcons name="close" size={20} color="white" />
                                    </Pressable>
                                </>
                            )}
                        </Pressable>
                    </View>
                    <Pressable
                        onPress={uploadConfirmation}
                        style={({ pressed }) => [
                            {
                                borderRadius: 5,
                                padding: 8 * VS,
                                marginTop: 15,
                                backgroundColor: COLORS.redButton,
                                opacity: pressed ? 0.5 : 1,
                            },
                        ]}
                    >
                        <AlphaText
                            style={{
                                fontSize: 18,
                                color: COLORS.white,
                                textAlign: 'center',
                            }}
                        >
                            Upload selected photos
                        </AlphaText>
                    </Pressable>
                    <Pressable style={{ marginTop: 10 }} onPress={noPhoto}>
                        {({ pressed }) => (
                            <AlphaText
                                style={{
                                    fontSize: 14,
                                    color: COLORS.redButton,
                                    textDecorationLine: 'underline',
                                    opacity: pressed ? 0.5 : 1,
                                }}
                            >
                                Don't have a photo?
                            </AlphaText>
                        )}
                    </Pressable>
                </View>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    buttonSecondary: {
        marginVertical: 15 * VS,
        marginHorizontal: 5 * HS,
        padding: 2,
        borderWidth: 2,
        borderColor: COLORS.nearBlack,
        borderStyle: 'dashed',
    },

    container: {
        backgroundColor: COLORS.white,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },
    text2: {
        width: '90%',
        marginVertical: 5,
        textAlign: 'center',
        fontSize: 13,
        color: COLORS.nearBlack,
    },
    photoIcon: {
        width: 102 * HS * 0.8,
        height: 136 * HS * 0.8,
        resizeMode: 'contain',
    },
    removeIcon: {
        position: 'absolute',
        top: 5,
        right: 5,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        borderRadius: 10,
        padding: 5,
    },
})
