import React, { useState, useEffect, useRef } from 'react'
import { View, Dimensions, Pressable, ScrollView, Image, Animated } from 'react-native'
import { COLORS } from '../../Colors'
import AlphaText from '../ComponentsShared/TextAlpha'
import Dealbreakers from './Dealbreakers'
import DeepDive from './DeepDive'
import ForFun from './ForFun'
import { LinearGradient } from 'expo-linear-gradient'

const NoIcon = require('../../assets/buttons/NoButtonBorderlessSkinny.png')
const YesIcon = require('../../assets/buttons/YesButtonBorderlessSkinny.png')
const MaybeIcon = require('../../assets/buttons/PauseButtonBorderlessSkinny.png')

// ATTENTION: INDICATORPOSITION/INDICATORTRANSLATE CODE IS CURRENTLY COMMENTED OUT BECAUSE IT'S A BIT LAGGY ON IOS CHROME AND EVEN MORE STUTTERY ON ANDROID CHROME

const TabContent = ({ answers, setReportModal }) => (
    <View style={{ paddingHorizontal: 20, backgroundColor: COLORS.white }}>
        <Dealbreakers answers={answers} />
        <DeepDive answers={answers} />
        <ForFun answers={answers} />
        <View style={{ alignItems: 'center', marginVertical: 25 }}>
            <Pressable onPress={() => setReportModal(true)}>
                <AlphaText>Hide & Report</AlphaText>
            </Pressable>
        </View>
    </View>
)

const TabSymbol = ({ activeTab, index, votes }) => {
    let vote = votes[index + 1]
    if (vote) {
        let imageSource

        switch (vote) {
            case 'no':
                imageSource = NoIcon
                break
            case 'yes':
                imageSource = YesIcon
                break
            case 'skip':
                imageSource = MaybeIcon
                break
        }
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Image
                    key={activeTab === index ? COLORS.redButton : COLORS.darkGray} //this forces it to refresh the image tintcolor - otherwise on all ios browsers the redButton tintcolor is cached so tabs stay red when they shouldn't
                    source={imageSource}
                    style={{
                        marginVertical: 6, //keeps top tab bar from squishing down after all votes are in
                        width: 40,
                        height: 40,
                        resizeMode: 'contain',
                        tintColor: activeTab === index ? COLORS.redButton : COLORS.darkGray,
                    }}
                />
            </View>
        )
    }
    return (
        <AlphaText style={{ fontSize: 35, color: activeTab === index ? COLORS.redButton : COLORS.darkGray }}>
            {index + 1}
        </AlphaText>
    )
}

export default function Swipable({ answers, setReportModal, person, setPerson, votes }) {
    const { width, height } = Dimensions.get('window')
    const tabs = ['1', '2', '3', '4', '5', '6', '7']
    const tabWidth = width / tabs.length

    useEffect(() => {
        // find the first null value in votes
        let firstNullVoteIndex = Object.values(votes).findIndex((vote) => vote === null)
        if (firstNullVoteIndex !== -1) onTabPress(firstNullVoteIndex)
    }, [votes])

    //HORIZONTAL SCROLLING STUFF
    const [activeTab, setActiveTab] = useState(0)
    const scrollRef = useRef()
    // const indicatorPosition = useRef(new Animated.Value(0)).current

    const onTabPress = (index) => {
        setActiveTab(index)
        setPerson(index + 1)
        scrollRef.current.scrollTo({ x: index * width, animated: true })
        // Animated.spring(indicatorPosition, {
        //     toValue: index * tabWidth,
        //     useNativeDriver: true,
        // }).start()
    }
    // const indicatorTranslateX = indicatorPosition.interpolate({
    //     inputRange: [0, width],
    //     outputRange: [0, width],
    // })

    //VERTICAL SCROLLING (GRADIENT) STUFF
    const topGradientOpacity = tabs.map(() => useRef(new Animated.Value(0)).current)
    const bottomGradientOpacity = tabs.map(() => useRef(new Animated.Value(1)).current)

    const onScroll = (event, tabIndex) => {
        const { contentOffset, contentSize, layoutMeasurement } = event.nativeEvent

        const maxScrollOffset = contentSize.height - layoutMeasurement.height
        const scrollOffset = contentOffset.y

        const topGradientOpacityValue = scrollOffset / maxScrollOffset
        const bottomGradientOpacityValue = 1 - topGradientOpacityValue

        topGradientOpacity[tabIndex].setValue(topGradientOpacityValue)
        bottomGradientOpacity[tabIndex].setValue(bottomGradientOpacityValue)
    }
    const gradientColor = 'rgba(134,139,139,0.2)' //the tint of this is based off COLORS.darkGray, it's a bit different than the gradient on photos screen because they look better this way
    const gradientHeight = 100

    return (
        <View style={{ flex: 1 }}>
            {/* TABBAR */}
            <View style={{ flexDirection: 'row', height: 70 }}>
                {tabs.map((tab, index) => (
                    <Pressable
                        key={index}
                        onPress={() => onTabPress(index)}
                        style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
                    >
                        <TabSymbol activeTab={activeTab} index={index} votes={votes} />
                    </Pressable>
                ))}
                {/* <Animated.View
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        height: 2,
                        width: tabWidth,
                        backgroundColor: COLORS.redButton,
                        transform: [{ translateX: indicatorTranslateX }],
                    }}
                /> */}
            </View>
            {/* HORIZONTAL SCROLLER */}
            <ScrollView
                ref={scrollRef}
                horizontal
                showsHorizontalScrollIndicator={false}
                pagingEnabled
                onScroll={({ nativeEvent }) => {
                    const scrollOffset = nativeEvent.contentOffset.x
                    const tabOffset = (tabWidth / width) * scrollOffset
                    // indicatorPosition.setValue(tabOffset)
                    const index = Math.round(scrollOffset / width)
                    if (index !== activeTab) {
                        setActiveTab(index)
                        setPerson(index + 1)
                    }
                }}
                scrollEventThrottle={50}
            >
                {tabs.map((tab, index) => {
                    return (
                        // VERTICAL SCROLLER
                        <View style={{ flex: 1 }} key={index}>
                            <ScrollView
                                onScroll={(event) => onScroll(event, index)}
                                showsVerticalScrollIndicator={false}
                                contentContainerStyle={{
                                    width: width,
                                    flexGrow: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                scrollEventThrottle={50}
                            >
                                <TabContent answers={answers[index + 1]} setReportModal={setReportModal} />
                            </ScrollView>
                            <Animated.View
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    height: gradientHeight,
                                    opacity: topGradientOpacity[index],
                                }}
                                pointerEvents={'none'}
                            >
                                <LinearGradient colors={[gradientColor, 'transparent']} style={{ flex: 1 }} />
                            </Animated.View>
                            <Animated.View
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    height: gradientHeight,
                                    opacity: bottomGradientOpacity[index],
                                }}
                                pointerEvents={'none'}
                            >
                                <LinearGradient colors={['transparent', gradientColor]} style={{ flex: 1 }} />
                            </Animated.View>
                        </View>
                    )
                })}
            </ScrollView>
        </View>
    )
}
