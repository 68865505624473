import React, { useState, useEffect, useCallback, useContext } from 'react'
import { Modal, Pressable, FlatList, StyleSheet, View, Image, TextInput } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from '../../Colors'
import sendArrow from 'assets/buttons/sendArrow.png'
import { db } from './../../FirebaseInitialization'
import { addDoc, setDoc, doc, query, collection, onSnapshot, serverTimestamp } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { MaterialIcons, AntDesign } from '@expo/vector-icons'
import ModalReport from '../ComponentsShared/ModalReport'
import { ScreenSizeContext } from './../../ScreenSizeContext'

import * as uuid from 'uuid'

// CREATE A WAY FOR PEOPLE TO DELETE CHATS OR UNMATCH
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// ATTENTION: THE UI IS BROKEN IN FIREFOX ON PC (have to scroll right to see button), BUT SEEMS TO WORK OKAY ELSEWHERE (NEED TO DO THOROUGH MOBILE TESTING)

export default function Messaging({ route, navigation }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    async function backButton() {
        if (chatDocName !== 'loading') {
            await setDoc(
                doc(db, 'chats', chatDocName),
                {
                    userLastSeen: { [uid]: serverTimestamp() },
                },
                { merge: true }
            )
        }
        navigation.navigate('MessagingMenu')
    }

    function reportButton() {
        setReportModal(true)
    }

    const { matchsUID } = route.params

    const [selectedId, setSelectedId] = useState(null)
    const [newText, onChangeText] = useState('')
    const [chatDocName, setChatDocName] = useState('loading')
    const [chats, setChats] = useState([])
    const [reportModal, setReportModal] = useState(false)

    const auth = getAuth()
    const uid = auth.currentUser.uid

    useEffect(() => {
        let chatName = 'placeholder'
        if (uid > matchsUID) {
            chatName = uid + '_' + matchsUID
        } else {
            chatName = matchsUID + '_' + uid
        }
        setDoc(
            doc(db, 'chats', chatName),
            {
                userLastSeen: { [uid]: serverTimestamp() },
            },
            { merge: true }
        )
        setChatDocName(chatName)

        // this loads the chat messages here using chatName, not chatDocName, since chatDocName it may not have updated yet
        const q = query(collection(db, 'chats', chatName, 'messages'))
        const unsubscribe = onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === 'added') {
                    const data = change.doc.data()
                    setChats((chats) => [
                        //{ ...data }
                        { id: data.id, createdAt: data.createdAt, text: data.text, sender: data.sender },
                        ...chats,
                    ])
                }
            })
        })
        return () => {
            unsubscribe()
        }
    }, [])

    const sendPressed = () => {
        if (newText !== '') {
            const uniqueId = uuid.v4()
            onChangeText('')

            setTimeout(() => {
                flatListRef.current.scrollToIndex({ index: 0, animated: true })
            }, 0)

            addDoc(collection(db, 'chats', chatDocName, 'messages'), {
                id: uniqueId,
                createdAt: serverTimestamp(),
                text: newText,
                sender: uid,
            })
        }
    }

    const flatListRef = React.useRef(null)

    const renderItem = ({ item }) => {
        const otherUser = !item.sender.includes(uid)
        const backgroundColor = otherUser ? COLORS.lightRed : COLORS.lightGray
        const color = otherUser ? COLORS.nearBlack : COLORS.nearBlack
        const alignSelf = otherUser ? 'flex-start' : 'flex-end'
        return (
            <Pressable
                onPress={() => setSelectedId(item.id)}
                style={[styles.textBubble, { backgroundColor: backgroundColor, alignSelf: alignSelf }]}
            >
                <AlphaText style={[styles.text, { color: color }]}>{item.text}</AlphaText>
            </Pressable>
        )
    }

    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white, justifyContent: 'flex-end' }}>
            <Modal visible={reportModal} animationType="fade" transparent={true}>
                <ModalReport
                    setReportModal={setReportModal}
                    demo={false}
                    uidOfProfile={matchsUID}
                    screen={'messaging'}
                />
            </Modal>
            <View
                //setting margin to auto sorta overrides flexbox
                style={{ flex: 1, flexDirection: 'row', zIndex: 999, position: 'absolute', top: 20, left: 20 }}
            >
                <Pressable
                    onPress={() => backButton()}
                    style={({ pressed }) => [
                        {
                            opacity: pressed ? 0.5 : 1,
                            backgroundColor: COLORS.white,
                            borderRadius: 25,
                            paddingVertical: 10,
                            paddingRight: 7,
                            paddingStart: 3,
                        },
                    ]}
                >
                    <AntDesign name="left" size={40} color={COLORS.redButton} />
                </Pressable>
            </View>
            <View
                //setting margin to auto sorta overrides flexbox
                style={{ flex: 1, flexDirection: 'row', zIndex: 999, position: 'absolute', top: 20, right: 20 }}
            >
                <Pressable
                    onPress={() => reportButton()}
                    style={({ pressed }) => [
                        {
                            opacity: pressed ? 0.5 : 1,
                            backgroundColor: COLORS.white,
                            borderRadius: 25,
                            paddingVertical: 10,
                            paddingRight: 7,
                            paddingStart: 3,
                        },
                    ]}
                >
                    <MaterialIcons name="report" size={40} color={COLORS.redButton} />
                </Pressable>
            </View>
            <View style={{ flex: 1, zIndex: 0 }}>
                <FlatList
                    contentContainerStyle={{ flexGrow: 1 }}
                    ref={flatListRef}
                    data={chats}
                    renderItem={renderItem}
                    keyExtractor={(item) => item.id}
                    extraData={selectedId}
                    inverted
                />
                <View style={{ flexDirection: 'row', borderTopWidth: 1, marginTop: 10 }}>
                    <TextInput
                        placeholder="Type Message"
                        placeholderTextColor={COLORS.darkGray}
                        style={{
                            padding: 20,
                            fontFamily: 'Medium',
                            flex: 1,
                            fontSize: 18 * ((VS + HS) / 2) * 0.85, //if you update the calculations on this line, need to update alphatext and other textinputs
                        }}
                        onChangeText={onChangeText}
                        value={newText}
                        multiline={true}
                    />
                    <Pressable
                        onPress={sendPressed}
                        style={({ pressed }) => [
                            {
                                backgroundColor: COLORS.redButton,
                                marginLeft: 10,
                                marginRight: 20,
                                alignSelf: 'center',
                                borderRadius: 100,
                                paddingRight: 8,
                                paddingLeft: 15,
                                paddingVertical: 14,
                                opacity: pressed ? 0.5 : 1,
                            },
                        ]}
                    >
                        <Image source={sendArrow} style={{ resizeMode: 'contain', width: 30, height: 25 }} />
                    </Pressable>
                </View>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    textBubble: {
        borderRadius: 10,
        padding: 10,
        marginVertical: 2,
        marginHorizontal: 16,
        maxWidth: '80%',
        // flex: 1,
        // flexShrink: 1,
    },
    text: {
        fontSize: 18,
    },
})
