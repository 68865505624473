import React, { useContext } from 'react'
import { ScreenSizeContext } from './../../ScreenSizeContext'
import { Color, View } from 'react-native'
import { COLORS } from '../../Colors'

export default function ModalPrimary({ children, photoUpload }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    return (
        <View
            style={{
                backgroundColor: photoUpload ? COLORS.lightModalSurroundings : COLORS.modalSurroundings,
                flex: 1,
                justifyContent: 'center',
                paddingBottom: photoUpload ? 200 : 0,
            }}
        >
            <View
                style={{
                    backgroundColor: COLORS.lightGreen,
                    padding: 30 * HS,
                    margin: 20,
                    borderRadius: 10,
                    alignItems: 'center',
                }}
            >
                {children}
            </View>
        </View>
    )
}

ModalPrimary.defaultProps = {
    photoUpload: false,
}
