import React, { useContext } from 'react'
import { View, Dimensions, StyleSheet, TextInput } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { mandatory } from '../onboarding/AnswerQuestions'
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function TypedAnswer({ questionName, questionText, answerPrompt, answers, setAnswers }) {
    const text = answers[questionName] || ''
    const { HS, VS } = useContext(ScreenSizeContext)

    const handleChange = (value) => {
        // When the input changes, update the answers state in the parent.
        if (!value.includes('♥')) {
            setAnswers({ ...answers, [questionName]: value })
        }
    }
    return (
        <View style={{ marginTop: 10 }}>
            {/* //this marginTop sets top gap between different TypedAnswers and Dropdowns */}

            <View style={{ marginLeft: 10 }}>
                <AlphaText style={styles.questionText}>{questionText}</AlphaText>
            </View>
            <View style={{ marginLeft: 10, marginBottom: 0, marginTop: 0 }}>
                {answers[questionName] === '' && (
                    <AlphaText style={{ fontSize: 14, color: COLORS.darkGray, fontStyle: 'italic' }}>
                        {answerPrompt}
                    </AlphaText>
                )}
            </View>
            <View style={{ marginLeft: 10, marginBottom: 0, marginTop: 0 }}>
                {answers[questionName] !== '' && (
                    <AlphaText style={{ fontSize: 14, color: COLORS.darkGray, fontStyle: 'italic' }}>
                        {answerPrompt.slice(0, -3) + ' ' + answers[questionName]}
                    </AlphaText>
                )}
            </View>
            <View
                style={[
                    styles.largeBox,
                    { borderWidth: mandatory.includes(questionName) ? 2 : 0, borderColor: COLORS.redButton },
                ]}
            >
                <TextInput
                    style={{
                        color: COLORS.darkGray,
                        fontFamily: 'Medium',
                        fontSize: 14 * ((VS + HS) / 2) * 0.85, //if you update the calculations on this line, need to update alphatext and other textinputs
                        marginTop: 5,
                        height: 40,
                        backgroundColor: COLORS.white,
                        borderRadius: 8,
                        paddingHorizontal: 7,
                    }}
                    onChangeText={handleChange}
                    value={text}
                    // placeholder={questionText}
                    maxLength={120}
                />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    largeBox: {
        backgroundColor: COLORS.white,
        borderRadius: 8,
        paddingTop: 4,
        paddingBottom: 6,
        paddingHorizontal: 8,
        flexDirection: 'column',
        marginBottom: 10, //sets bottom gap between different TypedAnswers and Dropdowns
    },
    questionText: {
        color: COLORS.nearBlack,
        fontSize: 14,
    },
})
