import React, { useEffect, useState } from 'react'
import AlphaText from '../ComponentsShared/TextAlpha'
import ButtonPrimary from '../ComponentsShared/ButtonPrimary'
import { View, Dimensions, ScrollView, Pressable, StyleSheet, Modal } from 'react-native'
import { db } from './../../FirebaseInitialization'
import { setDoc, doc } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

// import SelectDropdown from "react-native-select-dropdown";

import AsyncStorage from '@react-native-async-storage/async-storage'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from './../../Colors'
import Dropdown from '../ComponentsAnswerQuestions/Dropdown'
import TypedAnswer from '../ComponentsAnswerQuestions/TypedAnswer'
import ForFun from '../ComponentsAnswerQuestions/ForFun'
import ModalIntro1 from '../ComponentsAnswerQuestions/ModalIntro1'
import ModalIntro2 from '../ComponentsAnswerQuestions/ModalIntro2'
import ModalConfirmation from '../ComponentsAnswerQuestions/ModalConfirmation'
import ModalSave from '../ComponentsShared/ModalSave'
import ModalAlert from '../ComponentsShared/ModalAlert'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'

// WHEN YOU UPDATE THIS, NEED TO UPDATE PROFILEANSWERSEDITOR UNTIL YOU REFACTOR SO THEY HAVE COMMON COMPONENTS

export let mandatory = ['dealbreaker', 'religion1', 'religion2', 'drugs1', 'marriage1', 'kids2', 'kids3', 'kids4']

export default function AnswerQuestions({ navigation }) {
    function handleBackButtonClick() {
        return true
    }

    const [modalIntro1Visible, setModalIntro1Visible] = useState(true) //RE-ENABLE THIS TO TRUE - DISABLED FOR TESTING
    const [modalIntro2Visible, setModalIntro2Visible] = useState(false)
    const [modalConfirmationVisible, setModalConfirmationVisible] = useState(false)
    const [sampleDisplay, setSampleDisplay] = useState('')
    const [modalAlert, setModalAlert] = useState(false)
    const [alertModalText, setAlertModalText] = useState('error')

    const religion1Q = 'What is your religion?'
    const religion1A = [
        'Not religious',
        'Evangelical Protestant',
        'Mainline Protestant',
        'Catholic',
        'Christian (other)',
        'Orthodox Christian',
        'Mormon',
        "Jehovah's Witness",
        'Jewish',
        'Jewish (Reform)',
        'Jewish (Conservative)',
        'Jewish (Orthodox)',
        'Muslim (Sunni)',
        'Muslim (Shia)',
        'Buddhist',
        'Hindu',
        'Pagan or Wiccan',
        'Spiritual but not religious',
        'Religious (religion not listed)',
    ]
    const religion2Q = 'How important are your religious beliefs to you?'
    const religion2A = [
        'the most important thing',
        'extremely important',
        'important',
        'not super important',
        'nonexistent',
    ]
    const religion3Q = "Do you care what your partner's religion is?"
    const religion3A = [
        'must be the same as mine',
        'is fairly important to relationship',
        "isn't a huge factor",
        "doesn't affect relationship",
    ]
    const astrologyQ = 'Do you partake in Astrology?'
    const astrologyA = [
        'Nope',
        "Yes, I'm an Aquarius",
        "Yes, I'm a Pisces",
        "Yes, I'm an Aries",
        "Yes, I'm a Taurus",
        "Yes, I'm a Gemini",
        "Yes, I'm a Cancer",
        "Yes, I'm a Leo",
        "Yes, I'm a Virgo",
        "Yes, I'm a Libra",
        "Yes, I'm a Scorpio",
        "Yes, I'm a Sagittarius",
        "Yes, I'm a Capricorn",
    ]
    const career1Q = 'What is your highest completed degree?'
    const career1A = [
        'None yet',
        'GED',
        'High School Diploma',
        'Associate Degree',
        "Bachelor's Degree",
        "Master's Degree",
        'PhD, JD, or MD',
    ]
    const political1Q = 'How would you describe yourself politically?'
    const political1A = [
        'Liberal',
        'Conservative',
        'Moderate',
        'Democrat',
        'Republican',
        'Libertarian',
        'Trump Supporter',
        'Desantis Supporter',
        'Bernie Supporter',
        'Socialist',
        'Communist',
        'Marxist',
        'Tea Party Supporter',
        'Red Quadrant',
        'Blue Quadrant',
        'Green Quadrant',
        'Yellow Quadrant',
        'Apolitical',
    ]
    const political2Q = 'How political are you?'
    const political2A = [
        'Politics are a very big part of who I am',
        'I actively support political causes I care about',
        'I talk, watch, or post about politics frequently',
        "My beliefs matter, but I don't engage much",
        "I don't really care about politics that much",
        'I actively dislike the mention of politics',
    ]
    const political3Q = "How important is your partner's politics?"
    const political3A = [
        'Their political views should be similar to mine',
        'Our political views need some common ground',
        "I'm not picky as long as they tolerate my views",
        "I'm willing to adapt my political views to theirs",
    ]

    const fun3Q = 'Friends might describe you as...'
    const fun3A = [
        '🃏 the wild card',
        '😬 paranoid',
        '🏄 too sexy for my shirt',
        '😉 a sociopath',
        '🤸 fabulous',
        '👓 intelligent',
        '🙃 two-faced',
        '💣 a true ride or die chick',
        '🧨 a prankster',
        '❔ indecisive',
        '🤗 caring',
        '🔫 a real G',
        '🎩 honest',
        '😦 dumb',
        '😘 flirtatious',
        '🥛 an optimist',
        '🥛 a pessimist',
        '🕴️ a god',
        '🍛 kanin na may hipon',
        '🥳 a party animal',
        '🔥 sexual napalm',
        '🙂 considerate',
        '📚 a lifelong learner',
        '😎 charismatic',
        '🤭 shy',
        '🙄 over this',
        '😇 a narcissist',
        '🎊 a talking pinata',
        '👂 a good listener',
        '🐕 a dog aficionado',
        '🐈 a cat servant',
        '🥇 the greatest',
        '🌿 420 friendly',
        '🗑️ basically a mess',
        '🎓 studious',
        '🥂 effervescent',
        '🍯 having notes of honey',
        '👬 their friend',
        '😐 a Zoe',
        '😄 a Zelda',
        '🐆 a man-cheetah',
        '🏢 the Jim to your Pam',
        '🏢 the Pam to your Jim',
    ]
    const psych1Q = 'How involved in your personal life is your family?'
    const psych1A = [
        "No living relatives or I've gone no-contact",
        'Rarely communicate with any of my family',
        "I listen to family's opinions but decide myself",
        'I have final say, but family influences me',
        "It's vital that my family approves of you",
        'Taking care of my parents is my first priority',
        'Ultimately, I have to do what my parents say',
    ]
    const psych2Q = 'How much mental health therapy have you done?'
    const psych2A = ['none', '1-10 sessions', '10+ sessions']
    // const psych3Q = "How smart do you think you are?"    // const psych3A = ["genius", "top 10% of population", "above average", "slightly above average", "basically average", "slightly below average", "below average", "bottom 10%, not disabled", "intellectually disabled"]   // const psych4Q = "How smart do others think you are?"    // const psych4A = ["genius", "top 10% of population", "above average", "slightly above average", "basically average", "slightly below average", "below average", "bottom 10%, not disabled", "intellectually disabled"]
    const drugs1Q = 'When do you use tobacco products?'
    const drugs1A = ['never', "only when I'm drunk", 'sometimes', "often, but I'm quitting", 'daily']

    const marriage1Q = 'Do you want to get married someday?'
    const marriage1A = ['Yes', 'Probably', 'Probably not', 'No']
    const marriage2Q = "When's the earliest you'd consider marriage?"
    const marriage2A = [
        'ASAP',
        '0-2 years',
        '2-5 years',
        '5-10 years',
        '10-15 years',
        '15-20 years',
        "What's the rush?",
        'Not applicable',
    ]
    const sex1Q = 'Premarital sex?'
    const sex1A = ['Yes, please', 'No, thank you']
    const sex2Q = 'Ideal number of times per week to have sex?'
    const sex2A = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15+'] //could do  brackets

    const kids1Q = 'How many kids do you have already?'
    const kids1A = ['0', '1', '2', '3', '4', '5', '6', '7+']
    const kids2Q = 'Do you want more kids then you currently have?'
    const kids2A = ['Yes', 'Probably', 'Probably not', 'No']
    const kids3Q = 'How many kids (total) do you consider ideal?'
    const kids3A = ['0', '1', '2', '3', '4', '5', '6', '7+']
    const kids4Q = 'When would you like to have first (or next) child?'
    const kids4A = ['ASAP', '0-2 years', '2-5 years', '5-10 years', '10-15 years', '15-20 years', 'Not applicable']
    const relationship1Q = "How do you feel about partner's finances?"
    const relationship1A = [
        'I want partner to support me financially',
        'I want partner who can support self financially',
        "I don't mind supporting my partner financially",
    ]

    const forFun = [
        `My ideal first date...`,
        `I love when my date...`,
        `My most embarrassing date moment was...`,
        `The best meal I've ever had...`,
        `My go-to cheesy joke is...`,
        `My go-to podcast right now is...`,
        `My go-to song right now is...`,
        `My favorite book is...`,
        `The best vacation I've ever taken was...`,
        `My dream wedding...`,
        `My role model is...`,
        `My guilty pleasure movie(s) would be...`,
        `If I could live anywhere, I would live...`,
        `The last time I cried...`,
        `Two Truths and a Lie...`,
        `We are meant to be if you like...`,
        `The one thing I cannot live without is...`,
        `My bucket list has to include...`,
        `The craziest thing I've ever done...`,
        `One thing no one would guess about me...`,
        `My favorite hobby is...`,
        `My least favorite hobby is...`,
        `If I won a million dollars, I would...`,
        `The best advice I've ever gotten...`,
        `My celebrity crush(es)...`,
        `My ideal Sunday would be...`,
        `I hadn't lived until I tried...`,
        `I get too competitive over...`,
    ]
    //OLD NOTES ON QUESTIONS
    // Have former trumpists moved on? do you need a new word for them to select?
    // "Other", //If many people pick other, should probably axe it. Force people to pick something somewhat descriptive
    // "Leninist", too niche  SEE WHICH RESPONSES ARE MOST COMMON AND REMOVE THE ONES THAT DON'T SEE ANY ACTION. EASY TO DO RETROACTIVELY SINCE IT WON'T AFFECT EXISTING USERS
    // "Their political views must closely match mine",Axed on Jackie's recommendation because there are too many similar //SOME ANSWERS ARE HARDCODED TO BE MODIFIED TO REPLACE "THEIR" WITH "YOUR"
    // "Partner's views shouldn't be too extreme", Axed on Jackie's recommendation because there are too many similar

    // const [modalSaveVisible, setModalSaveVisible] = useState(false)
    // function Panic() {
    //     setModalSaveVisible(true)
    // }
    // const handleSave = () => {
    //     //DISABLED FOR NOW. SEE IF ANYONE ASKS FOR IT
    //     uploadAnswers()
    //     setModalAlert(true)
    //     setAlertModalText('Profile Progress Saved')
    // }
    // async function saveAndQuit() {
    //     try {
    //         AsyncStorage.setItem('creationCheckpoint', 'answerQuestions')
    //         setModalAlert(true)
    //         setAlertModalText('Progress up to this point saved.\nAnswers on this screen not saved.')
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    // function containsAtLeastEightLetters(str) {
    //     const matches = str.match(/[a-z]/gi)
    //     return matches && matches.length >= 2
    // }  TWO THINGS: 1. this breaks for dropdown questions that have numbers for answers or only a few char 2. you'd need to clearly communicate the problem to users or it's very awful to be stopped by invisible wall (unknown issue)

    function handleConfirmation() {
        // let text1 = 'show sample screen here as it will be displayed to others!'
        setModalConfirmationVisible(true)
    }

    const isAllMandatoryAnswered = () => {
        for (const [key, value] of Object.entries(answers)) {
            if (mandatory.includes(key) && value === '') {
                return false
            }
        }
        return true
    }

    useEffect(() => {
        console.log(answers)
    }, [answers])

    const handleContinue = async () => {
        if (isAllMandatoryAnswered()) {
            const auth = getAuth()
            const currentUser = auth.currentUser
            // blanks out the forFun questions unless they have both a question and an answer
            let newAnswers = { ...answers }
            for (let i = 1; i <= 3; i++) {
                let prop = 'forFun' + i
                if (newAnswers.hasOwnProperty(prop)) {
                    let obj = newAnswers[prop]
                    if (obj.question === '' || obj.answer === '') {
                        newAnswers[prop] = { question: '', answer: '' }
                    }
                }
            }
            try {
                console.log("I'M DISABLED")
                // TEMPORARILY DISABLED FOR TESTING
                // await setDoc(doc(db, 'users', currentUser.uid, 'answers', 'answers'), { answers: newAnswers })
                // setModalConfirmationVisible(false)
                // logEvent(analytics, 'finished_answer_questions_screen')
                // navigation.navigate('Authentication')
            } catch (err) {
                setModalConfirmationVisible(false)
                setModalAlert(true)
                setAlertModalText('Error. Please try again.', err.message)
            }
        } else {
            setModalConfirmationVisible(false)
            setModalAlert(true)
            setAlertModalText('Please answer all red-bordered questions before proceeding') //GIVE A MORE HELPFUL TIP HERE (SCROLL TO FIRST UNANSWERED AND/OR DISPLAY NAMES OF UNANSWERED)
        }
    }

    const showNextModal = () => {
        setModalIntro1Visible(!modalIntro1Visible)
        setModalIntro2Visible(true)
    }

    const [answers, setAnswers] = useState({
        religion1: '',
        religion2: '',
        religion3: '',
        astrology: '',
        career1: '',
        career2: '',
        career3: '',
        political1: '',
        political2: '',
        political3: '',
        food1: '',
        food2: '',
        fun1: '',
        fun2: '',
        fun3: '',
        dealbreaker: '',
        psych1: '',
        psych2: '',
        psych3: '',
        psych4: '',
        drugs1: '',
        drugs2: '',
        drugs3: '',
        relationship1: '',
        relationship2: '',
        relationship3: '',
        marriage1: '',
        marriage2: '',
        sex1: '',
        sex2: '',
        sex3: '',
        kids1: '',
        kids2: '',
        kids3: '',
        kids4: '',
        forFun1: { question: '', answer: '' },
        forFun2: { question: '', answer: '' },
        forFun3: { question: '', answer: '' },
    })

    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.lightGreen }}>
            <View style={{ flex: 1, backgroundColor: COLORS.lightGreen }}>
                <Modal animationType="fade" transparent={true} visible={modalIntro1Visible}>
                    <ModalIntro1 showNextModal={showNextModal} />
                </Modal>
                <Modal animationType="fade" transparent={true} visible={modalIntro2Visible}>
                    <ModalIntro2 setModalIntro2Visible={setModalIntro2Visible} />
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalConfirmationVisible}
                    onRequestClose={() => {
                        setModalConfirmationVisible(!modalConfirmationVisible)
                    }}
                >
                    <ModalConfirmation
                        setModalConfirmationVisible={setModalConfirmationVisible}
                        handleContinue={handleContinue}
                        answers={answers}
                    />
                </Modal>
                {/* <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalSaveVisible}
                    onRequestClose={() => {
                        setModalSaveVisible(!modalSaveVisible)
                    }}
                >
                    <ModalSave setModalSaveVisible={setModalSaveVisible} />
                </Modal> */}
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalAlert}
                    onRequestClose={() => {
                        setModalAlert(!modalAlert)
                    }}
                >
                    <ModalAlert setModalAlert={setModalAlert} alertModalText={alertModalText} />
                </Modal>

                <AlphaText style={{ fontSize: 40, color: COLORS.nearBlack, textAlign: 'center' }}>
                    Question Time
                </AlphaText>
                <ScrollView vertical contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 10 }}>
                    <AlphaText style={{ textAlign: 'center', fontSize: 16 }}>
                        {'Please answer all questions for best results.'}
                    </AlphaText>
                    <AlphaText style={{ textAlign: 'center', fontSize: 16, color: COLORS.redButton, marginTop: 5 }}>
                        {'Red-bordered questions are required.'}
                    </AlphaText>
                    <AlphaText style={{ textAlign: 'center', fontSize: 16, color: COLORS.redButton, marginTop: 5 }}>
                        {'Do not include your name, social handles, or'}
                    </AlphaText>
                    <AlphaText style={{ textAlign: 'center', fontSize: 16, color: COLORS.redButton }}>
                        {'any urls in answers or you will be banned.'}
                    </AlphaText>
                    <AlphaText style={{ textAlign: 'center', fontSize: 20, marginTop: 5 }}>{'Thank you!'}</AlphaText>
                    <AlphaText style={styles.headings}>{'About You (Demographic)'}</AlphaText>
                    <Dropdown
                        questionName={'religion1'}
                        questionText={religion1Q}
                        choicesArray={religion1A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'religion2'}
                        questionText={religion2Q}
                        choicesArray={religion2A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'religion3'}
                        questionText={religion3Q}
                        choicesArray={religion3A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'astrology'}
                        questionText={astrologyQ}
                        choicesArray={astrologyA}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'career1'}
                        questionText={career1Q}
                        choicesArray={career1A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <TypedAnswer
                        answerPrompt={'My current job/school is...'}
                        questionName={'career2'}
                        questionText={"What's your current job (or school enrollment)?"}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <TypedAnswer
                        answerPrompt={'My dream job is...'}
                        questionName={'career3'}
                        questionText={"What's your dream job?"}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'political1'}
                        questionText={political1Q}
                        choicesArray={political1A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'political2'}
                        questionText={political2Q}
                        choicesArray={political2A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'political3'}
                        questionText={political3Q}
                        choicesArray={political3A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />

                    <AlphaText style={styles.headings}>{'About You (Personal)'}</AlphaText>
                    <TypedAnswer
                        answerPrompt={'I eat...'}
                        questionName={'food1'}
                        questionText={'What foods do you eat and which do you avoid?'}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <TypedAnswer
                        answerPrompt={'Cooking: ...'}
                        questionName={'food2'}
                        questionText={'Do you cook? Are you skilled?'}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <TypedAnswer
                        answerPrompt={'For fun I...'}
                        questionName={'fun1'}
                        questionText={'What do you do for fun?'}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <TypedAnswer
                        answerPrompt={'I always laugh at...'}
                        questionName={'fun2'}
                        questionText={'Favorite comedians? Or comedy movie/tv?'}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'fun3'}
                        questionText={fun3Q}
                        choicesArray={fun3A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    {/* <TypedAnswer
answerPrompt={''} questionName={"fun4"} questionText={"Average hours per day on social media?"} answers={answers} setAnswers={setAnswers} /> */}
                    {/* <TypedAnswer
answerPrompt={''} questionName={"fun5"} questionText={"How many books do you read per year?"} answers={answers} setAnswers={setAnswers} /> */}
                    <Dropdown
                        questionName={'psych1'}
                        questionText={psych1Q}
                        choicesArray={psych1A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <TypedAnswer
                        answerPrompt={'A philosophy I find compelling is...'}
                        questionName={'psych3'}
                        questionText={'What ideas or philosophies appeal to you?'}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <TypedAnswer
                        answerPrompt={''} //intentionally left blank
                        questionName={'dealbreaker'}
                        questionText={'What about you is most likely to be a dealbreaker for potential partners?'}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'psych2'}
                        questionText={psych2Q}
                        choicesArray={psych2A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <TypedAnswer
                        answerPrompt={'Diagnoses: ...'}
                        questionName={'psych4'}
                        questionText={'Do you have any mental health diagnoses?'}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'drugs1'}
                        questionText={drugs1Q}
                        choicesArray={drugs1A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <TypedAnswer
                        answerPrompt={'Alcohol per week: ...'}
                        questionName={'drugs2'}
                        questionText={'How many alcoholic drinks do you have in the average week?'}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <TypedAnswer
                        answerPrompt={'Drugs: ...'}
                        questionName={'drugs3'}
                        questionText={'Any other drug usage worth mentioning?\n(you can be vague)'}
                        answers={answers}
                        setAnswers={setAnswers}
                    />

                    <AlphaText style={styles.headings}>Relationship</AlphaText>
                    <TypedAnswer
                        answerPrompt={'Q:  Believe in predestined soulmates? A: ...'}
                        questionName={'relationship2'}
                        questionText={'Do you believe in predestined soulmates?'}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'relationship1'}
                        questionText={relationship1Q}
                        choicesArray={relationship1A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <TypedAnswer
                        answerPrompt={'Q:  Couples counseling? A: ...'}
                        questionName={'relationship3'}
                        questionText={'How do you feel about couples counseling?'}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'marriage1'}
                        questionText={marriage1Q}
                        choicesArray={marriage1A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'marriage2'}
                        questionText={marriage2Q}
                        choicesArray={marriage2A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'sex1'}
                        questionText={sex1Q}
                        choicesArray={sex1A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'sex2'}
                        questionText={sex2Q}
                        choicesArray={sex2A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <TypedAnswer
                        answerPrompt={'Kinks: ...'}
                        questionName={'sex3'}
                        questionText={'What kinks are you into?'}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'kids1'}
                        questionText={kids1Q}
                        choicesArray={kids1A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'kids2'}
                        questionText={kids2Q}
                        choicesArray={kids2A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'kids3'}
                        questionText={kids3Q}
                        choicesArray={kids3A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <Dropdown
                        questionName={'kids4'}
                        questionText={kids4Q}
                        choicesArray={kids4A}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <AlphaText style={styles.headings}>{'Pick Your Own'}</AlphaText>
                    <AlphaText style={{ color: COLORS.nearBlack, textAlign: 'center', fontSize: 16 }}>
                        {`These Q&A's will be displayed in the "For Fun" section at the bottom of your text profile`}
                    </AlphaText>
                    <ForFun questionNumber={1} questionsArray={forFun} answers={answers} setAnswers={setAnswers} />
                    <ForFun questionNumber={2} questionsArray={forFun} answers={answers} setAnswers={setAnswers} />
                    <ForFun questionNumber={3} questionsArray={forFun} answers={answers} setAnswers={setAnswers} />
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            paddingBottom: 15,
                            marginBottom: 10,
                        }}
                    >
                        <ButtonPrimary buttonText={'Continue'} onPress={handleConfirmation} />
                    </View>
                </ScrollView>
            </View>
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    headings: {
        fontSize: 24,
        marginTop: 10,
        color: COLORS.nearBlack,
        textAlign: 'center',
    },
})
