import { useState, useContext } from 'react'
import { View, Pressable, TextInput, Modal } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { styles } from './StylesSettings'
import { db } from './../../FirebaseInitialization'
import { doc, getDoc, getDocs, deleteDoc } from 'firebase/firestore'
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, deleteUser } from 'firebase/auth'
import { getStorage, ref, deleteObject, listAll } from 'firebase/storage'
import ModalAlert from '../ComponentsShared/ModalAlert'
import { ScreenSizeContext } from './../../ScreenSizeContext'

export default function ModalDeleteConfirmation({
    setModalDeleteConfirmation,
    setModalDeletionSuccess,
    demo,
    navigation,
}) {
    const { HS, VS } = useContext(ScreenSizeContext)

    const [passwordGiven, setPasswordGiven] = useState('')
    const [modalAlert, setModalAlert] = useState(false)
    const [alertModalText, setAlertModalText] = useState('possible error')

    const handleDeletion = async () => {
        if (demo) {
            setModalDeletionSuccess(true)
            setModalDeleteConfirmation(false)
        } else if (!demo) {
            await deleteForReal()
        }
    }

    const deleteForReal = async function () {
        const password = passwordGiven
        const auth = getAuth()
        const user = auth.currentUser
        const credential = EmailAuthProvider.credential(user.email, password)
        try {
            await reauthenticateWithCredential(user, credential)
            try {
                const uid = user.uid

                // deletes photos from storage
                const storage = getStorage()
                const listRef = ref(storage, `users/${uid}`)
                listAll(listRef).then((res) => {
                    res.items.forEach((itemRef) => {
                        deleteObject(itemRef)
                    })
                })

                // deletes them from zipcodes list (accessed by suitorFetching)
                const userData = await getDoc(doc(db, 'users', uid))
                const theirZipcode = userData.data().zipcode
                await deleteDoc(doc(db, 'zipcodes', theirZipcode, 'zipcodesUsers', uid))

                // deletes their chat messages
                const chatData = await getDoc(doc(db, 'users', uid, 'chats', 'chats'))
                const chatsList = chatData.data().chatsList
                if (typeof chatsList.length !== 'undefined') {
                    for (let i = 0; i < chatsList.length; i++) {
                        let chatName = ''
                        let matchsUID = chatsList[i].uid
                        if (uid > matchsUID) {
                            chatName = uid + '_' + matchsUID
                        } else {
                            chatName = matchsUID + '_' + uid
                        }
                        const q = query(collection(db, 'chats', chatName, 'messages'), where('sender', '==', uid))
                        const messagesSnapshot = await getDocs(q)
                        const deletePromises = messagesSnapshot.docs.map((doc) => deleteDoc(doc.ref))
                        await Promise.all(deletePromises)

                        // await deleteDoc(doc(db, 'chats', chatName)) this line may cause issues and is unnecessary since not really user data
                    }
                }
                // deletes their data in their main doc
                await deleteDoc(doc(db, 'users', uid, 'answers', 'answers'))
                await deleteDoc(doc(db, 'users', uid))
                await deleteDoc(doc(db, 'users', uid, 'photos', 'nameAgeHeight'))

                // deletes their auth account
                await deleteUser(user)

                setModalDeletionSuccess(true)
                setModalDeleteConfirmation(false)
            } catch (err) {
                console.log(err)
                setModalAlert(true)
                setAlertModalText('possible deletion error')
            }
        } catch (err) {
            console.log(err)
            setModalAlert(true)
            setAlertModalText('password incorrect')
        }
    }

    return (
        <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
            <Modal
                animationType="fade"
                transparent={true}
                visible={modalAlert}
                onRequestClose={() => {
                    setModalAlert(!modalAlert)
                }}
            >
                <ModalAlert setModalAlert={setModalAlert} alertModalText={alertModalText} navigation={navigation} />
            </Modal>
            <View
                style={{
                    backgroundColor: COLORS.modalColor,
                    padding: 20,
                    // alignItems: "center", Don't re-enable, squishes buttons
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginHorizontal: 20,
                    marginVertical: 30,
                    borderRadius: 10,
                }}
            >
                <AlphaText style={[styles.modalTitle, { paddingBottom: 15 }]}>Confirm It's You</AlphaText>

                <AlphaText style={[styles.buttonText1, { color: COLORS.nearBlack }]}>
                    Please enter your password to prove it's you.
                </AlphaText>
                <View style={{ alignContent: 'center', alignItems: 'center' }}>
                    <TextInput
                        style={{
                            backgroundColor: COLORS.white,
                            borderWidth: 2,
                            flexDirection: 'row',
                            marginVertical: 10,
                            width: '70%',
                            fontSize: 18 * ((VS + HS) / 2) * 0.85, //if you update the calculations on this line, need to update alphatext and other textinputs
                            paddingHorizontal: 10,
                            paddingVertical: 5,
                            borderRadius: 8,
                            borderColor: COLORS.nearBlack,
                            fontFamily: 'Medium',
                        }}
                        placeholder="Password"
                        placeholderTextColor={COLORS.darkGray}
                        value={passwordGiven}
                        textContentType="none"
                        onChangeText={(passwordGiven) => setPasswordGiven(passwordGiven)}
                    />
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                    }}
                >
                    <View style={{ flex: 0.5 }}></View>

                    <Pressable
                        onPress={() => setModalDeleteConfirmation(false)}
                        style={({ pressed }) => [
                            {
                                flex: 6,
                                borderRadius: 5,
                                padding: 10,
                                justifyContent: 'center',
                                marginTop: 25,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.redButton,
                            },
                        ]}
                    >
                        <AlphaText style={{ fontSize: 16, textAlign: 'center', color: COLORS.white }}>Back</AlphaText>
                    </Pressable>

                    <View style={{ flex: 1 }}></View>

                    <Pressable
                        onPress={handleDeletion}
                        style={({ pressed }) => [
                            {
                                flex: 6,
                                borderRadius: 5,
                                padding: 10,
                                marginTop: 25,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.darkGray,
                            },
                        ]}
                    >
                        <AlphaText style={{ fontSize: 16, textAlign: 'center', color: COLORS.white }}>
                            Delete For Real
                        </AlphaText>
                    </Pressable>

                    <View style={{ flex: 0.5 }}></View>
                </View>
            </View>
        </View>
    )
}
