import { View, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { useNavigation } from '@react-navigation/native'

import ProfileAnswersEditor from '../main/ProfileAnswersEditor'

export default function ModalAnswerQuestions({ updateAnswers }) {
    let screen = 'AnswersVoting'

    return (
        <View
            style={{
                backgroundColor: COLORS.modalSurroundings,
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <View
                style={{
                    backgroundColor: COLORS.lightGreen,
                    padding: 10,
                    borderRadius: 10,
                    width: '95%',
                    maxHeight: '95%',
                    alignItems: 'center',
                }}
            >
                <ProfileAnswersEditor screen={screen} updateAnswers={updateAnswers} />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    pressable: {
        borderRadius: 7,
        width: 130,
        height: 50,
        marginHorizontal: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
