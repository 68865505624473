import React, { useState, useEffect, useRef, useContext } from 'react'
import { Animated, View, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import ExpandCarrot from './../../assets/icons/ExpandCarrot.png'
import CapsuleButton from './CapsuleButton'
import { ScreenSizeContext } from '../../ScreenSizeContext'

export default function DeepDive({ answers }) {
    const [notExpanded, setNotExpanded] = useState(true)
    const firstUpdate = useRef(true)
    const { HS, VS } = useContext(ScreenSizeContext)
    const [pressed, setPressed] = useState({
        Relationship: false,
        Fun: false,
        Psychology: false,
        Food: false,
        Alcohol: false,
        Sex: false,
        Politics: false,
        Astrology: false,
        Career: false,
        Religion: false,
    })
    const handlePress = (buttonName) => {
        let currVal = pressed[buttonName]
        setPressed({ ...pressed, [buttonName]: !currVal })
    }

    const expandAll = () => {
        if (Object.values(pressed).every(Boolean)) {
            //if all are true, i.e., expanded
            setPressed({
                Relationship: false,
                Fun: false,
                Psychology: false,
                Food: false,
                Alcohol: false,
                Sex: false,
                Politics: false,
                Astrology: false,
                Career: false,
                Religion: false,
            })
            setNotExpanded(true)
        } else {
            setPressed({
                Relationship: true,
                Fun: true,
                Psychology: true,
                Food: true,
                Alcohol: true,
                Sex: true,
                Politics: true,
                Astrology: true,
                Career: true,
                Religion: true,
            })
            setNotExpanded(false)
        }
    }

    useEffect(() => {
        if (firstUpdate.current == false && notExpanded == true) {
            Animated.timing(Spin, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true,
            }).start()
        }
        if (firstUpdate.current == false && notExpanded == false) {
            Animated.timing(Spin, {
                toValue: 1,
                duration: 200,
                useNativeDriver: true,
            }).start()
        }

        if (firstUpdate.current == true) {
            //prevents from animating on initial render
            firstUpdate.current = false
        }
    }, [notExpanded])

    const [Spin] = useState(new Animated.Value(0)) //this has to use useState or else it only interpolates/animates when going to value 1 and skips animation when going to 0

    const keywords = [
        'Relationship',
        'Fun',
        'Psychology',
        'Food',
        'Alcohol',
        'Politics',
        'Sex',
        'Astrology',
        'Career',
        'Religion',
    ]

    const SpinValue = Spin.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '180deg'],
    })

    return (
        <View style={{ marginTop: 30 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <AlphaText style={{ fontSize: 15 }}>Deep dive</AlphaText>
                <Pressable onPress={expandAll}>
                    <Animated.Image
                        style={[styles.icon, { transform: [{ rotate: SpinValue }] }]}
                        source={ExpandCarrot}
                    />
                </Pressable>
            </View>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 10 * HS, marginTop: 10 * VS }}>
                {keywords.map(
                    (keyword) =>
                        answers[keyword] !== '' && (
                            <CapsuleButton
                                key={keyword}
                                keyword={keyword}
                                answers={answers}
                                pressed={pressed}
                                handlePress={handlePress}
                            />
                        )
                )}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    icon: {
        resizeMode: 'contain',
        width: 20,
        height: 20,
        marginRight: 10,
        tintColor: COLORS.nearBlack,
    },
})
