import React, { useState, useEffect } from 'react'
import { Dimensions, View, Image, Modal, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import * as ImagePicker from 'expo-image-picker'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import 'react-native-get-random-values'
import * as uuid from 'uuid'
import { createFaceLink } from '../../FirebaseMethods'

import AsyncStorage from '@react-native-async-storage/async-storage'
import { SafeAreaView } from 'react-native-safe-area-context'
import AddPhotoIcon from './../../assets/icons/AddPhotoIconNB.png'
import ModalPicConfirmation from '../ComponentsUpload/ModalPicConfirmation'
import ModalProcessing from '../ComponentsShared/ModalProcessing'
import ModalAlert from '../ComponentsShared/ModalAlert'
import ModalSave from '../ComponentsShared/ModalSave'
import { COLORS } from './../../Colors'
import { analytics } from '../../FirebaseInitialization'
import { logEvent } from 'firebase/analytics'

const { width, height } = Dimensions.get('window')
let HS = width / 320
let VS = height / 640

export default function UploadFace({ navigation }) {
    useEffect(() => {
        save()
    }, [])

    const [modalProcessingVisible, setModalProcessingVisible] = useState(false)
    const [modalPicConfirmationVisible, setModalPicConfirmationVisible] = useState(false)
    const [modalSaveVisible, setModalSaveVisible] = useState(false)
    const [modalAlert, setModalAlert] = useState(false)
    const [alertModalText, setAlertModalText] = useState('error')
    const [imageOne, setImageOne] = useState('')

    let selectImageAsync = async () => {
        let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (permissionResult.granted === false) {
            setModalAlert(true)
            setAlertModalText('Granting permission to access your image gallery is required to upload photos')
            return
        }
        let pickerResult = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
        })
        setImageOne(pickerResult.assets[0].uri) //#1
    }

    let handleImagePicked = async (uri) => {
        try {
            await uploadImageAsync(uri) //#4
            logEvent(analytics, 'finished_upload_face_screen')
            navigation.navigate('ExampleBody') //ONLY DO THIS IF HANDLE IMAGE IS WITHOUT ERROR
        } catch (e) {
            setImageOne('')
            setModalAlert(true)
            setAlertModalText('Upload failed. Please try again. (Contact admin if error repeats)')
        } finally {
            setModalProcessingVisible(false)
        }
    }

    async function uploadImageAsync(uri) {
        try {
            const img = await fetch(uri)
            const bytes = await img.blob()
            const auth = getAuth()
            const uid = auth.currentUser.uid
            const storage = getStorage()
            const remoteUri = 'users/' + uid + '/' + uuid.v4()
            const userRef = ref(storage, remoteUri)
            await uploadBytesResumable(userRef, bytes)
            const toSave = await getDownloadURL(ref(storage, remoteUri))
            createFaceLink(toSave, uid) //CALLS FIREBASE METHOD TO PUT A LINK TO THEIR PHOTO IN THE APPROPRIATE SECTION OF THEIR PART OF THE DATABASE
        } catch (err) {}
    }

    function uploadConfirmation() {
        setModalPicConfirmationVisible(true) //#2
    }
    function noPhoto() {
        setModalSaveVisible(true)
    }

    async function save() {
        //DON'T FORGET TO CALL save IN THE USEEFFECT ON LOAD
        try {
            AsyncStorage.setItem('creationCheckpoint', 'uploadFace')
        } catch (err) {
            // console.log(err);
        }
    }

    async function saveAndQuit() {
        try {
            AsyncStorage.setItem('creationCheckpoint', 'uploadFace') //AFTER TESTING ABOVE SAVE FUNCTION, COULD DELETE THIS LINE SINCE IT'LL ALREADY BE SAVED
            setModalAlert(true)
            setAlertModalText('Account creation has been saved.\nYou may safely close app.')
        } catch (err) {
            setModalAlert(true)
            setAlertModalText(err)
        }
    }

    async function uploadAndProceed() {
        if (imageOne === '') {
            setModalAlert(true)
            setAlertModalText('Please select a photo before proceeding.')
            setModalPicConfirmationVisible(false)
        } else {
            setModalProcessingVisible(true)
            setModalPicConfirmationVisible(!modalPicConfirmationVisible)
            await handleImagePicked(imageOne) //#3
        }
    }
    return (
        <SafeAreaView
            style={{
                backgroundColor: COLORS.white,
                flex: 1,
                justifyContent: 'center',
            }}
        >
            {/* <View
                style={{
                    height: Platform.OS === "android" ? 24 : 0,
                    backgroundColor: "grey",
                }}
            /> */}
            <View style={styles.container}>
                <Modal animationType="fade" transparent={true} visible={modalProcessingVisible}>
                    <ModalProcessing />
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalPicConfirmationVisible}
                    onRequestClose={() => {
                        setModalPicConfirmationVisible(!modalPicConfirmationVisible)
                    }}
                >
                    <ModalPicConfirmation
                        setModalPicConfirmationVisible={setModalPicConfirmationVisible}
                        uploadAndProceed={uploadAndProceed}
                        multiplePics={false}
                    />
                </Modal>

                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalSaveVisible}
                    onRequestClose={() => {
                        setModalSaveVisible(!modalSaveVisible)
                    }}
                >
                    <ModalSave setModalSaveVisible={setModalSaveVisible} saveAndQuit={saveAndQuit} />
                </Modal>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalAlert}
                    onRequestClose={() => {
                        setModalAlert(!modalAlert)
                    }}
                >
                    <ModalAlert setModalAlert={setModalAlert} alertModalText={alertModalText} />
                </Modal>
                <View
                    style={{
                        justifyContent: 'center',
                        flex: 1,
                        alignItems: 'center',
                        // width: "95%",
                    }}
                >
                    <AlphaText
                        style={{
                            fontSize: 30,
                            color: COLORS.darkGray,
                            marginBottom: 10 * VS,
                            marginHorizontal: 10 * HS,
                            textAlign: 'center',
                        }}
                    >
                        Upload Face Photo
                    </AlphaText>
                </View>
                <View
                    style={{
                        justifyContent: 'center',
                        flex: 1,
                        alignItems: 'center',
                        width: '95%',
                    }}
                >
                    <AlphaText
                        style={{
                            textAlign: 'center',
                            fontSize: 16,
                            color: COLORS.nearBlack,
                            width: '90%',
                        }}
                    >
                        This is the first photo potential matches will see
                    </AlphaText>
                </View>

                {/* <Image
                    style={{ width: 200, height: 131, margin: 7 }}
                    source={require("./../../images/PhotoIDicon.png")}  MAYBE DISPLAY PHOTO THEY'VE SELECTED AS LIKE A CONFIRMATION?
                ></Image> */}
                <AlphaText
                    style={{
                        marginVertical: 10 * VS,
                        textAlign: 'center',
                        fontSize: 16,
                        color: COLORS.nearBlack,
                        width: '90%',
                    }}
                >
                    Please make sure it provides a clear view of your face
                </AlphaText>
                <AlphaText style={styles.text2}>Overall rules always apply:</AlphaText>
                <AlphaText style={styles.text2}>No hate speech/symbols</AlphaText>
                <AlphaText style={styles.text2}>No below-the-belt nudity</AlphaText>
                {/* <AlphaText style={styles.text2}>
                    If someone other than yourself is in one of your photos,
                    their face must be covered
                    OR OR OR
                    If someone other than yourself is in one of your photos,
                    it must be obvious which person you are.
                </AlphaText> */}
                <AlphaText style={[styles.text2, { fontWeight: 'bold' }]}>
                    {'Do not promote your social media.'}
                </AlphaText>
                <AlphaText style={styles.text2}>
                    {
                        'Any handles or links to your instagram, snapchat, onlyfans, or other website, will result in an immediate ban.'
                    }
                </AlphaText>
                <View
                    style={{
                        marginTop: 10,
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {/* <Image
                        style={{
                            width: 117 * HS * 0.8,
                            height: 102 * HS * 0.8,
                            transform: [{ scaleX: -1 }],
                        }}
                        source={sourceOne}
                    ></Image> */}

                    <Pressable
                        style={{
                            marginHorizontal: 15,
                            padding: 5,
                            borderWidth: 2,
                            borderColor: COLORS.nearBlack,
                            borderStyle: 'dashed',
                        }}
                        onPress={selectImageAsync}
                    >
                        {imageOne === '' && <Image style={styles.photoIcon} source={AddPhotoIcon} />}
                        {imageOne !== '' && <Image style={styles.photoIcon} source={{ uri: imageOne }} />}
                    </Pressable>
                    {/* <Image
                        style={{
                            width: 117 * HS * 0.8,
                            height: 102 * HS * 0.8,
                        }}
                        source={sourceOne}
                    ></Image> */}
                </View>
                <Pressable
                    onPress={uploadConfirmation}
                    style={({ pressed }) => [
                        {
                            borderRadius: 5,
                            padding: 8 * VS,
                            marginTop: 15,
                            backgroundColor: COLORS.redButton,
                            opacity: pressed ? 0.5 : 1,
                        },
                    ]}
                >
                    <AlphaText
                        style={{
                            fontSize: 18,
                            color: COLORS.white,
                            textAlign: 'center',
                        }}
                    >
                        Upload your face photo
                    </AlphaText>
                </Pressable>
                <Pressable style={{ marginTop: 10 }} onPress={noPhoto}>
                    {({ pressed }) => (
                        <AlphaText
                            style={{
                                fontSize: 14,
                                color: COLORS.redButton,
                                textDecorationLine: 'underline',
                                opacity: pressed ? 0.5 : 1,
                            }}
                        >
                            Don't have a photo?
                        </AlphaText>
                    )}
                </Pressable>
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.white,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },
    text2: {
        width: '90%',
        marginVertical: 5,
        textAlign: 'center',
        fontSize: 13,
        color: COLORS.nearBlack,
    },
    photoIcon: {
        width: 102 * HS * 0.9,
        height: 136 * HS * 0.9,
        resizeMode: 'contain',
    },
})
