import React, { useState, useEffect } from 'react'
import { View, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { mandatory } from '../onboarding/AnswerQuestions'

export default function Dropdown({ questionName, questionText, choicesArray, answers, setAnswers }) {
    const [expanded, setExpanded] = useState(false)
    // The dropdown value is directly controlled by the answers prop.
    const chosenText = answers[questionName] || ''

    const handlePress = () => {
        setExpanded(!expanded)
    }
    const selectAnswer = (choice) => {
        // When an answer is selected, update the answers state in the parent.
        setAnswers({ ...answers, [questionName]: choice })
        setExpanded(false)
    }
    return (
        <View style={{ marginVertical: 10 }}>
            <View style={{ marginHorizontal: 10 }}>
                <AlphaText style={styles.questionText}>{questionText}</AlphaText>
            </View>
            <Pressable onPress={handlePress}>
                <View
                    style={[
                        styles.largeBox,
                        { borderWidth: mandatory.includes(questionName) ? 2 : 0, borderColor: COLORS.redButton },
                    ]}
                >
                    <AlphaText style={styles.answersText}>{chosenText}</AlphaText>
                    {expanded &&
                        choicesArray.map((choice) => {
                            return (
                                <Pressable onPress={() => selectAnswer(choice)} key={choice}>
                                    <AlphaText style={styles.answersText}>{choice}</AlphaText>
                                </Pressable>
                            )
                        })}
                    {expanded && chosenText !== '' && (
                        <Pressable onPress={() => selectAnswer('')}>
                            <AlphaText style={styles.answersText}>Remove Response</AlphaText>
                        </Pressable>
                    )}
                </View>
            </Pressable>
        </View>
    )
}

const styles = StyleSheet.create({
    largeBox: {
        backgroundColor: COLORS.white,
        minHeight: 55,
        borderRadius: 8,
        padding: 15,
        flexDirection: 'column',
    },
    questionText: {
        color: COLORS.nearBlack,
        fontSize: 14,
    },
    answersText: {
        color: COLORS.darkGray,
        fontSize: 14,
    },
})
