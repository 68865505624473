import { View, Pressable, StyleSheet } from 'react-native'
import AlphaText from '../ComponentsShared/TextAlpha'
import { COLORS } from '../../Colors'
import { styles } from './StylesSettings'
import { loggingOut } from '../../FirebaseMethods'

export default function ModalLogout({ setModalLogout1, setModalLogout2, demo, navigation }) {
    let signOutForReal = async () => {
        await loggingOut()
        navigation.navigate('SignIn')
    }

    const handleLogout = () => {
        setModalLogout1(false)
        if (!demo) {
            signOutForReal()
        } else {
            setModalLogout2(true)
        }
    }
    return (
        <View style={{ backgroundColor: COLORS.modalSurroundings, flex: 1, justifyContent: 'center' }}>
            <View
                style={{
                    backgroundColor: COLORS.modalColor,
                    padding: 20,
                    // alignItems: "center", DO NOT re-enable, squishes buttons
                    justifyContent: 'center',
                    flexDirection: 'column',
                    flex: 1,
                    marginHorizontal: 15,
                    marginTop: 150,
                    marginBottom: 150,
                    borderRadius: 10,
                }}
            >
                <AlphaText style={[styles.modalTitle, { paddingBottom: 15 }]}>
                    Are you sure you want to logout?
                </AlphaText>
                <AlphaText style={{ color: COLORS.nearBlack, fontSize: 20, textAlign: 'center' }}>
                    To log back in later, you'll either need to re-enter your password or create a new one.
                </AlphaText>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <View
                        style={{
                            flex: 0.5,
                        }}
                    ></View>
                    <Pressable
                        onPress={() => setModalLogout1(false)}
                        style={({ pressed }) => [
                            {
                                flex: 2,
                                borderRadius: 5,
                                padding: 10,
                                marginTop: 25,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.redButton,
                            },
                        ]}
                    >
                        <AlphaText style={styles.buttonText1}>Back</AlphaText>
                    </Pressable>
                    <View style={{ flex: 1 }} />
                    <Pressable
                        onPress={handleLogout}
                        style={({ pressed }) => [
                            {
                                flex: 2,
                                borderRadius: 5,
                                padding: 10,
                                marginTop: 25,
                                opacity: pressed ? 0.5 : 1,
                                backgroundColor: COLORS.darkGray,
                            },
                        ]}
                    >
                        <AlphaText style={styles.buttonText1}>Log Out</AlphaText>
                    </Pressable>
                    <View style={{ flex: 0.5 }}></View>
                </View>
            </View>
        </View>
    )
}
