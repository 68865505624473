import React, { useContext } from 'react'
import { View, Pressable, ScrollView, StyleSheet, Image, Text } from 'react-native'
import ButtonPrimary from './ButtonPrimary'
import ButtonSecondary from './ButtonSecondary'
import ModalPrimary from './ModalPrimary'
import AlphaText from './TextAlpha'
import { COLORS } from '../../Colors'
// import { styles } from "./StylesDoHaveAccount" //styles for modalS?????
import { ScreenSizeContext } from '../../ScreenSizeContext'

export default function ModalSave({ setModalSaveVisible, modalSaveVisible, saveAndQuit }) {
    const { HS, VS } = useContext(ScreenSizeContext)

    return (
        <ModalPrimary>
            <AlphaText
                style={{
                    color: COLORS.nearBlack,
                    fontSize: 20,
                    textAlign: 'center',
                }}
            >
                {
                    ' Do you want to save and quit?\n\nYou can go take some photos and resume building your profile later on.'
                }
            </AlphaText>

            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                <ButtonSecondary onPress={() => setModalSaveVisible(false)} buttonText={'Back'} />
                <ButtonPrimary onPress={saveAndQuit} buttonText={'Save & Quit'} />
            </View>
        </ModalPrimary>
    )
}
